import { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  editMachine,
  getCountries,
  getMachine,
  getMachineTypes,
} from "../../api/Machine/MachineService";
import {
  addComment,
  deleteComment,
  getMachineComments,
} from "../../api/MachineComments/MachineCommentService";
import { MachineQueryNames, UserQueryNames } from "../../api/Queries";
import {
  getAsmUsers,
  getCurrentUser,
  getUsers,
} from "../../api/User/UserService";
import { Machine } from "../../types/Machine";
import { IUser } from "../../types/User";
import { GetEmailFromUserName } from "../../utility/FormUtility";
import { CreateCommentCommand } from "../../utility/MachineUtility";
import { useDispatchAlert } from "../ui/Alert";

export type Country = {
  countryCode: string;
  countryName: string;
};

const getUserDisplayName = (user: IUser | undefined): string => {
  if (!user) return "";
  return `${user.firstName} ${user.lastName} (${user.email})`;
};

export default function useEditMachineData(machineId: MachineId) {
  const queryClient = useQueryClient();
  const dispatchAlert = useDispatchAlert();
  const [childLoading, setChildLoading] = useState<boolean>(false);
  const [machineNotFound, setMachineNotFound] = useState<boolean>(false);

  const { data: machineData, isLoading: isMachineDataLoading } = useQuery(
    MachineQueryNames.GetMachine,
    () => getMachine(machineId),
    {
      onError: () => {
        setMachineNotFound(true);
      },
    }
  );

  const { data: countries, isLoading: isCountriesDataLoading } = useQuery(
    MachineQueryNames.GetCountries,
    () => getCountries(),
    {
      select: (response: Array<Country>) => {
        return response.map((x) => x.countryName);
      },
    }
  );

  const { data: machineTypes, isLoading: isMachineTypesDataLoading } = useQuery(
    MachineQueryNames.GetMachineTypes,
    () => getMachineTypes(),
    {
      select: (response: string[]) => [...new Set(response)],
    }
  );

  const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery(
    MachineQueryNames.GetMachineTypes,
    () => getCurrentUser()
  );

  const { data: machineComments, isLoading: isMachineCommentsLoading } =
    useQuery(MachineQueryNames.GetMachineComments, () =>
      getMachineComments(machineId.toString())
    );

  const { data: users, isLoading: isUsersDataLoading } = useQuery(
    UserQueryNames.GetAllUsers,
    () => getUsers(),
    {
      select: (response: any): Array<string> => {
        return response.map((x: IUser) => getUserDisplayName(x));
      },
    }
  );

  const { data: asmUsers, isLoading: isAsmUserDataLoading } = useQuery(
    UserQueryNames.GetAsmUsers,
    () => getAsmUsers(),
    {
      select: (response: any): Array<string> => {
        return response.users.map((x: IUser) => getUserDisplayName(x));
      },
    }
  );

  const editMachineMutation = useMutation(
    (request: Machine) => editMachine(request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [MachineQueryNames.GetMachine],
        });

        dispatchAlert({
          message: "Machine edited successfully",
          messageType: "success",
        });
      },
      onError: () => {
        dispatchAlert({
          message: "Something went wrong while editing machine",
          messageType: "error",
        });
      },
    }
  );

  const addMachineCommentMutation = useMutation(
    (comment: CreateCommentCommand) => addComment(comment),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [MachineQueryNames.GetMachineComments],
        });
        dispatchAlert({
          message: "Comment added successfully",
          messageType: "success",
        });
      },
      onError: () => {
        dispatchAlert({
          message: "Something went wrong while adding comment",
          messageType: "error",
        });
      },
    }
  );

  const deleteMachineCommentMutation = useMutation(
    (id: string) => deleteComment(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [MachineQueryNames.GetMachineComments],
        });
        dispatchAlert({
          message: "Comment removed successfully",
          messageType: "success",
        });
      },
      onError: () => {
        dispatchAlert({
          message: "Something went wrong while removing comment",
          messageType: "error",
        });
      },
    }
  );

  const isPageLoading =
    addMachineCommentMutation.isLoading ||
    deleteMachineCommentMutation.isLoading ||
    isMachineDataLoading ||
    isCountriesDataLoading ||
    isMachineTypesDataLoading ||
    isUsersDataLoading ||
    isAsmUserDataLoading ||
    isCurrentUserLoading ||
    isMachineCommentsLoading ||
    childLoading;

  const handleEditMachine = (submittedMachine: Machine) => {
    const request = { ...submittedMachine };
    //If we have a comment filled in, add it
    if (submittedMachine.comment != null) {
      const comment: CreateCommentCommand = {
        id: "-1",
        machineId: machineId.toString(),
        commenterEmail: currentUser?.email ?? "",
        content: submittedMachine.comment,
        createdAtUtc: new Date(),
      };

      if (
        submittedMachine.comment != null &&
        submittedMachine.comment.length > 0
      ) {
        addMachineCommentMutation.mutate(comment);
      }
    }

    if (Number(request.latitude) === 0) {
      request.latitude = null;
    }
    if (Number(request.longitude) === 0) {
      request.longitude = null;
    }

    request.machineId = machineId;
    request.ownerEmail = GetEmailFromUserName(request.ownerEmail);
    request.asmEmail = GetEmailFromUserName(request.asmEmail);

    editMachineMutation.mutate(request);
  };

  const handleDeleteComment = (commentId: string) => {
    deleteMachineCommentMutation.mutate(commentId);
  };

  const machine = useMemo(() => {
    if (!machineData || !users || !asmUsers) return undefined;
    const machineCopy = { ...machineData };

    const owner = users.find((x: string) =>
      x.includes(machineCopy.ownerEmail ?? "")
    );
    const asm = asmUsers.find((x: string) =>
      x.includes(machineCopy.asmEmail ?? "")
    );

    machineCopy.ownerEmail = owner ?? null;
    machineCopy.asmEmail = asm ?? null;
    if (machineComments != null) {
      machineCopy.comments = machineComments;
    }
    return machineCopy;
  }, [machineData, users, asmUsers, machineComments]);

  return {
    machineNotFound,
    machine,
    machineComments,
    countries,
    users,
    asmUsers,
    machineTypes,
    isPageLoading,
    setChildLoading,
    handleEditMachine,
    handleDeleteComment,
  };
}
