import React from "react";

import BasePage from "../../components/ui/BasePage";
import EditcustomerCareUserContainer from "../../components/ViewCustomerCareUserList/EditCustomerCareUser/EditCustomerCareUserContainer";

export default function EditcustomerCareUserPage() {
  return (
    <BasePage>
      <EditcustomerCareUserContainer/>
    </BasePage>
  );
}
