import { User } from "@auth0/auth0-react";
import { UserResult } from "../../globalHooks/authorizationHooks";
import { axiosHttpClient } from "../../store/GlobalContext";
import { IUser } from "../../types/User";

export const resetUserMfa = async (email: string) => {
  await axiosHttpClient.put("v1/user/reset-mfa", {
    email: email,
  });
};

export const getCurrentUser = async (): Promise<User | undefined> => {
  const response = await axiosHttpClient.get("v1/user/me");
  return response.data;
};

export const getUsers = async (): Promise<IUser[] | undefined> => {
  const response = await axiosHttpClient.get("v1/user/with-stats");
  return response.data.usersWithStats;
};

export const getAsmUsers = async (): Promise<string[] | undefined> => {
  const response = await axiosHttpClient.get("v1/user/asm");
  return response.data;
};

export const deleteUser = async (email: string): Promise<void> => {
  await axiosHttpClient.put("v1/user/delete-by-email", {
    email,
  });
};

export const getMyUser = async (): Promise<UserResult> => {
  const response = await axiosHttpClient.get(`v1/user/me`);
  return response.data;
};

export const getOwnedAndAdminMachinesForUser = async (email: string) => {
  const response = await axiosHttpClient.post("v1/user/owned-machines", {
    email: email,
  });
  return response;
};
