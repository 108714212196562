import React from "react";
import BasePage from "../../components/ui/BasePage";
import { CustomerCareUserListContainer } from "../../components/ViewCustomerCareUserList/CustomerCareUserListContainer";

export default function CustomerCareUserListPage() {
  return (
    <BasePage>
      <CustomerCareUserListContainer />
    </BasePage>
  );
}
