export enum MachineDiagnosticsQueryNames {
  Summary = "machine-diagnostics-summary",
  Lifesign = "machine-diagnostics-lifesign",
  Quests = "machine-diagnostics-quests",
}

export enum MachineQueryNames {
  GetAllMachines = "all-machines",
  GetMachine = "machine-with-id",
  GetCountries = "countires",
  GetMachineTypes = "machine-types",
  GetMachineComments = "machine-comments",
}

export enum UserQueryNames {
  GetAllUsers = "all-users",
  GetAsmUsers = "asm-users",
  GetMyUser = "my-user",
  GetOwnedAndAdminMachinesForUser = "get-owned-and-admin-machines-for-user",
  getInvoices = "invoices",
}

export enum GlobalAnnouncementQueryNames {
  GetGlobalAnnouncement = "global-announcement",
}

export enum MachineSubscriptionsQueryNames {
  GetMachineSubscriptions = "machine-subscriptions",
}
