import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback } from "react";
import { Feature } from "../../globalHooks/authorizationHooks";
import {
  GetSubscriptionName,
  MachineSubscription,
} from "../../utility/SubscriptionsUtility";
import { GoToMachineListPageButton } from "../EditMachine/EditMachineContainer";
import PagePaper from "../ui/PagePaper";
import ProtectedButton from "../ui/ProtectedButton";
import ChangeSubscriptionDialogContainer from "./ChangeSubscriptionDialog/ChangeSubscriptionDialogContainer";
import SubscriptionCard from "./SubscriptionCard/SubscriptionCard";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "block",
  },
  button: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
    },
  },
  headerSection: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  isPageLoading: boolean;
  machineId: MachineId;
  changeEndDateDialogOpen: boolean;
  machineSubscriptions: Array<MachineSubscription>;
  subscriptionToEdit: Nullable<MachineSubscription>;
  isMachineLocked: boolean;
  onChangeEndDateDialogClose: () => void;
  onChangeEndDateClick: (subscription: MachineSubscription) => void;
  onSubscriptionsButtonClick: () => void;
  onSubmitEndDate: (
    subscription: Nullable<MachineSubscription>,
    newEndDate: Date
  ) => void;
}

export default function ManageSubscriptionsPresentation({
  isPageLoading,
  machineId,
  changeEndDateDialogOpen,
  machineSubscriptions,
  subscriptionToEdit,
  isMachineLocked,
  onChangeEndDateDialogClose,
  onChangeEndDateClick,
  onSubscriptionsButtonClick,
  onSubmitEndDate,
}: Props) {
  const classes = useStyles();

  const handleSubmit = useCallback(
    (newEndDate: Date) => onSubmitEndDate(subscriptionToEdit, newEndDate),
    [onSubmitEndDate, subscriptionToEdit]
  );

  return (
    <PagePaper isLoading={isPageLoading} title={"Manage Subscriptions"}>
      <div className={classes.headerSection}>
        <Typography variant="subtitle1">MachineId: {machineId}</Typography>
        <ProtectedButton
          startIcon={<ArrowForwardIcon />}
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onSubscriptionsButtonClick}
          requiredFeature={Feature.CustomerCareManageMachines}
        >
          To subscriptions in iMoba
        </ProtectedButton>
        <GoToMachineListPageButton />
        <br />
      </div>
      <Divider className={classes.divider} />

      <Grid container spacing={6}>
        {machineSubscriptions.map((machineSubscription) =>
          mapSubscription(
            machineSubscription,
            isPageLoading,
            isMachineLocked,
            onChangeEndDateClick
          )
        )}
      </Grid>
      <ChangeSubscriptionDialogContainer
        subscription={subscriptionToEdit}
        open={changeEndDateDialogOpen}
        onSubmit={handleSubmit}
        onClose={onChangeEndDateDialogClose}
        startDate={
          !subscriptionToEdit ? new Date() : subscriptionToEdit.fromUtc
        }
        endDate={!subscriptionToEdit ? new Date() : subscriptionToEdit.untilUtc}
      />
    </PagePaper>
  );
}

const mapSubscription = (
  machineSubscription: MachineSubscription,
  isPageLoading: boolean,
  isMachineLocked: boolean,
  onChangeEndDateClick: (subscription: MachineSubscription) => void
): JSX.Element | null => {
  if (GetSubscriptionName(machineSubscription.subscriptionType) === "") {
    return null;
  }
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      key={machineSubscription.subscriptionType}
    >
      <SubscriptionCard
        subscription={machineSubscription}
        isLoading={isPageLoading}
        onChangeEndDateClick={onChangeEndDateClick}
        isMachineLocked={isMachineLocked}
      />
    </Grid>
  );
};
