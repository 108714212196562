import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import {
  DefaultAnnouncment,
  GlobalAnnouncementModel,
} from "../../types/GlobalAnnouncementModel";
import { GlobalAnnouncement } from "../ui/GlobalAnnouncement/GlobalAnnouncement";
import { MaxCharactersInMessage, MaxCharactersInTitle } from "./common";
import SetGlobalAnnouncementForm from "./SetGlobalAnnouncementForm";

export interface Props {
  globalAnnouncement: GlobalAnnouncementModel | undefined;
  isPageLoading: boolean;
  onSubmit: (machine: GlobalAnnouncementModel) => void;
}

export default function SetGlobalAnnouncementPresentation({
  globalAnnouncement,
  isPageLoading,
  onSubmit,
}: Props) {
  const [previewAnnouncement, setPreviewAnnouncement] =
    useState<GlobalAnnouncementModel | null>(null);

  const handleSubmit = useCallback((values, _) => {
    onSubmit(values);
  }, []);

  return (
    <>
      <p>
        Note that on this page a preview of the global announcement is shown, it
        appears in the bottom right hand corner of the screen.
        <br />
        The preview is only shown when the form has been filled correctly.
        <br />
        The announcement is only visible for customers in iMoba if it is set to
        active.
      </p>
      <p>
        Please note that it can take up to 15 minutes until all users can see
        the announcement.
      </p>
      <Formik
        enableReinitialize
        initialValues={globalAnnouncement ?? DefaultAnnouncment}
        onSubmit={handleSubmit}
        validate={ValidateForm}
        validateOnMount={true}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          touched,
          resetForm,
        }) => (
          <SetGlobalAnnouncementForm
            values={values}
            errors={errors}
            isValid={isValid}
            dirty={dirty}
            touched={touched}
            isPageLoading={isPageLoading}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleBlur={handleBlur}
            resetForm={resetForm}
            setPreviewAnnouncement={setPreviewAnnouncement}
          />
        )}
      </Formik>

      {GlobalAnnouncement(previewAnnouncement)}
    </>
  );
}

function ValidateForm(values: GlobalAnnouncementModel) {
  const errors: any = {};

  if (values.title == undefined || values.title.trim() === "") {
    errors.title = "Required";
  } else if (values.title.length > MaxCharactersInTitle) {
    errors.title = "Title may not be longer as 256 characters";
  }

  if (values.message == undefined || values.message.trim() === "") {
    errors.message = "Required";
  } else if (values.message.length > MaxCharactersInMessage) {
    errors.message = "Message may not be longer as 512 characters";
  }

  if (values.severity == undefined) {
    errors.severity = "Required";
  }

  if (values.isActive == undefined) {
    errors.isActive = "Required";
  }

  return errors;
}
