import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Theme,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Feature } from "../../../../globalHooks/authorizationHooks";
import ProtectedIconButton from "../../../ui/ProtectedIconButton";
import { StyledTableCell, StyledTableRow } from "../../../ui/TableComponents";
import { ignoredTableColumnLabels, IMachineStatusTableRow } from "../common";
import { DefaultTableHead } from "../DefaultTableHead";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
    lineHeight: 1.5,
  },
}));

function TableBodyWithLoadingAndDefaults(
  isLoading: boolean,
  sortedMachines: Array<IMachineStatusTableRow>,
  onEditMachine: (machine: MachineId) => void,
  onViewMachine: (machine: MachineId) => void,
  onIgnoreMachine: (machineId: MachineId) => void,
  isMachineLocked: (machine: IMachineStatusTableRow) => boolean
) {
  const classes = useStyles();

  if (isLoading) {
    return (
      <StyledTableRow>
        <StyledTableCell colSpan={3} className={classes.root}>
          {" "}
          <CircularProgress />
        </StyledTableCell>
      </StyledTableRow>
    );
  } else if (sortedMachines == null || sortedMachines.length > 0) {
    return sortedMachines.map((machine: IMachineStatusTableRow, i: number) => (
      <StyledTableRow key={i}>
        <StyledTableCell>{machine.machineId}</StyledTableCell>
        <StyledTableCell>
          {machine.termsAndConditionsAccepted ? <CheckIcon color={'success'} /> : <CloseIcon color={'error'} />}
        </StyledTableCell>
        <StyledTableCell>
          <Tooltip title={"Include machine in mail notifications"}>
            <IconButton
              onClick={() => onIgnoreMachine(machine.machineId)}
              color={"primary"}
              size="large"
            >
              <NotificationsActiveIcon />
            </IconButton>
          </Tooltip>
          {isMachineLocked(machine) ? (
            <Tooltip title={"View Machine"}>
              <IconButton
                onClick={() => onViewMachine(machine.machineId)}
                color={"primary"}
                size="large"
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <ProtectedIconButton
              onClick={() => onEditMachine(machine.machineId)}
              requiredFeature={Feature.CustomerCareManageMachines}
              color={"primary"}
              tooltip={"Edit Machine"}
            >
              <EditIcon />
            </ProtectedIconButton>
          )}
        </StyledTableCell>
      </StyledTableRow>
    ));
  } else {
    return (
      <StyledTableRow key={0}>
        <StyledTableCell colSpan={3} className={classes.root}>
          {"No machines are ignored currently."}
        </StyledTableCell>
      </StyledTableRow>
    );
  }
}

interface Props {
  machines: Array<IMachineStatusTableRow>;
  sortedMachines: Array<IMachineStatusTableRow>;
  onViewMachine: (machine: MachineId) => void;
  onEditMachine: (machine: MachineId) => void;
  onIgnoreMachine: (machineId: MachineId) => void;
  isMachineLocked: (machine: IMachineStatusTableRow) => boolean;
  setSortedMachines: React.Dispatch<
    React.SetStateAction<IMachineStatusTableRow[]>
  >;
  isLoading: boolean;
}

export function MachineIgnoredTablePresentation({
  machines,
  isLoading,
  sortedMachines,
  onEditMachine,
  onViewMachine,
  onIgnoreMachine,
  isMachineLocked,
  setSortedMachines,
}: Props) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <DefaultTableHead
          machines={machines}
          setSortedMachines={setSortedMachines}
          tableColumnLabels={ignoredTableColumnLabels}
          defaultSortedColumn="machineId"
        />
        <TableBody>
          {TableBodyWithLoadingAndDefaults(
            isLoading,
            sortedMachines,
            onEditMachine,
            onViewMachine,
            onIgnoreMachine,
            isMachineLocked
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
