import {
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { MachineWithOwner } from "../../viewMachineList/ViewMachineListContainer";

const useStyles = makeStyles((theme) => ({
  helperText: {
    marginBottom: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogRoot: {
    zIndex: "2000 !important" as any, // Material-UI sets Z-Index using inline styles, override using !important
  },
}));

export interface AddUserDialogPresentationProps {
  open: boolean;
  machines: Array<MachineWithOwner>;
  searchTerm: string;
  onChange: (value: string) => void;
  onClose: () => void;
  onClick: (machineId: MachineWithOwner) => void;
}

export default function AddUserDialogPresentation({
  open,
  machines,
  searchTerm,
  onChange,
  onClose,
  onClick,
}: AddUserDialogPresentationProps) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        classes={{ root: classes.dialogRoot }}
      >
        <DialogTitle>Select a machine</DialogTitle>
        <DialogContent>
          <Typography variant="body2" className={classes.helperText}>
            Choose a machine to view in iMoba (opens in a new tab).
          </Typography>
          <TextField
            id="searchTerm"
            autoFocus
            fullWidth
            label={"MachineId"}
            value={searchTerm || ""}
            onChange={(event: any) => onChange(event.target.value)}
            variant="outlined"
            className={classes.input}
          />
          <Divider className={classes.divider} />
          <List>
            {machines.map((machine, index) => (
              <ListItem button onClick={() => onClick(machine)} key={index}>
                <ListItemText
                  primary={machine.machineView.machineId}
                  secondary={machine.machineView.machineType}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
