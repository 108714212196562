import { MachineDiagnosticModel } from "../../../types/MachineDiagnostics/MachineDiagnosticModel";
import { TableColumnLabel } from "../../../types/TableColumnLabel";

export interface IMachineStatusTableRow {
  machineId: MachineId;
  firstOccurrenceDateTime: Date;
  isIgnored: boolean;
  termsAndConditionsAccepted: boolean;
}

export const statusTableColumnLabels: TableColumnLabel[] = [
  {
    key: "machineId",
    text: "MachineId",
  },
  {
    key: "firstOccurrenceDateTime",
    text: "First Occurence Date Time (UTC)",
  },
  {
    key: "termsAndConditionsAccepted",
    text: "T&C Accepted",
  },
  {
    key: "action",
    text: "Actions"
  },
];

export const ignoredTableColumnLabels: TableColumnLabel[] = [
  {
    key: "machineId",
    text: "MachineId",
  },
  {
    key: "termsAndConditionsAccepted",
    text: "T&C Accepted",
  },
  { key: "action",
    text: "Actions"
  },
];

export const convertToTableRows = (
  input: MachineDiagnosticModel | undefined
): IMachineStatusTableRow[] => {
  let result: IMachineStatusTableRow[] = [];
  if (input === undefined) {
    return result;
  }
  for (let diagnostic of input.machineDiagnostics) {
    result.push({
      machineId: diagnostic.machineId,
      firstOccurrenceDateTime: diagnostic.firstOccurrenceDateTime,
      isIgnored: diagnostic.isIgnored,
      termsAndConditionsAccepted: diagnostic.termsAndConditionsAccepted
    });
  }

  return result;
};
