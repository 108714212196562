import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useLogout } from "../../globalHooks/authHooks";

function LogoutPage() {
  const classes = useStyles();
  useLogout();

  return (
    <>
      <div className={classes.centered}>
        <div>
          <div className={classes.centeredSpinner}>
            <CircularProgress />
          </div>
          <div>
            <Typography variant="h5" className={classes.loadingText}>
              {"Logging out"}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    height: "100%",
    width: "100%",
  },
  loadingText: {
    marginTop: theme.spacing(4),
  },
  centeredSpinner: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
}));

export default LogoutPage;
