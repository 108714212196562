import React from "react";
import BasePage from "../../components/ui/BasePage";
import ViewMachineListContainer from "../../components/viewMachineList/ViewMachineListContainer";

export default function MachineListPage() {
  return (
    <BasePage>
      <ViewMachineListContainer />
    </BasePage>
  );
}
