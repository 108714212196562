import { Button, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCustomerCareUserService } from "../../../api/ServiceContext";
import {
  GetEmptyCustomerCareUser,
  ICustomerCareUser,
} from "../../../types/User";
import { useDispatchAlert } from "../../ui/Alert";
import PagePaper from "../../ui/PagePaper";
import EditCustomerCareUserForm from "../Forms/EditCustomerCareUserForm";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

export default function EditcustomerCareUserContainer() {
  const { email } = useParams<{ email: string }>();

  const classes = useStyles();
  const customerCareUserService = useCustomerCareUserService();
  const dispatchAlert = useDispatchAlert();
  const browserHistory = useHistory();

  const [customerCareUser, setCustomerCareUser] = useState<ICustomerCareUser>(
    GetEmptyCustomerCareUser()
  );
  const [userIsLastAdmin, setUserIsLastAdmin] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsPageLoading(true);

    customerCareUserService
      .getCustomerCareUsers()
      .then((res: any) => {
        const customerCareUsers: ICustomerCareUser[] =
          res.data.customerCareUsers;
        if (
          customerCareUsers.filter(
            (user) => user.systemRoles[0] === "AdminCustomerCareApp"
          ).length === 1
        ) {
          setUserIsLastAdmin(true);
        }
        const customerCareUser = customerCareUsers.find(
          (user) => user.email === email
        );
        if (customerCareUser) {
          setCustomerCareUser(customerCareUser);
        } else {
          dispatchAlert({
            message:
              "Something went wrong retrieving the customer care team member",
            messageType: "error",
          });
        }
      })
      .catch(() => {
        dispatchAlert({
          message:
            "Something went wrong retrieving the customer care team member",
          messageType: "error",
        });
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  }, [customerCareUserService]);

  const handleSubmit = (submittedCustomerCareUser: ICustomerCareUser) => {
    const request = { ...submittedCustomerCareUser };

    if (roleChangedForAssignedAsm(submittedCustomerCareUser)) {
      dispatchAlert({
        message:
          "The user is assigned as ASM to a machine. Unassign the ASM before changing the role.",
        messageType: "error",
      });
      return;
    }

    if (roleChangedForLastAdmin(submittedCustomerCareUser)) {
      dispatchAlert({
        message:
          "The selected customer care team member is the last admin. The role cannot be changed.",
        messageType: "error",
      });
      return;
    }

    setIsPageLoading(true);
    customerCareUserService
      .updateCustomerCareUser(request)
      .then(() => {
        dispatchAlert({
          message: "Customer care team member updated successfully",
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message:
            "Something went wrong updating the customer care team member",
          messageType: "error",
        });
      })
      .finally(() => {
        setIsPageLoading(false);
        browserHistory.push("/customer-care-team/");
      });
  };

  return (
    <>
      <PagePaper
        isLoading={isPageLoading}
        title={"Update customer care team member"}
      >
        <GoToCustomerCareUserListPageButton />
        <Divider className={classes.divider} />
        <EditCustomerCareUserForm
          onSubmit={handleSubmit}
          isPageLoading={isPageLoading}
          customerCareUser={customerCareUser}
        />
      </PagePaper>
    </>
  );

  function roleChangedForAssignedAsm(
    submittedCustomerCareUser: ICustomerCareUser
  ) {
    return (
      submittedCustomerCareUser.isAssignedAsm &&
      submittedCustomerCareUser.systemRoles.find(
        (role) => role !== "AsmCustomerCareApp"
      )
    );
  }

  function roleChangedForLastAdmin(
    submittedCustomerCareUser: ICustomerCareUser
  ) {
    return (
      userIsLastAdmin &&
      submittedCustomerCareUser.systemRoles.find(
        (role) => role !== "AdminCustomerCareApp"
      )
    );
  }
}

export function GoToCustomerCareUserListPageButton() {
  const classes = useStyles();
  const browserHistory = useHistory();

  const handleClick = () => {
    browserHistory.push("/customer-care-team/");
  };

  return (
    <Button
      onClick={handleClick}
      color="primary"
      variant="outlined"
      className={classes.button}
    >
      Go to customer care team list
    </Button>
  );
}
