import { MenuItem, Select } from "@mui/material";
import React from "react";
import {
  SystemRole,
  allSystemRoles,
} from "../../../globalHooks/authorizationHooks";
import { GetSystemRoleDisplayName } from "../ViewCustomerCareUserListUtil";

export interface SystemRoleSelectProps {
  selectedValue: SystemRole;
  disabled: boolean;
  onChange: (value: SystemRole) => void;
}

export default function SystemRoleSelect({
  selectedValue,
  disabled,
  onChange,
}: SystemRoleSelectProps) {
  return (
    <>
      <Select
        disabled={disabled}
        fullWidth
        value={selectedValue ?? ""}
        onChange={(event: any, _) => {
          onChange(event.target.value);
        }}
        variant="outlined"
      >
        {allSystemRoles.map((value, index) => {
          return (
            <MenuItem key={index} value={value}>
              {GetSystemRoleDisplayName(value)}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}
