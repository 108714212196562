import React, { useState } from "react";
import { StepperDialog } from "../ui/StepperDialog";
import { DefaultConfirmationStep } from "./Steps/DefaultConfirmationStep";
import { UserOwnerOfMachineStep } from "./Steps/OwnerOfMachineStep";
import { UserLastAdminOfMachineStep } from "./Steps/UserLastAdminOfMachineStep";

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
  email: string;
  lastAdminOfMachines: Array<number>;
  ownerOfMachines: Array<number>;
}

function getSteps(
  lastAdminOfMachines: Array<number>,
  ownerOfMachines: Array<number>
) {
  let result: string[] = [];

  if (ownerOfMachines.length > 0) {
    result.push("This user is an owner");
  }

  if (lastAdminOfMachines.length > 0) {
    result.push("This user is the only admin");
  }

  result.push("Delete user");

  return result;
}

export function DeleteUserStepperDialog({
  isOpen,
  onConfirm,
  onReject,
  email,
  lastAdminOfMachines,
  ownerOfMachines,
}: Props) {
  const [inputCorrectlyRetyped, setInputCorrectlyRetyped] = useState(false);

  const handleOnType = (newValue: string) => {
    if (newValue === email) {
      setInputCorrectlyRetyped(true);
    } else {
      setInputCorrectlyRetyped(false);
    }
  };

  return (
    <StepperDialog
      isOpen={isOpen}
      onConfirm={onConfirm}
      onReject={onReject}
      steps={getSteps(lastAdminOfMachines, ownerOfMachines)}
      lastActionButtonDisabled={!inputCorrectlyRetyped}
      lastActionButtonText={"Delete user"}
    >
      {ownerOfMachines.length > 0 && (
        <UserOwnerOfMachineStep ownerOfMachines={ownerOfMachines} />
      )}
      {lastAdminOfMachines.length > 0 && (
        <UserLastAdminOfMachineStep lastAdminOfMachines={lastAdminOfMachines} />
      )}
      <DefaultConfirmationStep email={email} onType={handleOnType} />
    </StepperDialog>
  );
}
