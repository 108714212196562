import { List, ListItem } from "@mui/material";
import React from "react";
import { Comment } from "../../../utility/MachineUtility";
import CommentPresentation from "./CommentPresentation";

interface Props {
  Comments: Array<Comment>;
  handleDeletion: (id: string) => void;
}

export function getCommentSortValue(a: Comment, b: Comment): number {
  if (a == null || b == null) return 0;
  if (a.createdAtUtc.getTime() < b.createdAtUtc.getTime()) {
    return 1;
  } else if (a.createdAtUtc.getTime() === b.createdAtUtc.getTime()) {
    return 0;
  } else {
    return -1;
  }
}

export default function CommentsSectionPresentation({
  Comments,
  handleDeletion,
}: Props) {
  const commentsExist = Comments != null && Comments.length > 0;
  if (commentsExist)
    Comments.sort((a: Comment, b: Comment) => getCommentSortValue(a, b));

  return (
    <List
      sx={{
        width: "100%",
        maxHeight: 360,
        overflow: "auto",
      }}
    >
      {commentsExist ? (
        Comments.map((comment, i) => (
          <ListItem key={comment.id} alignItems="flex-start">
            <CommentPresentation
              Id={comment.id}
              Commenter={comment.commenter?.name}
              CommentTime={comment.createdAtUtc}
              CommentContent={comment.content}
              HandleDeletion={handleDeletion}
            />
          </ListItem>
        ))
      ) : (
        <></>
      )}
    </List>
  );
}
