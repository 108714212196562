import React, { useState } from "react";
import { MachineWithOwner } from "../ViewMachineListContainer";
import AddMachineDialogPresentation from "./AddMachineDialogPresentation";

export interface AddMachineDialogContainerProps {
  open: boolean;
  onCancel: () => void;
  onAddMachine: (machineId: number) => void;
  machines: Array<MachineWithOwner>;
}

export default function AddMachineDialogContainer({
  open,
  onCancel,
  onAddMachine,
  machines,
}: AddMachineDialogContainerProps) {
  const [machineId, setMachineId] = useState<number | null>(null);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleCancel = () => {
    setMachineId(null);
    onCancel();
  };

  const showErrorInDialog = (errorMessageToDisplay: string) => {
    setDisplayError(true);
    setErrorMessage(errorMessageToDisplay);
  };

  const resetError = () => {
    setDisplayError(false);
    setErrorMessage("");
  };

  const handleAddMachine = (incomingMachineId: number | null) => {
    if (!incomingMachineId) {
      showErrorInDialog("Machine Id is invalid.");
      return;
    }

    if (machines.find((x) => x.machineView.machineId === incomingMachineId)) {
      showErrorInDialog(
        `A machine with machineId '${incomingMachineId}' already exists`
      );
      return;
    }

    onAddMachine(incomingMachineId);
    setMachineId(null);
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    const parsedInt = parseInt(value);
    resetError();

    if (isFinite(parsedInt)) {
      setMachineId(parsedInt);
    }
    if (value === "") {
      setMachineId(null);
    }
  };

  return (
    <AddMachineDialogPresentation
      open={open}
      machineId={machineId}
      displayError={displayError}
      errorMessage={errorMessage}
      onChange={handleChange}
      onCancel={handleCancel}
      onAddMachine={handleAddMachine}
    />
  );
}
