import Delete from "@mui/icons-material/Delete";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { toISO8601DateTimeStringLocal } from "../../../utility/DateUtility";

interface Props {
  Id: string;
  Commenter: string;
  CommentTime: Date;
  CommentContent: string;
  HandleDeletion: (id: string) => void;
}

export default function CommentPresentation({
  Id,
  Commenter,
  CommentTime,
  CommentContent,
  HandleDeletion,
}: Props) {
  const handleDelete = useCallback(() => {
    HandleDeletion(Id);
  }, [HandleDeletion, Id]);

  return (
    <Card variant="outlined">
      <CardContent>
        <CardHeader
          title={Commenter ?? "Unknown"}
          subheader={`Commented on ${toISO8601DateTimeStringLocal(
            CommentTime
          )}`}
          action={
            <IconButton aria-label="settings" onClick={handleDelete}>
              <Delete />
            </IconButton>
          }
        />
        <Typography variant="body1">{CommentContent ?? ""}</Typography>
      </CardContent>
    </Card>
  );
}
