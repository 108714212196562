import {
  getDaysBetweenDates,
  toISO8601DateString,
} from "../../utility/DateUtility";
import { GetSubscriptionName } from "../../utility/SubscriptionsUtility";

export const CreateColumns = () => {
  return [
    {
      field: "machineNumber",
      headerName: "Machine Id",
      flex: 1,
    },
    {
      field: "companyName",
      headerName: "Company",
      flex: 1,
    },
    {
      field: "debtorNumber",
      headerName: "Debtor Number",
      flex: 1,
    },
    {
      field: "amountDue",
      headerName: "Amount Due",
      renderCell: (params) => "€ " + params.value,
      flex: 1,
    },
    {
      field: "subscription",
      headerName: "Module",
      valueGetter: (params) => GetSubscriptionName(params.value),
      flex: 1,
    },
    {
      field: "invoiceStatus",
      headerName: "Status",
      valueGetter: (params) => mapStatusToLabel(params.value),
      flex: 1,
    },
    {
      field: "subscriptionStartDate",
      headerName: "Start Date",
      flex: 1,
    },
    {
      field: "subscriptionEndDate",
      headerName: "End Date",
      flex: 1,
    },
    {
      field: "daysLeft",
      headerName: "Next Invoice",
      valueGetter: (params) => {
        return getDaysBetweenDates(
          toISO8601DateString(new Date()),
          params.row.subscriptionEndDate
        );
      },
      renderCell: (params) => params.value + " days",
      flex: 1,
    },
    {
      field: "invoiceCreatedDate",
      headerName: "Created At",
      flex: 1,
    },
  ];
};

function mapStatusToLabel(status: string) {
  switch (status) {
    case "ReadyToInvoice":
      return "Ready to invoice";
    case "SentToInvoicingSystem":
      return "Received by LN";
  }
}
