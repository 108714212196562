import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Feature,
  useAuthorization,
} from "../../globalHooks/authorizationHooks";

interface ProtectedButtonProps {
  children: any;
  variant?: "text" | "outlined" | "contained";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  requiredFeature: Feature;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  className?: string;
  startIcon?: any;
}

export default function ProtectedButton({
  children,
  variant,
  color,
  requiredFeature,
  onClick,
  disabled,
  size,
  className,
  startIcon,
}: ProtectedButtonProps) {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const authorization = useAuthorization();

  useEffect(() => {
    if (authorization.features.includes(requiredFeature)) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [authorization]);

  return isAuthorized ? (
    <Button
      variant={variant}
      disabled={disabled}
      color={color}
      onClick={onClick}
      size={size}
      className={className}
      startIcon={startIcon}
    >
      {children}
    </Button>
  ) : (
    <></>
  );
}
