import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import React from "react";
import { Feature } from "../../globalHooks/authorizationHooks";
import ProtectedIconButton from "../ui/ProtectedIconButton";

type StringFunction = (str: string) => void;

function getFullName(params) {
  return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

export const CreateColumns = (
  onResetUserMfa: StringFunction,
  onDeleteUser: StringFunction
) => {
  return [
    {
      field: "fullName",
      headerName: "Full name",
      width: 160,
      valueGetter: getFullName,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "assignedMachines",
      headerName: "Assigned Machines",
      flex: 1,
    },
    {
      field: "lastLoginDate",
      headerName: "Last login date",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <>
            <ProtectedIconButton
              onClick={() => onResetUserMfa(params.row.email)}
              requiredFeature={Feature.CustomerCareManageUsers}
              color={"primary"}
              tooltip={`Reset MFA for ${params.row.email} (requires confirmation)`}
            >
              <PhonelinkEraseIcon color="secondary" />
            </ProtectedIconButton>
            <ProtectedIconButton
              onClick={() => onDeleteUser(params.row.email)}
              requiredFeature={Feature.CustomerCareManageUsers}
              color={"error"}
              tooltip={`Delete user ${params.row.email} (requires confirmation)`}
            >
              <DeleteForeverIcon color="secondary" />
            </ProtectedIconButton>
          </>
        );
      },
      disableColumnMenu: true,
      sortable: false,
    },
  ];
};
