import { createContext, Dispatch, useContext } from "react";
import { AxiosHttpClient } from "../api/AxiosHttpClient";
import CustomerCareUserService from "../api/CustomerCareUser/CustomerCareUserService";
import ICustomerCareUserService from "../api/CustomerCareUser/ICustomerCareUserService";
import IMachineService from "../api/Machine/IMachineService";
import MachineService from "../api/Machine/MachineService";
import { AlertMessage } from "../components/ui/Alert/Alert";
import { UserResult } from "../globalHooks/authorizationHooks";
import { Action } from "./Reducer";

/**
 * Interfaces for sections of the global state
 */

export interface IServices {
  machineService: IMachineService;
  customerCareUserService: ICustomerCareUserService;
}

export interface IGlobalState {
  services: IServices;
  alerts: AlertsState;
  myUser: UserResult;
  myUserIsLoading: boolean;
}

/**
 * Initial global state values
 */

export const initialAlertsState: AlertsState = {
  alertMessages: [],
};

export const axiosHttpClient = new AxiosHttpClient();

export const services: IServices = {
  machineService: new MachineService(axiosHttpClient),
  customerCareUserService: new CustomerCareUserService(axiosHttpClient),
};

export const initialState: IGlobalState = {
  services: services,
  alerts: initialAlertsState,
  myUser: {
    machines: [],
    userSystemWideAuthorization: {
      features: [],
      roles: [],
      lockedMachines: [],
    },
  },
  myUserIsLoading: true,
};

/**
 * Create a new global state with a temporary Reducer.
 * The actual reducer is created when the GlobalContext.Provider is initialized.
 */
export const GlobalContext = createContext<[IGlobalState, Dispatch<Action>]>([
  initialState,
  () => {},
]);

/**
 * Hooks that can be used to fetch or mutate global state.
 */

export const useGlobalContext = () => useContext(GlobalContext);
export const useServiceContextState: () => IServices = () =>
  useGlobalContext()[0].services;
export const useAlertsState = () => useGlobalContext()[0].alerts;
export const useMyUserState = () => useGlobalContext()[0].myUser;
export const useMyUserIsLoadingState = () =>
  useGlobalContext()[0].myUserIsLoading;

export interface AlertsState {
  alertMessages: AlertMessage[];
}

export const useAlertsActions = () => {
  const dispatch = useGlobalContext()[1];

  const updateAlertsState = (state: AlertsState) => {
    dispatch({
      type: "UPDATE_ALERTS_STATE",
      payload: state,
    });
  };

  return {
    updateAlertsState: updateAlertsState,
  };
};

export const useUpdateMyUser = () => {
  const dispatch = useGlobalContext()[1];

  const updateMyUser = (myUser: UserResult) => {
    dispatch({
      type: "UPDATE_MY_USER",
      payload: myUser,
    });
  };

  const updateMyUserIsLoading = (myUserIsLoading: boolean) => {
    dispatch({
      type: "UPDATE_MY_USER_IS_LOADING",
      payload: myUserIsLoading,
    });
  };

  return {
    updateMyUser: updateMyUser,
    updateMyUserIsLoading: updateMyUserIsLoading,
  };
};
