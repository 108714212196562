export const colors = {
  // Style Guide - Basic Colors
  mobaBlue: "#004082",
  lightBlue: "#0088FF",
  paleBlue: "#EDF6FF",
  mobaOrange: "#F08112",

  // Style Guide - Basic Greys
  black: "#272727",
  greyVeryLight: "#F4F4F4",
  grey40: "#666666",
  grey67: "#AAAAAA",
  grey87: "#DDDDDD",
  grey93: "#EEEEEE",
  white: "#E8E8E8",

  // Style Guide - Notification Colors
  priorityRed: "#E60000",
  priorityOrange: "#FFA200",
  priorityBlue: "#0060BF",

  // Style Guide - Egg Colors
  dirtGreen: "#3CB300",
  crackBlue: "#0094D6",
  leakerYellow: "#C9B800",
  bloodRed: "#BF0000",
  specialPurple: "#BF00BF",

  // iMoba v1.0 - Egg Colors
  cageEgg: "#B05678",
  barnEgg: "#885780",
  freeRangeEgg: "#6CA6C0",
  organicEgg: "#7A9355",
  unmappedEgg: "#D9534F",

  // Info Support - Text Colors
  textBlue: "#394263",

  // Misc. colors
  machineRunning: "#5CB85C",
  staffBreak: "#F26722",
  machineStop: "#004980",
  primarySelected: "#1e296d",

  // Performance Pro colors
  performanceProChart: "#D9DAE5",
  performanceProChartBorder: "#A4ACC5",
  performanceProChartDarker: "#828289",

  // Datatable colors
  rowBackgroundColor: "#EDF6FF",

  // User chip color
  pendingChip: "#f0ad4e",

  // User and alarm chip color
  activeChip: "#5cb85c",

  // Display target color
  whitesmoke: "whitesmoke",
};

export const offgradeColors = {
  leaker: "#f9b341",
  dirt: "#8b5e3c",
  crack: "#6e7ca3",
  blood: "#b21226",
  other: "#6a6a6a",
};

export const brownScaleColors = [
  "#fff6da",
  "#f3b975",
  "#e6a667",
  "#e59a57",
  "#cc7326",
  "#cd651d",
  "#bc5313",
  "#a5420e",
  "#af552e",
  "#872603",
];

export const shellStrengthColors = [
  "#d9dce2",
  "#c6c9dc",
  "#959ebd",
  "#6f7da5",
  "#466190",
  "#21427b",
  "#1a325b",
  "#0d2040",
  "#021337",
  "#000b24",
];

export const laneColors = {
  outlet: "#843C0B",
  end: "Red",
  weight: [
    "#9FA8dA",
    "#838ecf",
    "#7582ca",
    "#5868bf",
    "#4353ad",
    "#384591",
    "#323f83",
    "#2d3874",
    "#273166",
    "#222a58",
  ],
  production: [
    "#80B300",
    "#c44d4d",
    "#6680B3",
    "#1AB399",
    "#3C6104",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#33FFCC",
    "#E6331A",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#809900",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
  ],
};
