import { FormLabel, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

interface FormSelectProps {
  label: string;
  inputJsx: any;
}

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
  },
}));

/**
 * Displays Labels left of the form
 */
export default function InputWithLeftLabel({
  label,
  inputJsx,
}: FormSelectProps) {
  const classes = useStyles();
  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} md={5}>
        <FormLabel>{label}</FormLabel>
      </Grid>
      <Grid item xs={12} md={5}>
        {inputJsx}
      </Grid>
      <Grid item xs={12} md={1}></Grid>
    </Grid>
  );
}
