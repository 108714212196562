import React from "react";
import { MachineDiagnosticsQueryNames } from "../../../api/Queries";
import { MachineDiagnostic } from "../../../types/MachineDiagnostics/MachineDiagnosticEnum";
import BaseMachineDiagnosticsPage from "../machine-diagnostics-page/BaseMachineDiagnosticsPage";

export default function MachineDiagnosticsLifesignPage() {
  return (
    <BaseMachineDiagnosticsPage
      machineDiagnostic={MachineDiagnostic.NoDataUploaded}
      machineDiagnosticQueryKey={MachineDiagnosticsQueryNames.Lifesign}
      pageTitle={"Machine Diagnostics: No data uploaded in last 24 hours"}
      explanationText={
        "This page displays all machines that do not have uploaded any data to iMoba in the 24 hours. Note: timestamps are displayed in UTC time."
      }
      cardWarningSeverity="error"
      cardDescription={"No data uploaded in the 24 hours (UTC)"}
    />
  );
}
