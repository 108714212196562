import { Chip, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AlarmIcon from "@mui/icons-material/Alarm";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DoneIcon from "@mui/icons-material/Done";
import PauseIcon from "@mui/icons-material/Pause";
import React, { useLayoutEffect, useRef, useState } from "react";
import { colors } from "../../../colors";

const useStyles = makeStyles((theme) => ({
  chips: {
    maxWidth: 300,
    overflowX: "scroll",
    overflowY: "hidden",
    scrollbarWidth: "none",
    whiteSpace: "nowrap",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    gap: theme.spacing(1),
  },
  chipsSection: {
    display: "flex",
  },
  timeIcon: {
    width: 30,
    height: 30,
    color: colors.grey40,
  },
  icon: {
    color: "white",
  },
  inActiveIcon: {
    color: "black",
  },
  inActiveChip: {
    marginRight: theme.spacing(1),
    backgroundColor: colors.grey67,
    color: "black",
  },
  activeChip: {
    marginRight: theme.spacing(1),
    backgroundColor: colors.dirtGreen,
    color: "white",
  },
  renewChip: {
    marginRight: theme.spacing(1),
    backgroundColor: colors.mobaBlue,
    color: "white",
  },
  expiresChip: {
    marginRight: theme.spacing(1),
    backgroundColor: colors.mobaOrange,
    color: "white",
  },
  buttonLeft: {
    marginLeft: -theme.spacing(2),
  },
  buttonRight: {
    marginRight: -theme.spacing(2),
  },
  chipButtonRoot: {
    padding: theme.spacing(1),
  },
}));

export default function SubcriptionChips({
  isExpiringSoon,
  isAutoRenewal,
  isActive,
}: {
  isExpiringSoon: boolean;
  isAutoRenewal: boolean;
  isActive: boolean;
}) {
  const classes = useStyles();
  const ref: any = useRef(null);
  const scroll = (scrollOffset: number) => {
    if (!ref) return;
    const node: any = ref.current;
    if (!node) return;
    node.scrollLeft += scrollOffset;
  };

  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useLayoutEffect(() => {
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowScrollButtons(true);
    }
  }, [ref]);

  return (
    <div className={classes.chipsSection}>
      {showScrollButtons && (
        <IconButton
          className={classes.buttonLeft}
          onClick={() => scroll(-100)}
          classes={{
            root: classes.chipButtonRoot,
          }}
          size="large">
          <ChevronLeftIcon />
        </IconButton>
      )}
      <div className={classes.chips} ref={ref}>
        {isExpiringSoon && (
          <Chip
            className={classes.expiresChip}
            icon={<AlarmIcon className={classes.icon} />}
            label="Expires soon"
          />
        )}
        {isAutoRenewal ? (
          <Chip
            className={classes.renewChip}
            icon={<AutorenewIcon className={classes.icon} />}
            label="Renews automatically"
          />
        ) : (
          <Chip
            className={classes.inActiveChip}
            icon={<AlarmOffIcon className={classes.inActiveIcon} />}
            label="Manual renewal"
          />
        )}
        {isActive ? (
          <Chip
            className={classes.activeChip}
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
          />
        ) : (
          <Chip
            className={classes.inActiveChip}
            icon={<PauseIcon className={classes.inActiveIcon} />}
            label="Inactive"
          />
        )}
      </div>
      {showScrollButtons && (
        <IconButton
          className={classes.buttonRight}
          onClick={() => scroll(100)}
          classes={{
            root: classes.chipButtonRoot,
          }}
          size="large">
          <ChevronRightIcon />
        </IconButton>
      )}
    </div>
  );
}
