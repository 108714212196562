import MuiAlert, { AlertColor } from "@mui/lab/Alert";
import { Button } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import React from "react";

interface Props {
  muiSeverity: AlertColor | undefined;
  numberOfMachines: number;
  ignoredMachinesCount: number | undefined;
  cardDescription: string;
  cardButtonlabel?: string;
  onClick?: () => void;
}

const formatCardTitle = (
  numberOfMachines: number,
  ignoredMachinesCount: number | undefined
): string => {
  const formattedTitle =
    numberOfMachines === 1 ? "1 machine " : `${numberOfMachines} machines `;
  const ignoredMachinesSection =
    ignoredMachinesCount !== undefined
      ? "(" + ignoredMachinesCount + " ignored)"
      : "";
  return formattedTitle + ignoredMachinesSection;
};

export function MachineStatusCard({
  muiSeverity,
  numberOfMachines,
  ignoredMachinesCount,
  cardDescription,
  cardButtonlabel,
  onClick,
}: Props) {
  return (
    <MuiAlert
      severity={muiSeverity}
      action={
        cardButtonlabel !== undefined &&
        onClick !== undefined && (
          <Button color="inherit" size="small" onClick={onClick}>
            {cardButtonlabel}
          </Button>
        )
      }
    >
      <AlertTitle>
        {formatCardTitle(numberOfMachines, ignoredMachinesCount)}
      </AlertTitle>
      {cardDescription}
    </MuiAlert>
  );
}
