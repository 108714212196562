import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useMemo, useState } from "react";

interface Props {
  open: boolean;
  machineId: MachineId;
  isLoading: boolean;
  onClose: () => void;
  onCancel: () => void;
  onAccept: () => void;
}

const useStyles = makeStyles((theme) => ({
  extraConfirmationSection: {
    marginTop: theme.spacing(2),
  },
  checkBox: {
    marginTop: theme.spacing(1),
  },
}));

export default function CredentialsDialog({
  open,
  machineId,
  onClose,
  onCancel,
  onAccept,
  isLoading,
}: Props) {
  const classes = useStyles();
  const [confirmationCheck, setConfirmationCheck] = useState(false);

  const generateButtonDisabled = useMemo(() => {
    return !confirmationCheck;
  }, [confirmationCheck]);

  const handleCheckboxChecked = useCallback(
    () => setConfirmationCheck(!confirmationCheck),
    [confirmationCheck]
  );

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          Generate client credentials for machine '{machineId}'?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Generating new credentials will <b>revoke</b> any previous
            credentials associated with this machine ({machineId}).
            <br />
            This will prevent the machine to upload data using the previously
            used credentials. Are you sure to generate new credentials for this
            machine?
          </DialogContentText>
          <div className={classes.extraConfirmationSection}>
            <FormControlLabel
              className={classes.checkBox}
              control={
                <Checkbox
                  checked={confirmationCheck}
                  onChange={handleCheckboxChecked}
                  color="primary"
                />
              }
              label={"Yes I am sure"}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onAccept}
            color="primary"
            disabled={generateButtonDisabled || isLoading}
            autoFocus
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
