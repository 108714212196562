import Alert from "@mui/lab/Alert";
import React from "react";

export function UserLastAdminOfMachineStep({
  lastAdminOfMachines,
}: {
  lastAdminOfMachines: Array<number>;
}) {
  return (
    <Alert severity="warning">
      This user is the last admin of the following machine(s):
      <ul>
        {lastAdminOfMachines.map((machineId, index) => {
          return <li key={index}>{machineId}</li>;
        })}
      </ul>
      Deleting this user will leave these machine(s) without an admin.{" "}
      <b>
        Without an admin, no user for this machine will receive emails regarding
        expiring subscriptions
      </b>
      .
    </Alert>
  );
}
