import React, { useCallback } from "react";
import AutoCompleteTextField from "../../AutocompleteTextField/AutoCompleteTextField";
import InputWithLeftLabel from "../../Forms/FormSelect";

interface Props {
  id: string;
  labelText: string;
  disabled: boolean;
  selectedOption: any;
  options: any[];
  formik: any;
  error: any;
  touched: any;
}

export const AutocompleteInput = ({
  id,
  labelText,
  disabled,
  options,
  selectedOption,
  formik,
  error,
  touched,
}: Props) => {
  const handleChange = useCallback(
    (machineType: string) => {
      formik.setFieldValue(id, machineType);
    },
    [formik, id]
  );

  return (
    <InputWithLeftLabel
      label={labelText}
      inputJsx={
        <AutoCompleteTextField
          disabled={disabled}
          options={options}
          selectedOption={selectedOption}
          onChange={handleChange}
          error={touched && Boolean(error)}
          helperText={touched && error}
        />
      }
    />
  );
};
