import { TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from "@mui/lab";
import React from "react";

const useStyles = makeStyles((theme: any) => ({
  retypeField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export function DefaultConfirmationStep({
  email,
  onType,
}: {
  email: string;
  onType: (value: string) => void;
}) {
  const classes = useStyles();

  return (
    <>
      <Alert severity="info">
        Deleting a user will remove all associations with iMoba, including:
        <ul>
          <li>User profile, preferences and activity</li>
          <li>Machine roles</li>
          <li>Auth0 user account</li>
          <li>Customer Care Team roles (if any)</li>
        </ul>
        The user event logs associated with this user will <b>not</b> be
        deleted.
      </Alert>
      <Typography className={classes.retypeField}>
        Please type the user's email address (<b>{email}</b>) to confirm
      </Typography>
      <TextField
        autoFocus
        fullWidth
        variant="outlined"
        onChange={(e) => onType(e.target.value)}
      />
    </>
  );
}
