import { Auth0Provider } from "@auth0/auth0-react";
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "fontsource-roboto";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import config from "./config";
import "./index.css";
import { GlobalContextProvider } from "./store/GlobalContextProvider";
import theme from "./theme";
import { ReactQueryProvider } from "./utility/ReactQueryProvider";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

ReactDOM.render(
  <Auth0Provider
    domain={config.REACT_APP_AUTH0_DOMAIN}
    clientId={config.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={config.REACT_APP_AUTH0_CALLBACK_URL}
    audience={config.REACT_APP_AUTH0_AUDIENCE}
  >
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider>
          <ReactQueryProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </ReactQueryProvider>
        </GlobalContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Auth0Provider>,

  document.getElementById("root")
);
