import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

export type AlertMessageType = "info" | "warning" | "error" | "success";

export type AlertMessage = {
  message: string;
  messageType: AlertMessageType;
};

export function AlertSnackbar({
  message,
  open,
  onClose,
}: {
  message?: AlertMessage;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      {message && (
        <MuiAlert
          elevation={0}
          variant="filled"
          severity={message.messageType}
          onClose={onClose}
        >
          {message.message}
        </MuiAlert>
      )}
    </Snackbar>
  );
}
