import React from "react";
import { MachineDiagnosticsQueryNames } from "../../../api/Queries";
import { MachineDiagnostic } from "../../../types/MachineDiagnostics/MachineDiagnosticEnum";
import BaseMachineDiagnosticsPage from "../machine-diagnostics-page/BaseMachineDiagnosticsPage";

export const numberOfQuestsTreshold = 50;

export default function MachineDiagnosticsQuestsPage() {
  return (
    <BaseMachineDiagnosticsPage
      machineDiagnostic={MachineDiagnostic.NoQuestResponsesReceived}
      machineDiagnosticQueryKey={MachineDiagnosticsQueryNames.Quests}
      pageTitle={"Machine Diagnostics: Not Receiving Quest Responses"}
      explanationText={`This page displays all machines that do not respond to at least ${numberOfQuestsTreshold} outstanding quest retries. Note: timestamps are displayed in UTC time.`}
      cardWarningSeverity="warning"
      cardDescription={`Not responding to at least ${numberOfQuestsTreshold} outstanding quests.`}
    />
  );
}
