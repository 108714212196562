import { LinearProgress, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

export interface PagePaperProps {
  isLoading: boolean;
  title: string;
  children?: React.ReactNode;
  extraInfo?: string;
}

const useStyles = makeStyles((theme) => ({
  p4: {
    padding: theme.spacing(4),
  },
  extraInfo: {},
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
}));

export default function PagePaper({
  isLoading,
  title,
  children,
  extraInfo,
}: PagePaperProps) {
  const classes = useStyles();

  return (
    <div>
      {isLoading && <LinearProgress />}
      <Paper className={classes.p4}>
        <div className={classes.container}>
          <Typography variant="h5">{title}</Typography>
          {extraInfo && (
            <Typography color={"textSecondary"} className={classes.extraInfo}>
              {extraInfo}
            </Typography>
          )}
        </div>

        {children}
      </Paper>
    </div>
  );
}
