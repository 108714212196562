import makeStyles from '@mui/styles/makeStyles';
import React from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
}));

export default function BasePage({ children }: any) {
  const classes = useStyles();
  return <div className={classes.wrapper}>{children}</div>;
}
