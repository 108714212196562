import { format } from "date-fns";
import { axiosHttpClient } from "../../store/GlobalContext";

export const downloadEventLogs = async (
  from: Date,
  until: Date
): Promise<void> => {
  const formattedFrom = format(from, "yyyy-MM-dd");
  const formattedUntil = format(until, "yyyy-MM-dd");

  const result = await axiosHttpClient.getBlob(
    `v1/eventlog?from=${formattedFrom}&until=${formattedUntil}`
  );

  if (!(result.data instanceof Blob)) return;

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(result.data);
  link.download = `EventLogs_${formattedFrom}_${formattedUntil}.xlsx`;
  link.click();
};
