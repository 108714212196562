import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from "@mui/icons-material/Lock";
import React from "react";
import PagePaper from "../../ui/PagePaper";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

export interface LockMachinePresentationProps {
  machineId: MachineId;
  isPageLoading: boolean;
  isMachineLocked: boolean;
  onClick: () => void;
}

export default function LockMachinePresentation({
  machineId,
  isPageLoading,
  isMachineLocked,
  onClick,
}: LockMachinePresentationProps) {
  const classes = useStyles();
  return (
    <PagePaper isLoading={isPageLoading} title={"Lock machine"}>
      <Typography variant="subtitle1">
        The button below locks machine `{machineId}`.
      </Typography>
      <Button
        variant="outlined"
        startIcon={<LockIcon />}
        onClick={onClick}
        className={classes.marginTop}
        disabled={isPageLoading || isMachineLocked}
      >
        Lock machine
      </Button>
    </PagePaper>
  );
}
