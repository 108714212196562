import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getMachine } from "../../api/Machine/MachineService";
import {
  activateSubscription,
  getMachineSubscriptions,
  updateSubscriptionEndDate,
} from "../../api/MachineSubscription/MachineSubscriptionService";
import {
  MachineQueryNames,
  MachineSubscriptionsQueryNames,
} from "../../api/Queries";
import { useCurrentUser } from "../../globalHooks/authHooks";
import { useAuthorization } from "../../globalHooks/authorizationHooks";
import {
  RedirectToIMoba,
  useMachineIdFromParams,
} from "../../utility/RedirectUtility";
import { MachineSubscription } from "../../utility/SubscriptionsUtility";
import { useDispatchAlert } from "../ui/Alert";
import ManageSubscriptionsPresentation from "./ManageSubscriptionsPresentation";

export interface MachineSubscriptions {
  machineSubscriptions: Array<MachineSubscription>;
}

export default function ManageSubscriptionsContainer() {
  const queryClient = useQueryClient();
  const machineId = useMachineIdFromParams();
  const dispatchAlert = useDispatchAlert();
  const [subscriptionToEdit, setSubscriptionToEdit] =
    useState<Nullable<MachineSubscription>>(null);
  const currentUser = useCurrentUser();
  const authorization = useAuthorization();
  const isMachineLocked = authorization.lockedMachines.includes(
    Number(machineId)
  );

  const handleOnChangeEndDateDialogClose = useCallback(() => {
    setSubscriptionToEdit(null);
  }, []);

  const machineSubscriptionsQuery = useQuery(
    MachineSubscriptionsQueryNames.GetMachineSubscriptions,
    () => getMachineSubscriptions(machineId),
    {
      onError: () => {
        dispatchAlert({
          message: "Something went wrong.",
          messageType: "error",
        });
      },
      select: (result: MachineSubscription[]) =>
        [...result].sort((a, b) => a.subscriptionType - b.subscriptionType),
    }
  );

  const { data: machineData } = useQuery(
    MachineQueryNames.GetMachine,
    () => getMachine(machineId),
    {
      onError: () => {
        dispatchAlert({
          message: "Something went wrong.",
          messageType: "error",
        });
      },
    }
  );

  const updateSubscriptionEndDateMutation = useMutation(
    (input: any) =>
      updateSubscriptionEndDate(
        input.machineId,
        input.subscriptionType,
        input.endDate,
        input.responsibleEmail
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          MachineSubscriptionsQueryNames.GetMachineSubscriptions,
        ]);
        dispatchAlert({
          message: "Successfully updated end date",
          messageType: "success",
        });
      },
      onError: () => {
        dispatchAlert({
          message: "Something went wrong.",
          messageType: "error",
        });
      },
    }
  );

  const activateSubscriptionMutation = useMutation(
    (input: any) =>
      activateSubscription(
        input.machineId,
        input.subscriptionType,
        input.endDate,
        input.responsibleEmail
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          MachineSubscriptionsQueryNames.GetMachineSubscriptions,
        ]);
        dispatchAlert({
          message: "Successfully activated subscription",
          messageType: "success",
        });
      },
      onError: () => {
        dispatchAlert({
          message: "Something went wrong.",
          messageType: "error",
        });
      },
    }
  );

  const changeEndDateDialogOpen = useMemo(() => {
    return subscriptionToEdit !== null;
  }, [subscriptionToEdit]);

  const handleSubmitEndDate = useCallback(
    (subscription: Nullable<MachineSubscription>, newEndDate: Date) => {
      if (subscription === null) return;
      handleOnChangeEndDateDialogClose();

      if (subscription.isActive) {
        updateSubscriptionEndDateMutation.mutate({
          machineId: machineId,
          subscriptionType: subscription.subscriptionType,
          endDate: newEndDate,
          responsibleEmail: currentUser.email ?? "",
        });
      } else {
        activateSubscriptionMutation.mutate({
          machineId: machineId,
          subscriptionType: subscription.subscriptionType,
          endDate: newEndDate,
          responsibleEmail: currentUser.email ?? "",
        });
      }
    },
    [
      machineId,
      currentUser,
      activateSubscriptionMutation,
      handleOnChangeEndDateDialogClose,
      updateSubscriptionEndDateMutation,
    ]
  );

  const handleOnChangeEndDateClick = useCallback(
    (subscription: MachineSubscription) => {
      setSubscriptionToEdit(subscription);
    },
    []
  );

  const handleOnSubscriptionsButtonClick = useCallback(() => {
    RedirectToIMoba("management/machine", machineId);
  }, [machineId]);

  const machineSubscriptions =
    useMemo(() => {
      return machineSubscriptionsQuery?.data?.filter((x) =>
        machineData?.machineType && machineData?.machineType.includes("Forta")
          ? x.subscriptionType !== 3
          : true
      );
    }, [machineData, machineSubscriptionsQuery]) ?? [];

  return (
    <ManageSubscriptionsPresentation
      isPageLoading={updateSubscriptionEndDateMutation.isLoading}
      machineId={machineId}
      isMachineLocked={isMachineLocked}
      onChangeEndDateClick={handleOnChangeEndDateClick}
      onChangeEndDateDialogClose={handleOnChangeEndDateDialogClose}
      changeEndDateDialogOpen={changeEndDateDialogOpen}
      onSubscriptionsButtonClick={handleOnSubscriptionsButtonClick}
      onSubmitEndDate={handleSubmitEndDate}
      machineSubscriptions={machineSubscriptions}
      subscriptionToEdit={subscriptionToEdit}
    />
  );
}
