import { Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
}));

export function BackToMachineDiagnosticsButton() {
  const classes = useStyles();
  const browserHistory = useHistory();

  const handleClick = () => {
    browserHistory.push("/machine-diagnostics/");
  };

  return (
    <Button
      onClick={handleClick}
      color="primary"
      variant="outlined"
      className={classes.button}
    >
      Go to Machine Diagnostics Page
    </Button>
  );
}
