import { useParams } from "react-router-dom";
import config from "../config";

export type IMobaPage = "users/manage" | "management/machine" | "owner";

const iMobaBaseUrl = config.REACT_APP_IMOBA_BASE_URL;

export function RedirectToIMoba(page: IMobaPage, machineId: MachineId) {
  window.open(`${iMobaBaseUrl}/#/${page}/${machineId}`, "_blank");
}

export function useMachineIdFromParams() {
  const { machineId } = useParams<{ machineId: string }>();

  return Number(machineId);
}
