import GetAppIcon from "@mui/icons-material/GetApp";
import { ButtonGroup, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Feature } from "../../globalHooks/authorizationHooks";
import ProtectedButton from "../ui/ProtectedButton";

export interface ExportLogsPresentationProps {
  selectedFromDate: Date;
  selectedUntilDate: Date;
  onFromDateUpdated: (date: Date) => void;
  onUntilDateUpdated: (date: Date) => void;
  displayValidationErrorMessage: boolean;
  onDownloadLogs: (from: Date, until: Date) => void;
  isPageLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: "0.5rem",
  },
  datePicker: {
    marginTop: "1rem",
    marginRight: "1rem",
  },
  exportButton: {
    marginTop: "1rem",
  },
  exportIcon: {
    marginRight: "0.5rem",
  },
}));

function ExportLogsPresentation({
  selectedFromDate,
  selectedUntilDate,
  onFromDateUpdated,
  onUntilDateUpdated,
  displayValidationErrorMessage,
  onDownloadLogs,
  isPageLoading,
}: ExportLogsPresentationProps) {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="subtitle1" className={classes.subtitle}>
        By clicking the button, logs for all machines will be exported for the
        selected period. The maximum date range to export is limited to one
        year.
      </Typography>

      <DatePicker
        inputFormat={"dd MMM yyyy"}
        value={selectedFromDate}
        onChange={(date) => onFromDateUpdated(new Date(date!.getTime()))}
        maxDate={selectedUntilDate}
        className={classes.datePicker}
        renderInput={(props) => <TextField label="From" {...props} />}
      />
      <DatePicker
        inputFormat={"dd MMM yyyy"}
        value={selectedUntilDate}
        onChange={(date) => onUntilDateUpdated(new Date(date!.getTime()))}
        className={classes.datePicker}
        renderInput={(props) => <TextField label="Until" {...props} />}
      />

      <ButtonGroup>
        <ProtectedButton
          size="large"
          onClick={() => onDownloadLogs(selectedFromDate, selectedUntilDate)}
          className={classes.exportButton}
          disabled={isPageLoading}
          requiredFeature={Feature.CustomerCareReadUsers}
        >
          <GetAppIcon className={classes.exportIcon} />
          Export
        </ProtectedButton>
      </ButtonGroup>

      {displayValidationErrorMessage && (
        <Typography variant="body1" color="error">
          {"The maximum date range to export is limited to one year."}
        </Typography>
      )}
    </div>
  );
}

export default ExportLogsPresentation;
