import axios, { AxiosRequestConfig } from "axios";
import config from "../config";
import { dateFormatter } from "../utility/DateUtility";
import { IHttpClient } from "./IHttpClient";

export const axiosInstance = axios.create({
  baseURL: config.REACT_APP_API_BASE_URL,
});

/**
 * Gets the custom Axios Config for API requests
 */
export const getAxiosConfig = (params?: any): AxiosRequestConfig => {
  return {
    transformResponse: [
      function (data) {
        const result = JSON.parse(data, dateFormatter);

        return result;
      },
    ],
    ...params,
  };
};

/**
 * A default wrapper for the Axios HTTP client
 */
export class AxiosHttpClient implements IHttpClient {
  private authTokenGetter?: AuthTokenGetter;

  get(url: string, params?: any) {
    const getConfig = getAxiosConfig(params);

    if (this.authTokenGetter) {
      return this.authTokenGetter().then((token) => {
        return axiosInstance.get(url, addAuthToken(getConfig, token));
      });
    }

    return axiosInstance.get(url, getConfig);
  }

  getBlob(url: string, params?: any) {
    const getBlobConfig: AxiosRequestConfig = {responseType: "blob", ...params};

    if (this.authTokenGetter) {
      return this.authTokenGetter().then((token) => {
        return axiosInstance.get(url, addAuthToken(getBlobConfig, token));
      });
    }

    return axiosInstance.get(url, getBlobConfig);
  }

  put(url: string, body: any) {
    if (this.authTokenGetter) {
      return this.authTokenGetter().then((token) => {
        return axiosInstance.put(url, body, addAuthToken({}, token));
      });
    }

    return axiosInstance.put(url, body);
  }

  post(url: string, body: any): Promise<any> {
    if (this.authTokenGetter) {
      return this.authTokenGetter().then((token) => {
        return axiosInstance.post(url, body, addAuthToken({}, token));
      });
    }

    return axiosInstance.post(url, body);
  }

  delete(url: string, params?: any) {
    if (this.authTokenGetter) {
      return this.authTokenGetter().then((token) => {
        return axiosInstance.delete(url, addAuthToken(params, token));
      });
    }

    return axiosInstance.delete(url, params);
  }

  setTokenGetter(getter: AuthTokenGetter) {
    this.authTokenGetter = getter;
  }
}

function addAuthToken(
  axiosConfig: AxiosRequestConfig,
  token: string
): AxiosRequestConfig {
  return {
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      Authorization: `Bearer ${token}`,
    },
  };
}

export interface AuthTokenGetter {
  (): Promise<string>;
}
