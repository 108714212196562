import {ArrowForward, GetApp} from "@mui/icons-material";
import {Alert} from "@mui/lab";
import makeStyles from "@mui/styles/makeStyles";
import React, {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {getMachineExport} from "../../api/Machine/MachineService";
import {useMachineService} from "../../api/ServiceContext";
import {Feature} from "../../globalHooks/authorizationHooks";
import {Machine} from "../../types/Machine";
import {IsMachineInstalled} from "../../utility/MachineUtility";
import {useDispatchAlert} from "../ui/Alert";
import {ConfirmActionRetypeDialog} from "../ui/ConfirmActionRetypeDialog";
import PagePaper from "../ui/PagePaper";
import ProtectedButton from "../ui/ProtectedButton";
import TableSearch from "../ui/TableSearch";
import AddMachineDialogContainer from "./AddMachineDialog/AddMachineDialogContainer";
import ViewMachineListPresentation from "./ViewMachineListPresentation";

const useStyles = makeStyles((theme) => ({
  button: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
    },
    marginBottom: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export interface MachineWithOwner {
  machineView: Machine;
  ownerFullName: string;
  ownerPhone: string;
}

export default function ViewMachineListContainer() {
  const classes = useStyles();
  const [machines, setMachines] = useState<MachineWithOwner[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openAddMachineDialog, setOpenAddMachineDialog] =
    useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const browserHistory = useHistory();

  const machineService = useMachineService();
  const dispatchAlert = useDispatchAlert();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [retypeString, setRetypeString] = useState("");
  const [machineToDelete, setMachineToDelete] = useState<MachineId | null>(
    null
  );
  const [
    displayExtraCheckboxConfirmation,
    setDisplayExtraCheckboxConfirmation,
  ] = useState(false);

  useEffect(() => {
    setIsPageLoading(true);
    machineService
      .getMachines()
      .then((response) => {
        setMachines(response.data);
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  }, [machineService]);

  const searchedMachines = useMemo(() => {
    if (searchTerm.length === 0) {
      return machines;
    }
    return machines.filter((machine: MachineWithOwner) => {
      let values = Object.values(machine.machineView);
      values.push(machine.ownerFullName);
      values.push(machine.ownerPhone);

      return values.join(" ").toLowerCase().includes(searchTerm);
    });
  }, [machines, searchTerm]);

  const sortedMachines = useMemo(() => {
    return searchedMachines?.sort((m1, m2) =>
      m1.machineView.machineId > m2.machineView.machineId ? 1 : -1
    );
  }, [searchedMachines]);

  const handleEditMachine = (machineId: MachineId) => {
    browserHistory.push(`/machines/${machineId}`);
  };

  const handleViewMachine = (machineId: MachineId) => {
    browserHistory.push(`/machines/${machineId}`);
  };

  const handleOnSubscriptionIconClick = (machineId: MachineId) => {
    browserHistory.push(`/machines/${machineId}/subscriptions`);
  };

  const handleAddMachineCancel = () => {
    setOpenAddMachineDialog(false);
  };

  const handleAddMachine = (machineId: number) => {
    setOpenAddMachineDialog(false);
    setIsPageLoading(true);
    machineService
      .addMachine(machineId)
      .then((_: IApiResponse<Machine>) => {
        dispatchAlert({
          message: "Machine added successfully",
          messageType: "success",
        });
        machineService.getMachines().then((res) => {
          setMachines(res.data);
        });
      })
      .catch(() => {
        dispatchAlert({
          message: "Something went wrong while adding a machine.",
          messageType: "error",
        });
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const handleDeleteMachine = (machineId: MachineId) => {
    const machine = machines.find(
      (x) => x.machineView.machineId.toString() === machineId.toString()
    );

    if (!machine) return;

    if (IsMachineInstalled(machine.machineView.machineConnectionStatus)) {
      setDisplayExtraCheckboxConfirmation(false);
    } else {
      setDisplayExtraCheckboxConfirmation(true);
    }

    setDialogMessage(
      `Are you sure you want to delete machine ${machineId}? This action will permanently delete all data for this machine.`
    );
    setRetypeString(machineId.toString());
    setMachineToDelete(machineId);

    setDialogOpen(true);
  };

  const deleteMachine = (machineId: MachineId | null) => {
    if (!machineId) return;

    setIsPageLoading(true);
    machineService
      .deleteMachine(machineId)
      .then(() => {
        dispatchAlert({
          message: "Machine is marked for removal.",
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: "Error removing machine",
          messageType: "error",
        });
      })
      .finally(() => {
        setIsPageLoading(false);
        machineService.getMachines().then((res) => {
          setMachines(res.data);
        });
      });
  };

  const resetAndCloseDialog = () => {
    setDialogMessage("");
    setRetypeString("");
    setMachineToDelete(null);
    setDialogOpen(false);
  };

  const handleDeleteMachineConfirm = () => {
    deleteMachine(machineToDelete);
    resetAndCloseDialog();
  };

  const handleGetMachineExport = async () => {
    setIsPageLoading(true);
    try {
      await getMachineExport();
      dispatchAlert({
        message: "Successfully exported machines & subscriptions",
        messageType: "success",
      });
    } catch {
      dispatchAlert({
        message: "Error exporting machines & subscriptions",
        messageType: "error",
      });
    } finally {
      setIsPageLoading(false);
    }
  }
  
  return (
    <>
      <PagePaper isLoading={isPageLoading} title="Machine List">
        <div className={classes.wrapper}>
          <ProtectedButton
            variant="outlined"
            color="primary"
            disabled={isPageLoading}
            className={classes.button}
            onClick={handleGetMachineExport}
            requiredFeature={Feature.CustomerCareManageMachines}
          >
            <GetApp className={classes.icon} />
            Machine export
          </ProtectedButton>
          <ProtectedButton
            variant="outlined"
            color="primary"
            disabled={isPageLoading}
            className={classes.button}
            onClick={() => setOpenAddMachineDialog(true)}
            requiredFeature={Feature.CustomerCareManageMachines}
          >
            <ArrowForward className={classes.icon} />
            Add a machine
          </ProtectedButton>
          <TableSearch
            searchTerm={searchTerm}
            onChange={setSearchTerm}
            captionText={"Search on every field in this table"}
          />
          <ViewMachineListPresentation
            machines={sortedMachines}
            isPageLoading={isPageLoading}
            onViewMachine={handleViewMachine}
            onEditMachine={handleEditMachine}
            onDeleteMachine={handleDeleteMachine}
            onSubscriptionIconClick={handleOnSubscriptionIconClick}
          />
        </div>
      </PagePaper>
      <AddMachineDialogContainer
        open={openAddMachineDialog}
        onCancel={handleAddMachineCancel}
        onAddMachine={handleAddMachine}
        machines={machines}
      />
      <ConfirmActionRetypeDialog
        isOpen={dialogOpen}
        message={dialogMessage}
        stringToRetype={retypeString}
        displayExtraCheckboxConfirmation={displayExtraCheckboxConfirmation}
        extraInformationMessage={
          <Alert severity="info">
            Because a manual operation is required to fully delete this machine,
            please inform the supplier of iMoba:
            <br />
            <br />
            <b>E-mail address:</b> support@infosupport.com
            <br />
            <b>Subject:</b> [iMoba] Request for deletion of machine{" "}
            {machineToDelete}
          </Alert>
        }
        checkBoxLabel={"I have informed the supplier of iMoba"}
        onConfirm={() => handleDeleteMachineConfirm()}
        onReject={() => resetAndCloseDialog()}
      />
    </>
  );
}
