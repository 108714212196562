import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { Feature, SystemRole } from "../../globalHooks/authorizationHooks";
import { ICustomerCareUser } from "../../types/User";
import ProtectedIconButton from "../ui/ProtectedIconButton";

type CustomerCareFunction = (ccaccount: ICustomerCareUser) => void;

export const CreateColumns = (
  onEditCustomerCareUser: CustomerCareFunction,
  onDeleteCustomerCareUser: CustomerCareFunction
) => {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 3,
    },
    {
      field: "systemRoles",
      headerName: "Role",
      flex: 1,
      valueGetter: (params) =>
        GetSystemRoleDisplayName(params.row.systemRoles[0]),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <ProtectedIconButton
              onClick={() => onEditCustomerCareUser(params.row)}
              requiredFeature={Feature.CustomerCareManageCustomerCareUser}
              color={"primary"}
              tooltip={"Edit customer care user"}
            >
              <EditIcon />
            </ProtectedIconButton>
            <ProtectedIconButton
              onClick={() => onDeleteCustomerCareUser(params.row)}
              requiredFeature={Feature.CustomerCareManageCustomerCareUser}
              color={"primary"}
              tooltip={"Delete customer care user (confirmation required)"}
            >
              <DeleteIcon />
            </ProtectedIconButton>
          </>
        );
      },
      disableColumnMenu: true,
      sortable: false,
      minWidth: 140,
    },
  ];
};

export function GetSystemRoleDisplayName(role: SystemRole) {
  switch (role) {
    case "MemberCustomerCareApp":
      return "Member";
    case "AdminCustomerCareApp":
      return "Admin";
    case "AsmCustomerCareApp":
      return "ASM";
    default:
      return "Unknown";
  }
}
