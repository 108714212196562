import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "70vh",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "13em",
    margin: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: "7em",
    },
  },
  text: {
    fontSize: "1.5em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.2em",
    },
  },
}));

export default function UnauthorizedPage() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>401</h1>
      <Typography className={classes.text}>
        You are not authorized to view this page
      </Typography>
    </div>
  );
}
