import InputWithLeftLabel from "../../Forms/FormSelect";
import SystemRoleSelect from "../Inputs/SystemRoleSelect";
import { SystemRole } from "../../../globalHooks/authorizationHooks";
import { TextField, Typography } from "@mui/material";
import React from "react";

export function CustomerCareUserForm({
    values,
    errors,
    handleChange,
    touched,
    setFieldValue,
    isPageLoading,
    emailDisabled
  }: any) {
    return (
      <>
        <Typography variant="h5">Team member</Typography>
        <InputWithLeftLabel
          label={"Email *"}
          inputJsx={
            <TextField
              id="email"
              disabled={isPageLoading || emailDisabled}
              fullWidth
              value={values.email || ""}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              variant="outlined"
            />
          }
        />
  
        <InputWithLeftLabel
          label={"First name *"}
          inputJsx={
            <TextField
              id="firstName"
              disabled={isPageLoading}
              fullWidth
              value={values.firstName || ""}
              onChange={handleChange}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              variant="outlined"
            />
          }
        />
  
        <InputWithLeftLabel
          label={"Last name *"}
          inputJsx={
            <TextField
              id="lastName"
              disabled={isPageLoading}
              fullWidth
              value={values.lastName || ""}
              onChange={handleChange}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              variant="outlined"
            />
          }
        />

        <InputWithLeftLabel
          label={"Role *"}
          inputJsx={
            <SystemRoleSelect
              disabled={isPageLoading}
              selectedValue={values.systemRoles[0]}
              onChange={(value: SystemRole) => {
                setFieldValue("systemRoles", [value]);
              }}
            />
          }
        />
      </>
    );
  }