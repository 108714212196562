import { AxiosResponse } from "axios";
import { axiosHttpClient } from "../../store/GlobalContext";
import { GlobalAnnouncementModel } from "../../types/GlobalAnnouncementModel";

export const getGlobalAnnouncement = (): Promise<
  AxiosResponse<GlobalAnnouncementModel>
> => {
  return axiosHttpClient.get("v1/announcement");
};

export const setGlobalAnnouncement = (
  globalAnnouncementModel: GlobalAnnouncementModel
): Promise<void> => {
  return axiosHttpClient.post("v1/announcement", globalAnnouncementModel);
};
