import { axiosHttpClient } from "../../store/GlobalContext";
import { Comment, CreateCommentCommand } from "../../utility/MachineUtility";

export const addComment = async (
  comment: CreateCommentCommand
): Promise<void> => {
  return await axiosHttpClient.post("v1/MachineComments/comments", comment);
};

export const deleteComment = async (commentId: string): Promise<any> => {
  return await axiosHttpClient.delete(
    "v1/MachineComments/comments?commentId=" + commentId,
    {}
  );
};

export const getMachineComments = async (
  machineId: string
): Promise<Array<Comment>> => {
  const response = await axiosHttpClient.get(
    "v1/MachineComments/comments?machineId=" + machineId
  );
  return response.data?.results;
};
