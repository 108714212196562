import { differenceInMonths, startOfToday } from "date-fns/esm";

export interface MachineSubscription {
  autoRenew: boolean;
  fromUtc: Nullable<Date>;
  untilUtc: Nullable<Date>;
  isActive: boolean;
  subscriptionType: SubscriptionType;
}

export enum SubscriptionType {
  Unknown = 0,
  PerformancePro = 1,
  RealtimeDashboard = 2,
  Benchmark = 3,
}

export function GetSubscriptionName(subscriptionType: SubscriptionType) {
  switch (subscriptionType) {
    case SubscriptionType.PerformancePro:
      return "Performance Pro";
    case SubscriptionType.RealtimeDashboard:
      return "Realtime Dashboard";
    case SubscriptionType.Benchmark:
      return "Benchmark";
    case SubscriptionType.Unknown:
      return "";
    default:
      return "";
  }
}

/**
 *
 * @param machineSubscription
 * @returns True if the subscription is expiring within 3 month, false otherwise.
 */
export function IsExpiringSoon(machineSubscription: MachineSubscription) {
  const today = startOfToday();
  const expirationThresholdInMonth = 3;

  if (!machineSubscription.untilUtc) return false;

  return (
    differenceInMonths(machineSubscription.untilUtc, today) <
      expirationThresholdInMonth && machineSubscription.isActive
  );
}
