import { TableHead, TableRow } from "@mui/material";
import React from "react";
import { TableColumnLabel } from "../../../types/TableColumnLabel";
import SortableColumns, {
  descendingComparator,
} from "../../SortableTable/SortableColumns";
import { IMachineStatusTableRow } from "./common";

interface Props {
  machines: Array<IMachineStatusTableRow>;
  setSortedMachines: React.Dispatch<
    React.SetStateAction<IMachineStatusTableRow[]>
  >;
  tableColumnLabels: TableColumnLabel[];
  defaultSortedColumn: string;
}

export function DefaultTableHead({
  machines,
  setSortedMachines,
  tableColumnLabels,
  defaultSortedColumn,
}: Props) {
  return (
    <TableHead>
      <TableRow>
        <SortableColumns
          defaultOrder="asc"
          defaultSortedColumn={defaultSortedColumn}
          collection={machines}
          setCollection={setSortedMachines}
          columnLabels={tableColumnLabels}
          comparator={descendingComparator}
        />
      </TableRow>
    </TableHead>
  );
}
