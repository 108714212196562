import {
  AlertColor,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getMachineDiagnostic,
  setMachineDiagnosticIgnored
} from "../../../api/MachineDiagnostics/MachineDiagnostics";
import { MachineDiagnosticsQueryNames } from "../../../api/Queries";
import { BackToMachineDiagnosticsButton } from "../../../components/MachineDiagnostics/BackToMachineDiagnosticsButton/BackToMachineStatusButton";
import { MachineStatusCard } from "../../../components/MachineDiagnostics/MachineStatusCard/MachineStatusCard";
import { convertToTableRows } from "../../../components/MachineDiagnostics/MachineStatusTable/common";
import { MachineTableContainer } from "../../../components/MachineDiagnostics/MachineStatusTable/MachineTableContainer";
import BasePage from "../../../components/ui/BasePage";
import PagePaper from "../../../components/ui/PagePaper";
import { MachineDiagnostic } from "../../../types/MachineDiagnostics/MachineDiagnosticEnum";

export const numberOfQuestsTreshold = 50;

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  explanationText: {
    marginBottom: theme.spacing(1),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
}));

type MachineIgnoredChanged = {
  machineId: MachineId;
  newIgnoreValue: boolean;
};

interface Props {
  machineDiagnostic: MachineDiagnostic;
  machineDiagnosticQueryKey: MachineDiagnosticsQueryNames;
  pageTitle: string;
  explanationText: string;
  cardWarningSeverity: AlertColor;
  cardDescription: string;
}

export default function BaseMachineDiagnosticsPage({
  machineDiagnostic,
  machineDiagnosticQueryKey,
  pageTitle,
  explanationText,
  cardWarningSeverity,
  cardDescription,
}: Props) {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const machineDiagnostics = useQuery(
    machineDiagnosticQueryKey,
    () => getMachineDiagnostic(machineDiagnostic),
    {
      onSuccess: () => {
        setIgnoreLoading(false);
      },
    }
  );
  const [isIgnoreLoading, setIgnoreLoading] = useState(false);

  const setMachineIgnored = useMutation(
    (input: MachineIgnoredChanged) => {
      setIgnoreLoading(true);
      return setMachineDiagnosticIgnored(
        input.machineId,
        machineDiagnostic,
        input.newIgnoreValue
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [machineDiagnosticQueryKey],
        });
      },
    }
  );

  const handleDisableIgnore = (machineId: MachineId) => {
    setMachineIgnored.mutate({
      machineId: machineId,
      newIgnoreValue: false,
    });
  };

  const handleEnableIgnore = (machineId: MachineId) => {
    setMachineIgnored.mutate({
      machineId: machineId,
      newIgnoreValue: true,
    });
  };

  const machineRows = convertToTableRows(machineDiagnostics.data?.data);
  const ignoredMachineRows = machineRows.filter((x) => x.isIgnored);
  const activeMachineRows = machineRows.filter((x) => !x.isIgnored);

  return (
    <BasePage>
      <PagePaper isLoading={machineDiagnostics.isLoading} title={pageTitle}>
        <BackToMachineDiagnosticsButton />
        <Divider className={classes.divider} />
        <Typography variant="body1" className={classes.explanationText}>
          {explanationText}
        </Typography>

        {machineDiagnostics.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container>
              <Grid item md={3}>
                <MachineStatusCard
                  muiSeverity={cardWarningSeverity}
                  numberOfMachines={machineRows.length}
                  ignoredMachinesCount={ignoredMachineRows.length}
                  cardDescription={cardDescription}
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <div className={classes.tableContainer}>
              <Typography variant="h6">Diagnosed machines</Typography>
              <Typography variant="subtitle1">
                These machines are found since the last diagnostic check.
              </Typography>
              <MachineTableContainer
                machines={activeMachineRows}
                isLoading={isIgnoreLoading}
                isIgnoredMachineTable={false}
                onIgnoreMachineAction={handleEnableIgnore}
              />
            </div>
            <Divider className={classes.divider} />
            <div className={classes.tableContainer}>
              <Typography variant="h6">Ignored machines</Typography>
              <Typography variant="subtitle1">
                These machines are not included in diagnostic mail communcation.
              </Typography>
              <MachineTableContainer
                machines={ignoredMachineRows}
                isLoading={isIgnoreLoading}
                isIgnoredMachineTable={true}
                onIgnoreMachineAction={handleDisableIgnore}
              />
            </div>
          </>
        )}
      </PagePaper>
    </BasePage>
  );
}
