import Grid from "@mui/material/Grid";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../globalHooks/authorizationHooks";
import TableSearch from "../../ui/TableSearch";
import { IMachineStatusTableRow } from "./common";
import { MachineTablePresentation } from "./MachineTablePresentation";

interface Props {
  machines: Array<IMachineStatusTableRow>;
  isIgnoredMachineTable: boolean;
  isLoading: boolean;
  onIgnoreMachineAction: (machineId: MachineId) => void;
}

export function MachineTableContainer({
  machines,
  isLoading,
  isIgnoredMachineTable,
  onIgnoreMachineAction,
}: Props) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const browserHistory = useHistory();
  const authorization = useAuthorization();

  const isMachineLocked = (machine: IMachineStatusTableRow) =>
    authorization.lockedMachines.includes(Number(machine.machineId));

  const searchedMachines = useMemo(() => {
    if (searchTerm.length === 0) {
      return machines;
    }

    return machines.filter((machine: IMachineStatusTableRow) => {
      return Object.values(machine)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm);
    });
  }, [machines, searchTerm]);

  const [sortedMachines, setSortedMachines] = useState<
    IMachineStatusTableRow[]
  >([]);

  const handleEditMachine = (machineId: MachineId) => {
    browserHistory.push(`/machines/${machineId}`);
  };

  return (
    <>
      <TableSearch
        searchTerm={searchTerm}
        onChange={setSearchTerm}
        captionText={"Search on every field in this table"}
      />
      <Grid container>
        <Grid item xs={12} md={7}>
          <MachineTablePresentation
            machines={searchedMachines}
            isLoading={isLoading}
            isMachineLocked={isMachineLocked}
            onEditMachine={handleEditMachine}
            onViewMachine={handleEditMachine}
            onIgnoreMachineAction={onIgnoreMachineAction}
            setSortedMachines={setSortedMachines}
            sortedMachines={sortedMachines}
            isIgnoredMachineTable={isIgnoredMachineTable}
          />
        </Grid>
      </Grid>
    </>
  );
}
