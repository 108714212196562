import React from "react";
import BasePage from "../../components/ui/BasePage";
import ExportUsdaEggInformationHistoryContainer
  from "../../components/UsdaEggInformationHistory/ExportUsdaEggInformationHistoryContainer";

function UsdaEggInformationHistoryPage() {
  return (
    <BasePage>
      <ExportUsdaEggInformationHistoryContainer/>
    </BasePage>
  );
}

export default UsdaEggInformationHistoryPage;
