import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { colors } from "../../colors";
import { ICustomerCareUser } from "../../types/User";
import { CreateColumns } from "./ViewCustomerCareUserListUtil";

export interface ViewCustomerCareUserListPresentationProps {
  customerCareUsers: ICustomerCareUser[];
  onEditCustomerCareUser: (customerCareUser: ICustomerCareUser) => void;
  onDeleteCustomerCareUser: (customerCareUser: ICustomerCareUser) => void;
}

export function ViewCustomerCareUserListPresentation({
  customerCareUsers,
  onEditCustomerCareUser,
  onDeleteCustomerCareUser,
}: ViewCustomerCareUserListPresentationProps) {
  const columns = useMemo(() => {
    return CreateColumns(onEditCustomerCareUser, onDeleteCustomerCareUser);
  }, [onDeleteCustomerCareUser, onEditCustomerCareUser]);

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={customerCareUsers}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        getRowId={(row) => row.email}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.mobaBlue,
            color: "white",
          },
          "& .MuiDataGrid-sortIcon, & .MuiDataGrid-filterIcon, & .MuiDataGrid-menuIconButton":
            {
              opacity: 1,
              color: "white",
            },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(odd)": {
              backgroundColor: colors.rowBackgroundColor,
            },
          },
        }}
        isRowSelectable={() => false}
      />
    </div>
  );
}
