import React, { useCallback, useState } from "react";
import { useMutation } from "react-query";
import {
  GrantAccessResponse,
  grantAccessToMachineApi,
} from "../../../api/Machine/MachineService";
import { useDispatchAlert } from "../../ui/Alert";
import CredentialsDialog from "./CredentialsDialog";
import CredentialsPresentation from "./CredentialsPresentation";

interface Props {
  machineId: MachineId;
  isPageLoading: boolean;
  onLoad: (isLoading: boolean) => void;
}

export default function CredentialsContainer({
  machineId,
  isPageLoading,
  onLoad,
}: Props) {
  const dispatchAlert = useDispatchAlert();
  const [displayCredentials, setDisplayCredentials] = useState(false);
  const [openCredentialsDialog, setOpenCredentialsDialog] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const handleClick = useCallback(() => {
    setOpenCredentialsDialog(true);
  }, []);

  const handleCredentialsDialogClose = useCallback(() => {
    setOpenCredentialsDialog(false);
  }, []);

  const mutation = useMutation(
    (inputMachineId: MachineId) => grantAccessToMachineApi(inputMachineId),
    {
      onSuccess: (response: GrantAccessResponse) => {
        setClientId(response.clientId);
        setClientSecret(response.clientSecret);

        setDisplayCredentials(true);
        setOpenCredentialsDialog(false);
        dispatchAlert({
          message: `Successfully granted machine ${machineId} access to the Machine API.`,
          messageType: "success",
        });
      },
      onError: () => {
        dispatchAlert({
          message:
            "Something went wrong while retrieving client credentials. Please contact the software supplier.",
          messageType: "error",
        });
      },
      onSettled: () => {
        onLoad(false);
      },
    }
  );

  const handleCredentialsDialogAccept = useCallback(() => {
    mutation.mutate(machineId);
  }, [machineId, mutation]);

  return (
    <>
      <CredentialsPresentation
        machineId={machineId}
        isPageLoading={isPageLoading}
        displayCredentials={displayCredentials}
        onClick={handleClick}
        clientId={clientId}
        clientSecret={clientSecret}
      />
      <CredentialsDialog
        open={openCredentialsDialog}
        machineId={machineId}
        isLoading={mutation.isLoading}
        onClose={handleCredentialsDialogClose}
        onCancel={handleCredentialsDialogClose}
        onAccept={handleCredentialsDialogAccept}
      />
    </>
  );
}
