import { Country } from "../../components/EditMachine/useEditMachineData";
import { MachineWithOwner } from "../../components/viewMachineList/ViewMachineListContainer";
import { axiosHttpClient } from "../../store/GlobalContext";
import { Machine, PrepareForJsonBody } from "../../types/Machine";
import { AxiosHttpClient } from "../AxiosHttpClient";
import IMachineService from "./IMachineService";

export const getMachine = async (machineId: MachineId): Promise<Machine> => {
  const response = await axiosHttpClient.get(`v1/machine/${machineId}`);
  return response.data;
};

export const getCountries = async (): Promise<Array<Country>> => {
  const response = await axiosHttpClient.get("v1/machine/countries");
  return response.data;
};

export const getMachineTypes = async (): Promise<Array<string>> => {
  const response = await axiosHttpClient.get("v1/machine/machine-types");
  return response.data;
};

export const editMachine = async (machineData: Machine): Promise<void> => {
  const body = PrepareForJsonBody(machineData);
  const response = await axiosHttpClient.put(
    `v1/machine/${machineData.machineId}`,
    {
      ...body,
    }
  );
  return response.data;
};

export const getMachines = async (): Promise<MachineWithOwner[]> => {
  const response = await axiosHttpClient.get("v1/machine/list");
  return response.data;
};

export const grantAccessToMachineApi = async (
  machineId: MachineId
): Promise<GrantAccessResponse> => {
  const response = await axiosHttpClient.post(
    `v1/machine/grant-access/${machineId}/machine-api`,
    null
  );

  return response.data;
};

export const getMachineExport = async (): Promise<void> => {
  const response = await axiosHttpClient.getBlob("v1/machine/machines-export");
  if (!(response.data instanceof Blob)) return;

  let filename = response.headers["content-disposition"]
    .split(";")[1]
    .split("filename=")[1]
    .trim();

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(response.data);
  link.download = filename;
  link.click();
};

export interface GrantAccessResponse {
  clientId: string;
  clientSecret: string;
}

class MachineService implements IMachineService {
  private _httpClient: AxiosHttpClient;

  constructor(httpClient: AxiosHttpClient) {
    this._httpClient = httpClient;
  }

  getMachines() {
    return this._httpClient.get("v1/machine/list");
  }

  addMachine(machineId: number) {
    return this._httpClient.post(`v1/machine/${machineId}`, null);
  }

  lockMachine(machineId: number) {
    return this._httpClient.post(`v1/machine/${machineId}/lock`, null);
  }

  deleteMachine(machineId: number) {
    return this._httpClient.delete(`v1/machine/${machineId}`, {});
  }
}
export default MachineService;
