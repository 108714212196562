import { format, formatISO } from "date-fns";
import { axiosHttpClient } from "../../store/GlobalContext";
import {
  MachineSubscription,
  SubscriptionType,
} from "../../utility/SubscriptionsUtility";

export const getMachineSubscriptions = async (
  machineId: MachineId
): Promise<Array<MachineSubscription>> => {
  const response = await axiosHttpClient.get(
    `v1/machine/${machineId}/subscription`
  );
  return response.data.machineSubscriptions;
};

export const updateSubscriptionEndDate = async (
  machineId: MachineId,
  subscriptionType: SubscriptionType,
  endDate: Date,
  responsibleEmail: string
): Promise<void> => {
  await axiosHttpClient.put(
    `v1/machine/${machineId}/subscription/${subscriptionType}/period`,
    {
      machineId: machineId,
      subscriptionType: subscriptionType,
      untilUtc: format(endDate, "yyyy-MM-dd"),
      responsibleEmail: responsibleEmail,
    }
  );
};

export const activateSubscription = async (
  machineId: MachineId,
  subscriptionType: SubscriptionType,
  endDate: Date,
  responsibleEmail: string
): Promise<void> => {
  await axiosHttpClient.put(
    `v1/machine/${machineId}/subscription/${subscriptionType}/activate`,
    {
      machineId: machineId,
      subscriptionType: subscriptionType,
      untilUtc: formatISO(endDate, { representation: "date" }),
      responsibleEmail: responsibleEmail,
    }
  );
};
