import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getMachines } from "../../api/Machine/MachineService";
import { MachineQueryNames, UserQueryNames } from "../../api/Queries";
import { getUsers } from "../../api/User/UserService";
import { Feature } from "../../globalHooks/authorizationHooks";
import { RedirectToIMoba } from "../../utility/RedirectUtility";
import { ConfirmActionRetypeDialog } from "../ui/ConfirmActionRetypeDialog";
import PagePaper from "../ui/PagePaper";
import ProtectedButton from "../ui/ProtectedButton";
import TableSearch from "../ui/TableSearch";
import { MachineWithOwner } from "../viewMachineList/ViewMachineListContainer";
import AddUserDialogContainer from "./AddUserDialog/AddUserDialogContainer";
import { DeleteUserStepperDialog } from "./DeleteUserStepperDialog";
import { useDeleteUser, useResetUserMfa } from "./userListHooks";
import ViewUserListPresentation from "./ViewUserListPresentation";

const useStyles = makeStyles((theme) => ({
  button: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
    },
  },
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function ViewUserListContainer() {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);

  const userQuery = useQuery(UserQueryNames.GetAllUsers, () => getUsers());
  const machinesQuery = useQuery(MachineQueryNames.GetAllMachines, () =>
    getMachines()
  );
  const isPageLoading = machinesQuery.isFetching || userQuery.isFetching;

  const {
    userResetMfaDialogOpen,
    userResetMfaDialogMessage,
    userMfaToReset,
    resetMfaLoading,
    resetMfa,
    handleUserResetMfa,
    resetAndCloseDialog,
  } = useResetUserMfa();

  const {
    lastAdminOfMachines,
    ownerOfMachines,
    deleteUserDialogOpen,
    emailOfUserToDelete,
    resetAndCloseDeleteUserDialog,
    handleOnDeleteUser,
    deleteUserMutation,
  } = useDeleteUser();

  const searchedUsers = useMemo(() => {
    if (userQuery.data === undefined) return [];
    if (searchTerm.length === 0) return userQuery.data;

    return userQuery.data
      .filter((u) => {
        return (
          `${u.firstName} ${u.lastName}`
            .toLocaleLowerCase()
            .includes(searchTerm) ||
          u.email.toLowerCase().includes(searchTerm) ||
          u.company.toLowerCase().includes(searchTerm) ||
          u.phone.includes(searchTerm) ||
          u.country.toLowerCase().includes(searchTerm) ||
          u.assignedMachines.join(",").includes(searchTerm) ||
          u.lastLoginDateUtc?.toString().includes(searchTerm)
        );
      })
      .sort((u1, u2) =>
        `${u1.firstName} ${u1.lastName}`.localeCompare(
          `${u2.firstName} ${u2.lastName}`
        )
      );
  }, [userQuery.data, searchTerm]);

  const handleAddUserDialogOnClick = useCallback(
    (machine: MachineWithOwner) => {
      RedirectToIMoba("users/manage", machine.machineView.machineId);
    },
    []
  );

  const handleAddUserDialogOnClose = useCallback(
    () => setOpenAddUserDialog(false),
    []
  );

  const handleOnAddUserButtonClick = useCallback(
    () => setOpenAddUserDialog(true),
    []
  );

  const handleUserResetDialogOnConfirm = useCallback(
    () => resetMfa(userMfaToReset),
    [resetMfa, userMfaToReset]
  );

  const handleUserResetDialogOnReject = useCallback(
    () => resetAndCloseDialog(),
    [resetAndCloseDialog]
  );

  const handleDeleteUserStepperDialogOnConfirm = useCallback(
    () => deleteUserMutation.mutate(emailOfUserToDelete),
    [deleteUserMutation, emailOfUserToDelete]
  );

  const handleDeleteUserStepperDialogOnReject = useCallback(
    () => resetAndCloseDeleteUserDialog(),
    [resetAndCloseDeleteUserDialog]
  );

  return (
    <PagePaper isLoading={isPageLoading || resetMfaLoading} title="iMoba Users">
      <div className={classes.wrapper}>
        <ProtectedButton
          variant="outlined"
          color="primary"
          disabled={isPageLoading}
          className={classes.button}
          onClick={handleOnAddUserButtonClick}
          startIcon={<ArrowForwardIcon />}
          requiredFeature={Feature.CustomerCareManageMachines}
        >
          Add a user
        </ProtectedButton>
        <TableSearch
          searchTerm={searchTerm}
          onChange={setSearchTerm}
          captionText={"Search on every field in this table"}
        />
      </div>
      <ViewUserListPresentation
        users={searchedUsers}
        onResetUserMfa={handleUserResetMfa}
        onDeleteUser={handleOnDeleteUser}
      />
      <AddUserDialogContainer
        open={openAddUserDialog}
        machines={machinesQuery.data ?? []}
        onClick={handleAddUserDialogOnClick}
        onClose={handleAddUserDialogOnClose}
      />
      <ConfirmActionRetypeDialog
        isOpen={userResetMfaDialogOpen}
        message={userResetMfaDialogMessage}
        stringToRetype={userMfaToReset}
        onConfirm={handleUserResetDialogOnConfirm}
        onReject={handleUserResetDialogOnReject}
        displayExtraCheckboxConfirmation={false}
        extraInformationMessage={""}
        checkBoxLabel={""}
        informationAlertMessage={
          "After resetting the MFA, the user must go through the MFA-flow again before being able to login again."
        }
      />
      <DeleteUserStepperDialog
        isOpen={deleteUserDialogOpen}
        email={emailOfUserToDelete}
        lastAdminOfMachines={lastAdminOfMachines}
        ownerOfMachines={ownerOfMachines}
        onConfirm={handleDeleteUserStepperDialogOnConfirm}
        onReject={handleDeleteUserStepperDialogOnReject}
      />
    </PagePaper>
  );
}

export default ViewUserListContainer;
