import { TextField } from "@mui/material";
import React from "react";
import InputWithLeftLabel from "../../Forms/FormSelect";

interface Props {
  id: string;
  labelText: string;
  disabled: boolean;
  value: any;
  formik: any;
  error: any;
  touched: any;
  multiline?: boolean;
  rows?: number;
  className?: string;
  inputProps?: any;
}

export const FormInput = ({
  id,
  labelText,
  disabled,
  value,
  formik,
  error,
  touched,
  multiline,
  rows,
  className,
  inputProps,
}: Props) => {
  return (
    <InputWithLeftLabel
      label={labelText}
      inputJsx={
        <TextField
          id={id}
          disabled={disabled}
          fullWidth
          multiline={multiline}
          rows={rows}
          inputProps={inputProps}
          value={value}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={touched && Boolean(error)}
          helperText={touched && error}
          variant="outlined"
        />
      }
    />
  );
};
