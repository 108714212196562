import React, { useEffect, useState } from "react";
import { MachineSubscription } from "../../../utility/SubscriptionsUtility";
import ChangeSubscriptionDialogPresentation from "./ChangeSubscriptionDialogPresentation";

interface Props {
  open: boolean;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  onClose: () => void;
  onSubmit: (newEndDate: Date) => void;
  subscription: Nullable<MachineSubscription>;
}

export default function ChangeSubscriptionDialogContainer({
  open,
  startDate,
  endDate,
  onClose,
  onSubmit,
  subscription,
}: Props) {
  const [selectedDate, setSelectedDate] = useState<Date>(endDate ?? new Date());

  useEffect(() => {
    setSelectedDate(endDate ?? new Date());
  }, [endDate]);

  const handleOnChange = (value: Date | null) => {
    if (!value) return;

    const date = value.getTime();

    if (!date) return;

    setSelectedDate(new Date(date));
  };

  const handleClose = () => {
    setSelectedDate(endDate ?? new Date());
    onClose();
  };

  const handleOnSubmit = () => {
    onSubmit(selectedDate);
  };

  return (
    <ChangeSubscriptionDialogPresentation
      open={open}
      startDate={startDate}
      endDate={selectedDate}
      onChange={handleOnChange}
      onClose={handleClose}
      onSubmit={handleOnSubmit}
      subscription={subscription}
    />
  );
}
