import React from "react";
import { IMachineStatusTableRow } from "./common";
import { MachineIgnoredTablePresentation } from "./Ignored/MachineIgnoredTablePresentation";
import { MachineStatusTablePresentation } from "./Status/MachineStatusTablePresentation";

interface Props {
  machines: Array<IMachineStatusTableRow>;
  sortedMachines: Array<IMachineStatusTableRow>;
  onViewMachine: (machine: MachineId) => void;
  onEditMachine: (machine: MachineId) => void;
  onIgnoreMachineAction: (machineId: MachineId) => void;
  isMachineLocked: (machine: IMachineStatusTableRow) => boolean;
  setSortedMachines: React.Dispatch<
    React.SetStateAction<IMachineStatusTableRow[]>
  >;
  isIgnoredMachineTable: boolean;
  isLoading: boolean;
}

export function MachineTablePresentation({
  machines,
  isLoading,
  sortedMachines,
  onEditMachine,
  onViewMachine,
  onIgnoreMachineAction,
  isMachineLocked,
  setSortedMachines,
  isIgnoredMachineTable,
}: Props) {
  return isIgnoredMachineTable ? (
    <MachineIgnoredTablePresentation
      machines={machines}
      isLoading={isLoading}
      isMachineLocked={isMachineLocked}
      onEditMachine={onEditMachine}
      onViewMachine={onViewMachine}
      onIgnoreMachine={onIgnoreMachineAction}
      setSortedMachines={setSortedMachines}
      sortedMachines={sortedMachines}
    />
  ) : (
    <MachineStatusTablePresentation
      machines={machines}
      isLoading={isLoading}
      isMachineLocked={isMachineLocked}
      onEditMachine={onEditMachine}
      onViewMachine={onViewMachine}
      onIgnoreMachine={onIgnoreMachineAction}
      setSortedMachines={setSortedMachines}
      sortedMachines={sortedMachines}
    />
  );
}
