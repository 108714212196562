import { Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import BasePage from "../../components/ui/BasePage";
import PagePaper from "../../components/ui/PagePaper";
import bugReportProcessImage from "../../resources/process_bugs_or_incidents.jpg";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  explanationText: {
    marginBottom: theme.spacing(1),
  },
  logo: {
    maxWidth: "100%",
    height: "auto",
  },
}));

export default function BugReportProcessPage() {
  const classes = useStyles();
  return (
    <>
      <BasePage>
        <PagePaper title="Bug report process" isLoading={false}>
          <Divider className={classes.divider} />
          <Typography variant="body1" className={classes.explanationText}>
            This image below describes iMoba's bug or incident reporting
            process.
          </Typography>

          <img
            src={bugReportProcessImage}
            alt="bug report process image"
            className={classes.logo}
          />
        </PagePaper>
      </BasePage>
    </>
  );
}
