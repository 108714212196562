import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
  },
  subscriptionTimes: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
export default function SubscriptionCardSkeleton() {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Skeleton variant="text" width={300} height={60} />
        <div className={classes.subscriptionTimes}>
          <Skeleton
            variant="rectangular"
            width={300}
            height={30}
            className={classes.margin}
          />
          <Skeleton variant="rectangular" width={300} height={30} />
        </div>
        <Divider className={classes.divider} />
        <Skeleton variant="text" width={300} height={30} />
        <div>
          <Skeleton variant="rectangular" width={300} height={50} />
        </div>
      </CardContent>
      <CardActions>
        <Skeleton
          variant="rectangular"
          width={120}
          height={30}
          className={classes.button}
        />
      </CardActions>
    </Card>
  );
}
