import { Comment, MachineConnectionStatus } from "../utility/MachineUtility";
import { MachineDiagnostic } from "./MachineDiagnostics/MachineDiagnosticEnum";

export interface Machine {
  machineId: MachineId;
  machineConnectionStatus: MachineConnectionStatus;
  comments: Array<Comment>;
  approvalDate: Date | null;
  createdAtUTC: Date;
  machineType: string | null;
  company: string | null;
  country: string | null;
  fullAddress: string | null;
  place: string | null;
  comment: string | null;
  timezone: string | null;
  asmEmail: string | null;
  ownerEmail: string | null;
  latitude: number | null;
  longitude: number | null;
  deleteMachineInProgress: boolean;
  hasRecentlyExpiredSubscriptions: boolean;
  debtorNumber: string | null;
  machineNotificationIgnores: MachineDiagnostic[];
}

/** Parse machineId as int to prevent errors in backend */
export const PrepareForJsonBody = (machine: Machine): Machine => {
  let copy: Machine = { ...machine, comment: null };
  copy.machineId = parseInt(copy.machineId.toString());
  return copy;
};

export const GetEmptyMachine = (): Machine => {
  return {
    machineId: 0,
    machineConnectionStatus: MachineConnectionStatus.Unknown,
    comments: [],
    approvalDate: new Date(),
    createdAtUTC: new Date(),
    machineType: null,
    company: null,
    country: null,
    fullAddress: null,
    place: null,
    comment: null,
    timezone: null,
    asmEmail: null,
    ownerEmail: null,
    latitude: null,
    longitude: null,
    deleteMachineInProgress: false,
    hasRecentlyExpiredSubscriptions: false,
    debtorNumber: null,
    machineNotificationIgnores: [],
  };
};
