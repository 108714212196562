import { withStyles } from "@mui/styles";
import { TableRow, TableCell, TableSortLabel } from "@mui/material";
import { colors } from '../../colors';

export const StyledTableRow = withStyles({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: colors.rowBackgroundColor,
    },
  }
})(TableRow)

export const StyledTableCell = withStyles({
  head: {
    backgroundColor: colors.mobaBlue,
    color: colors.white,
    border: "0px",
    fontWeight: "bold"
  },
  body: {
    fontSize: 14,
  },
})(TableCell)

export const StyledTableSortLabel = withStyles({
  root: {
    color: "white !important",
    "&:hover": {
      color: "white !important",
    },
    "&$active": {
      color: "white !important",
    },
  },
  icon: {
    color: "white !important",
  }
})(TableSortLabel)
