import { useAuth0, User } from "@auth0/auth0-react";
import { useEffect } from "react";
import { AuthTokenGetter } from "../api/AxiosHttpClient";
import config from "../config";
import { axiosHttpClient } from "../store/GlobalContext";

export function useLogout() {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: config.REACT_APP_AUTH0_CALLBACK_URL });
  }, [logout]);
}

export function useCurrentUser(): User {
  const { user } = useAuth0();

  return user ?? ({} as User);
}

export function useLoginWithRedirectEffect() {
  const { loginWithRedirect, isLoading, user } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user) {
        await loginWithRedirect();
      }
    })();
  }, [isLoading, loginWithRedirect, user]);
}

export function useAccessTokenGetter() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  return async () => {
    try {
      return await getAccessTokenSilently({
        audience: config.REACT_APP_AUTH0_AUDIENCE,
      });
    } catch (e: any) {
      if (e.error === "login_required" || e.error === "consent_required") {
        await loginWithRedirect();
      } 
      throw e;
    }
  };
}

export function useHttpClientPipelineInjectorEffect() {
  const getAccessToken: AuthTokenGetter = useAccessTokenGetter();

  useEffect(() => {
    axiosHttpClient.setTokenGetter(getAccessToken);
  }, []);
}
