export enum MachineConnectionStatus {
  Unknown = 0,
  Installed = 1,
  Configured = 2,
  Approved = 3,
  Connected = 4,
}

export interface Commenter {
  name: string;
}

export interface Comment {
  id: string;
  machineId: string;
  commenter: Commenter;
  createdAtUtc: Date;
  content: string;
}

export interface CreateCommentCommand {
  id: string;
  machineId: string;
  commenterEmail: string;
  createdAtUtc: Date;
  content: string;
}

export function IsMachineInstalled(
  machineConnectionStatus: MachineConnectionStatus
) {
  return machineConnectionStatus < 2;
}
