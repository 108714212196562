import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { colors } from "../../colors";
import iMobaLogoImage from "../../resources/logo.png";

export interface CustomerCareToolbarProps {
  onMenuClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: colors.mobaBlue,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 2000, // The z-index should be higher than the drawer
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: colors.white,
  },
  logo: {
    height: "40px",
  },
  logoText: {
    marginLeft: theme.spacing(1),
  },
}));

function CustomerCareToolbar({ onMenuClick }: CustomerCareToolbarProps) {
  const classes = useStyles();

  const isMobileSize = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  return (
    <AppBar elevation={0} position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          onClick={onMenuClick}
          size="large"
        >
          <MenuIcon />
        </IconButton>

        {!isMobileSize && (
          <>
            <img
              src={iMobaLogoImage}
              alt={"imoba-logo"}
              className={classes.logo}
            />
            <Typography variant="h6" noWrap className={classes.logoText}>
              Customer Care
            </Typography>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default CustomerCareToolbar;
