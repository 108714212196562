import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { colors } from "../../colors";
import { MachineWithOwner } from "./ViewMachineListContainer";
import { CreateColumns } from "./ViewMachineListUtil";

export interface ViewMachineListPresentationProps {
  machines: MachineWithOwner[];
  isPageLoading: boolean;
  onViewMachine: (machine: MachineId) => void;
  onEditMachine: (machine: MachineId) => void;
  onDeleteMachine: (machine: MachineId) => void;
  onSubscriptionIconClick: (machine: MachineId) => void;
}

const columnUseStyles = makeStyles((theme) => ({
  tooltip: {
    marginRight: theme.spacing(2),
    height: 20,
  },
  machineIdContainer: {
    display: "flex",
    flexDirection: "row",
  },
  warningIcon: {
    color: "#ffcc00",
  },
}));

export default function ViewMachineListPresentation({
  machines,
  isPageLoading,
  onViewMachine,
  onEditMachine,
  onDeleteMachine,
  onSubscriptionIconClick,
}: ViewMachineListPresentationProps) {
  const classes = columnUseStyles();

  const columns = useMemo(() => {
    return CreateColumns(
      onSubscriptionIconClick,
      onViewMachine,
      onEditMachine,
      onDeleteMachine,
      classes
    );
  }, [
    onDeleteMachine,
    onEditMachine,
    onSubscriptionIconClick,
    onViewMachine,
    classes,
  ]);

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={machines.map((x) => ({
          ...x.machineView,
          ownerFullName: x.ownerFullName,
          ownerPhone: x.ownerPhone,
        }))}
        columns={columns}
        loading={isPageLoading}
        pageSize={20}
        rowsPerPageOptions={[20]}
        getRowId={(row) => row.machineId}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.mobaBlue,
            color: "white",
          },
          "& .MuiDataGrid-sortIcon, & .MuiDataGrid-filterIcon, & .MuiDataGrid-menuIconButton":
            {
              opacity: 1,
              color: "white",
            },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(odd)": {
              backgroundColor: colors.rowBackgroundColor,
            },
          },
        }}
        isRowSelectable={() => false}
      />
    </div>
  );
}
