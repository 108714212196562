import { IconButton, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import {
  Feature,
  useAuthorization,
} from "../../globalHooks/authorizationHooks";

interface Props {
  children: any;
  color?:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  requiredFeature: Feature;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  arialabel?: string;
  size?: "small" | "medium";
  tooltip: string;
}

export default function ProtectedIconButton({
  children,
  color,
  requiredFeature,
  onClick,
  disabled,
  arialabel,
  size,
  tooltip,
}: Props) {
  const authorization = useAuthorization();

  const isAuthorized = useMemo(() => {
    if (authorization.features.includes(requiredFeature)) {
      return true;
    } else {
      return false;
    }
  }, [authorization]);

  return isAuthorized ? (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={onClick}
        color={color}
        aria-label={arialabel}
        size={size}
        disabled={disabled}
      >
        {children}
      </IconButton>
    </Tooltip>
  ) : (
    <></>
  );
}
