import AnnouncementIcon from "@mui/icons-material/Announcement";
import BugReportIcon from "@mui/icons-material/BugReport";
import EuroIcon from "@mui/icons-material/Euro";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GroupIcon from "@mui/icons-material/Group";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SignalCellularConnectedNoInternet1BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet1Bar";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import React from "react";

export const managementNav = [
  {
    icon: <GroupIcon />,
    name: "iMoba User List",
    link: "/users",
  },
  {
    icon: <SettingsApplicationsIcon />,
    name: "Machine List",
    link: "/machines",
  },
  {
    icon: <ListAltIcon />,
    name: "Logs",
    link: "/logs",
  },
  {
    icon: <SupervisorAccountIcon />,
    name: "Customer care team",
    link: "/customer-care-team",
  },
  {
    icon: <AnnouncementIcon />,
    name: "Announcement",
    link: "/global-announcement",
  },
  {
    icon: <EuroIcon />,
    name: "Invoices",
    link: "/invoices",
  },
  {
    icon: <SignalCellularConnectedNoInternet1BarIcon />,
    name: "Machine Diagnostics",
    link: "/machine-diagnostics",
  },
  {
    icon: <ListAltIcon />,
    name: "USDA Egg Information History",
    link: "/usda-egg-information-history",
  }
];

export const generalNav = [
  {
    icon: <BugReportIcon />,
    name: "Bug report process",
    link: "/bug-report-process",
  },
  {
    icon: <ExitToAppIcon />,
    name: "Logout",
    link: "/logout",
  },
];
