import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";
import React, { useCallback } from "react";

export default function AutoCompleteTextField({
  options,
  selectedOption,
  disabled,
  error,
  helperText,
  onChange,
}: {
  options: Array<string>;
  selectedOption: string | null;
  disabled: boolean;
  error: boolean;
  helperText: string;
  onChange: (value: string) => void;
}) {
  const handleChange = useCallback(
    (_: any, value: any) => {
      onChange(value);
    },
    [onChange]
  );

  const getOptionLabel = useCallback((option: any) => option ?? "", []);

  const renderInput = useCallback(
    (params: any) => (
      <TextField
        {...params}
        error={error}
        helperText={helperText}
        variant="outlined"
      />
    ),
    [error, helperText]
  );

  return (
    <>
      <div>
        <Autocomplete
          disabled={disabled}
          value={selectedOption === "" ? null : selectedOption}
          disableCloseOnSelect={false}
          onChange={handleChange}
          options={options}
          getOptionLabel={getOptionLabel}
          renderInput={renderInput}
        />
      </div>
    </>
  );
}
