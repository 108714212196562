import React from "react";
import BasePage from "../../components/ui/BasePage";
import ViewUserListContainer from "../../components/viewUserList/ViewUserListContainer";

export default function UserListPage() {
  return (
    <BasePage>
      <ViewUserListContainer />
    </BasePage>
  );
}
