import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import DraggablePaperComponent from "./DraggablePaperComponent";

export interface ConfirmActionDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
  message: string;
}

export function ConfirmActionDialog({
  isOpen,
  onConfirm,
  onReject,
  message,
}: ConfirmActionDialogProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={onReject}
      PaperComponent={DraggablePaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Warning
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onReject} color="primary">
          No
        </Button>
        <Button autoFocus onClick={onConfirm} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
