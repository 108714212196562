import { Skeleton, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export default function SkeletonPage() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Skeleton variant="rectangular" className={classes.skeletonHeader} />
      <Skeleton variant="rectangular" className={classes.skeletonTable} />
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  skeletonTable: {
    margin: theme.spacing(3),
    height: "500px",
  },
  skeletonHeader: {
    margin: theme.spacing(3),
    marginBottom: 0,
    height: "100px",
  },
}));
