import React from "react";
import ManageSubscriptionsContainer from "../../components/Subscriptions/ManageSubscriptionsContainer";
import BasePage from "../../components/ui/BasePage";

export default function ManageSubscriptionsPage() {
  return (
    <BasePage>
      <ManageSubscriptionsContainer />
    </BasePage>
  );
}
