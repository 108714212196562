import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getMachineDiagnosticsSummary } from "../../api/MachineDiagnostics/MachineDiagnostics";
import { MachineDiagnosticsQueryNames } from "../../api/Queries";
import { MachineStatusCard } from "../../components/MachineDiagnostics/MachineStatusCard/MachineStatusCard";
import BasePage from "../../components/ui/BasePage";
import PagePaper from "../../components/ui/PagePaper";
import { MachineDiagnostic } from "../../types/MachineDiagnostics/MachineDiagnosticEnum";
import { numberOfQuestsTreshold } from "./machine-diagnostics-quests/MachineDiagnosticsQuestsPage";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  explanationText: {
    marginBottom: theme.spacing(1),
  },
}));

export type MachineDiagnosticsSummary = {
  machineCount: number;
  machineDiagnostic: MachineDiagnostic;
  ignoredMachineCount: number;
  ignoredMachineDiagnostic: MachineDiagnostic;
};

export default function MachineDiagnosticsPage() {
  const classes = useStyles();
  const browserHistory = useHistory();

  const handleLifeSignCardClick = () => {
    browserHistory.push("/machine-diagnostics/lifesign");
  };

  const handleQuestCardClick = () => {
    browserHistory.push("/machine-diagnostics/quests");
  };

  const { data, isLoading } = useQuery(
    MachineDiagnosticsQueryNames.Summary,
    () => getMachineDiagnosticsSummary()
  );
  const foundNoDataDiagnostic = data?.data.diagnostics.find(
    (d) => d.machineDiagnostic === MachineDiagnostic.NoDataUploaded
  );
  const foundIgnoredNoDataDiagnostic = data?.data.ignoredDiagnostics.find(
    (d) => d.machineDiagnostic === MachineDiagnostic.NoDataUploaded
  );
  const foundQuestDiagnostic = data?.data.diagnostics.find(
    (d) => d.machineDiagnostic === MachineDiagnostic.NoQuestResponsesReceived
  );
  const foundIgnoredQuestDiagnostic = data?.data.ignoredDiagnostics.find(
    (d) => d.machineDiagnostic === MachineDiagnostic.NoQuestResponsesReceived
  );
  const noDataUploadedDiagnostic: MachineDiagnosticsSummary = {
    machineCount: foundNoDataDiagnostic?.machineCount ?? 0,
    machineDiagnostic: MachineDiagnostic.NoDataUploaded,
    ignoredMachineCount: foundIgnoredNoDataDiagnostic?.ignoredMachineCount ?? 0,
    ignoredMachineDiagnostic: MachineDiagnostic.NoDataUploaded,
  };
  const noQuestsReceivedDiagnostic: MachineDiagnosticsSummary = {
    machineCount: foundQuestDiagnostic?.machineCount ?? 0,
    machineDiagnostic: MachineDiagnostic.NoQuestResponsesReceived,
    ignoredMachineCount: foundIgnoredQuestDiagnostic?.ignoredMachineCount ?? 0,
    ignoredMachineDiagnostic: MachineDiagnostic.NoQuestResponsesReceived,
  };

  return (
    <BasePage>
      <PagePaper isLoading={isLoading} title="Machine Diagnostics">
        <Divider className={classes.divider} />
        <Typography variant="body1" className={classes.explanationText}>
          This page contains various metrics related to the status of Egg
          Grading Machines.
        </Typography>

        {!isLoading && (
          <Grid container spacing={2}>
            <Grid item sm={12} md={6} lg={3}>
              <MachineStatusCard
                muiSeverity="error"
                numberOfMachines={noDataUploadedDiagnostic?.machineCount ?? 0}
                ignoredMachinesCount={undefined}
                cardDescription="No data uploaded in the 24 hours (UTC)."
                cardButtonlabel="DETAILS"
                onClick={handleLifeSignCardClick}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <MachineStatusCard
                muiSeverity="warning"
                numberOfMachines={noQuestsReceivedDiagnostic?.machineCount ?? 0}
                ignoredMachinesCount={undefined}
                cardDescription={`Not responding to at least ${numberOfQuestsTreshold} outstanding quests.`}
                cardButtonlabel="DETAILS"
                onClick={handleQuestCardClick}
              />
            </Grid>
          </Grid>
        )}
      </PagePaper>
    </BasePage>
  );
}
