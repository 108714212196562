import { InputAdornment, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

export interface TableSearchProps {
  searchTerm: string;
  captionText: string;
  onChange: (searchTerm: string) => void;
}

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "5px",
    marginBottom: theme.spacing(2),
    width: theme.spacing(30),
    flexDirection: "column",
  },
}));

function TableSearch({ searchTerm, captionText, onChange }: TableSearchProps) {
  const classes = useStyles();

  return (
    <div className={classes.searchContainer}>
      <TextField
        value={searchTerm}
        onChange={(ev) => onChange(ev.target.value.toLowerCase())}
        variant="outlined"
        placeholder="Search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="caption">{captionText}</Typography>
    </div>
  );
}

export default TableSearch;
