import { Button, Card, CardActions, CardContent, Divider, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { colors } from "../../../colors";
import {
  GetSubscriptionName,
  IsExpiringSoon,
  MachineSubscription,
} from "../../../utility/SubscriptionsUtility";
import SubscriptionCardSkeleton from "./SubscriptionCardSkeleton";
import SubscriptionChips from "./SubscriptionChips";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
  },
  inActiveRoot: {
    maxWidth: 350,
    backgroundColor: colors.grey93,
    color: colors.grey40,
  },
  timeDisplayWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  timeInfoWrapper: {
    marginLeft: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subscriptionTimes: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  chips: {
    maxWidth: 300,
    overflowX: "scroll",
    overflowY: "hidden",
    scrollbarWidth: "none",
    whiteSpace: "nowrap",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    gap: theme.spacing(1),
  },
  chipsSection: {
    display: "flex",
  },
  timeIcon: {
    width: 30,
    height: 30,
    color: colors.grey40,
  },
}));

export interface SubscriptionCardProps {
  subscription: MachineSubscription;
  isLoading: boolean;
  isMachineLocked: boolean;
  onChangeEndDateClick: (subscription: MachineSubscription) => void;
}

export default function SubscriptionCard({
  subscription,
  isLoading,
  isMachineLocked,
  onChangeEndDateClick,
}: SubscriptionCardProps) {
  const classes = useStyles();

  const handleOnClick = () => {
    onChangeEndDateClick(subscription);
  };

  const {
    title,
    startDateText,
    endDateText,
    isAutoRenewal,
    isActive,
    isExpiringSoon,
  } = useMemo(() => {
    return {
      title: GetSubscriptionName(subscription.subscriptionType),
      startDateText: !subscription.fromUtc
        ? "-"
        : format(subscription.fromUtc, "dd-MM-yyyy"),
      endDateText: !subscription.untilUtc
        ? "-"
        : format(subscription.untilUtc, "dd-MM-yyyy"),
      isAutoRenewal: subscription.autoRenew,
      isActive: subscription.isActive,
      isExpiringSoon: IsExpiringSoon(subscription),
    };
  }, [subscription]);

  return isLoading ? (
    <SubscriptionCardSkeleton />
  ) : (
    <Card
      className={isActive ? classes.root : classes.inActiveRoot}
      variant="outlined"
    >
      <CardContent>
        <Typography variant="h5" component="h2" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.subscriptionTimes}>
          <TimeCard date={startDateText} type="startDate" />
          <TimeCard date={endDateText} type="endDate" />
        </div>
        <Divider className={classes.divider} />
        <Typography variant="subtitle1" className={classes.subtitle}>
          Subscription information
        </Typography>
        <SubscriptionChips
          isActive={isActive}
          isAutoRenewal={isAutoRenewal}
          isExpiringSoon={isExpiringSoon}
        />
      </CardContent>
      <CardActions>
        <div>
          {!isMachineLocked && (
            <Button color="primary" onClick={handleOnClick}>
              {isActive ? "Change end date" : "Activate"}
            </Button>
          )}
        </div>
      </CardActions>
    </Card>
  );
}

function TimeCard({
  date,
  type,
}: {
  date: string;
  type: "startDate" | "endDate";
}) {
  const classes = useStyles();

  return (
    <div className={classes.timeDisplayWrapper}>
      {type === "startDate" ? (
        <AlarmOnIcon className={classes.timeIcon} />
      ) : (
        <AlarmOffIcon className={classes.timeIcon} />
      )}
      <div className={classes.timeInfoWrapper}>
        <Typography variant="body1">{date}</Typography>
        <Typography variant="body2" color="textSecondary">
          {type === "startDate" ? "Start date" : "End date"}
        </Typography>
      </div>
    </div>
  );
}
