import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { colors } from "../../colors";
import { SubscriptionType } from "../../utility/SubscriptionsUtility";
import { CreateColumns } from "./InvoicesListUtil";

export interface InvoicesPresentationProps {
  invoices: InvoicePresentation[];
  isPageLoading: boolean;
}

export interface InvoicePresentation {
  machineNumber: number;
  companyName: string;
  debtorNumber: string;
  amountDue: number;
  subscription: SubscriptionType;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
  invoiceStatus: string;
  invoiceCreatedDate: string;
}

export default function InvoicesPresentation({
  invoices,
  isPageLoading,
}: InvoicesPresentationProps) {
  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={invoices.map((x, i) => ({
          ...x,
          index: i,
        }))}
        columns={CreateColumns()}
        loading={isPageLoading}
        pageSize={20}
        rowsPerPageOptions={[20]}
        getRowId={(row) => row.index}
        initialState={{
          sorting: {
            sortModel: [{ field: "invoiceCreatedDate", sort: "desc" }],
          },
        }}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.mobaBlue,
            color: "white",
          },
          "& .MuiDataGrid-sortIcon, & .MuiDataGrid-filterIcon, & .MuiDataGrid-menuIconButton":
            {
              opacity: 1,
              color: "white",
            },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(odd)": {
              backgroundColor: colors.rowBackgroundColor,
            },
          },
        }}
        isRowSelectable={() => false}
      />
    </div>
  );
}
