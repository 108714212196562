import { Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getGlobalAnnouncement,
  setGlobalAnnouncement,
} from "../../api/GlobalAnnouncement/GlobalAnnouncementService";
import { GlobalAnnouncementQueryNames } from "../../api/Queries";
import { GlobalAnnouncementModel } from "../../types/GlobalAnnouncementModel";
import { useDispatchAlert } from "../ui/Alert";
import PagePaper from "../ui/PagePaper";
import SetGlobalAnnouncementPresentation from "./SetGlobalAnnouncementPresentation";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

export default function SetGlobalAnnouncementContainer() {
  const classes = useStyles();
  const dispatchAlert = useDispatchAlert();
  const queryClient = useQueryClient();

  const globalAnnouncementQuery = useQuery(
    GlobalAnnouncementQueryNames.GetGlobalAnnouncement,
    () => getGlobalAnnouncement(),
    {
      onError: () => {
        dispatchAlert({
          message:
            "Something went wrong while retrieving the existing announcement",
          messageType: "error",
        });
      },
    }
  );

  const updateGlobalAnnouncement = useMutation(
    (updatedAnnouncement: GlobalAnnouncementModel) => {
      return setGlobalAnnouncement(updatedAnnouncement);
    },
    {
      onError: () => {
        dispatchAlert({
          message: "Something went wrong while saving the announcement",
          messageType: "error",
        });
      },
      onSuccess: async () => {
        dispatchAlert({
          message: "Successfully set global announcement in iMoba",
          messageType: "success",
        });
        await queryClient.invalidateQueries({
          queryKey: [GlobalAnnouncementQueryNames.GetGlobalAnnouncement],
        });
      },
    }
  );

  const handleOnSubmit = useCallback((updatedAnnouncement) => {
    updateGlobalAnnouncement.mutate(updatedAnnouncement);
  }, []);

  return (
    <div className={classes.margin}>
      <PagePaper
        isLoading={
          globalAnnouncementQuery.isLoading ||
          updateGlobalAnnouncement.isLoading
        }
        title={"Global Announcement"}
      >
        <Divider className={classes.divider} />
        <SetGlobalAnnouncementPresentation
          globalAnnouncement={globalAnnouncementQuery?.data?.data}
          isPageLoading={false}
          onSubmit={handleOnSubmit}
        />
      </PagePaper>
    </div>
  );
}
