import { useQuery } from "react-query";
import { UserQueryNames } from "../api/Queries";
import { getMyUser } from "../api/User/UserService";
import { useMyUserState, useUpdateMyUser } from "../store/GlobalContext";
import { SubscriptionType } from "../utility/SubscriptionsUtility";

export interface UserResult {
  machines: MachineDictionary;
  userSystemWideAuthorization: UserSystemWideAuthorization;
}

export interface MachineDictionary {
  [machineId: number]: UserMachineAuthorizations;
}

export interface UserSystemWideAuthorization {
  roles: string[];
  features: string[];
  lockedMachines: number[];
}

export interface InvoiceResult {
  machineNumber: number;
  companyName: string;
  debtorNumber: string;
  amountDue: number;
  subscription: SubscriptionType;
  subscriptionStartDate: Date;
  subscriptionEndDate: Date;
  invoiceStatus: string;
  invoiceCreatedDate: Date;
}

export enum Feature {
  Undefined = "Undefined",
  PerformancePro = "PerformancePro",
  Benchmark = "Benchmark",
  RealtimeDashboard = "RealtimeDashboard",
  ManageSettings = "ManageSettings",
  ManageUsers = "ManageUsers",
  ManageAlarms = "ManageAlarms",
  ManageMachine = "ManageMachine",
  Owner = "Owner",
  CustomerCareManageUsers = "CustomerCareManageUsers",
  CustomerCareManageCustomerCareUser = "CustomerCareManageCustomerCareUser",
  CustomerCareManageMachines = "CustomerCareManageMachines",
  CustomerCareReadUsers = "CustomerCareReadUsers",
  CustomerCareReadMachines = "CustomerCareReadMachines",
  CustomerCareManageAnnouncement = "CustomerCareManageAnnouncement",
  ReadInvoices = "ReadInvoices",
}

export type SystemRole =
  | "MemberCustomerCareApp"
  | "AdminCustomerCareApp"
  | "AsmCustomerCareApp";
export const allSystemRoles: SystemRole[] = [
  "MemberCustomerCareApp",
  "AdminCustomerCareApp",
  "AsmCustomerCareApp",
];

export enum UserRole {
  UserCustomerApp = "UserCustomerApp",
  AdminCustomerApp = "AdminCustomerApp",
  OwnerCustomerApp = "OwnerCustomerApp",
}
export interface UserMachineAuthorizations {
  machineName: string;
  roles: string[];
  modules: string[];
  features: string[];
}

export function useLoadMyUserIntoGlobalState() {
  const { updateMyUser, updateMyUserIsLoading } = useUpdateMyUser();
  useQuery(UserQueryNames.GetMyUser, () => getMyUser(), {
    onSuccess: (response) => {
      updateMyUser(response);
      updateMyUserIsLoading(false);
    },
  });
}

export function useAuthorization(): UserSystemWideAuthorization {
  const state = useMyUserState();
  return state.userSystemWideAuthorization;
}
