import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCustomerCareUserService } from "../../api/ServiceContext";
import { Feature } from "../../globalHooks/authorizationHooks";
import { GetEmptyCustomerCareUser, ICustomerCareUser } from "../../types/User";
import { useDispatchAlert } from "../ui/Alert";
import { ConfirmActionDialog } from "../ui/ConfirmActionDialog";
import PagePaper from "../ui/PagePaper";
import ProtectedButton from "../ui/ProtectedButton";
import TableSearch from "../ui/TableSearch";
import { ViewCustomerCareUserListPresentation } from "./ViewCustomerCareUserListPresentation";
import { GetSystemRoleDisplayName } from "./ViewCustomerCareUserListUtil";

const useStyles = makeStyles((theme) => ({
  button: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
    },
  },
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export function CustomerCareUserListContainer() {
  const [customerCareUsers, setCustomerCareUsers] = useState<
    ICustomerCareUser[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [customerCareUserToDelete, setCustomerCareUserToDelete] =
    useState<ICustomerCareUser>(GetEmptyCustomerCareUser());
  const browserHistory = useHistory();
  const dispatchAlert = useDispatchAlert();
  const customerCareUserService = useCustomerCareUserService();
  const classes = useStyles();

  const minimumNumberOfAdmins = 1;
  const customerCareAdmins = customerCareUsers.filter(
    (x) => x.systemRoles[0] === "AdminCustomerCareApp"
  );

  const searchedCustomerCareUsers = useMemo(() => {
    if (searchTerm.length === 0) {
      return customerCareUsers;
    }
    return customerCareUsers.filter((u) => {
      return (
        `${u.firstName} ${u.lastName}`.toLowerCase().includes(searchTerm) ||
        u.email.toLowerCase().includes(searchTerm) ||
        u.systemRoles.find((systemRole) =>
          GetSystemRoleDisplayName(systemRole)
            .toLowerCase()
            .includes(searchTerm)
        )
      );
    });
  }, [customerCareUsers, searchTerm]);

  const sortedCustomerCareUsers = useMemo(() => {
    return [...searchedCustomerCareUsers].sort((u1, u2) => {
      return `${u1.firstName} ${u1.lastName}`.localeCompare(
        `${u2.firstName} ${u2.lastName}`
      );
    });
  }, [searchedCustomerCareUsers]);

  useEffect(() => {
    setIsPageLoading(true);

    customerCareUserService
      .getCustomerCareUsers()
      .then((res: any) => {
        setCustomerCareUsers(res.data.customerCareUsers);
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  }, [customerCareUserService]);

  const openAddCustomerCareUserPage = () => {
    browserHistory.push("/add-customer-care-team-member");
  };

  const handleEditCustomerCareUser = (customerCareUser: ICustomerCareUser) => {
    browserHistory.push(
      `/edit-customer-care-team-member/${customerCareUser.email}`
    );
  };

  const handleDeleteCustomerCareUser = (
    customerCareUser: ICustomerCareUser
  ) => {
    if (customerCareUser.isAssignedAsm) {
      dispatchAlert({
        message:
          "The selected customer care team member cannot be deleted since he is assigned as ASM to a machine.",
        messageType: "error",
      });
      return;
    }

    if (
      customerCareAdmins.length === minimumNumberOfAdmins &&
      customerCareUser.systemRoles[0] === "AdminCustomerCareApp"
    ) {
      dispatchAlert({
        message:
          "The selected customer care team member is the last admin and cannot be deleted.",
        messageType: "error",
      });
      return;
    }

    setDialogMessage(
      `Are you sure you want to delete customer care team member ${customerCareUser.firstName} ${customerCareUser.lastName} with e-mail address ${customerCareUser.email}? This action will permanently delete this user.`
    );
    setCustomerCareUserToDelete(customerCareUser);
    setDialogOpen(true);
  };

  const resetAndCloseDialog = () => {
    setDialogMessage("");
    setDialogOpen(false);
  };

  const handleDeleteCustomerCareUserConfirm = () => {
    deleteCustomerCareUser(customerCareUserToDelete);
    resetAndCloseDialog();
  };

  const deleteCustomerCareUser = (customerCareUser: ICustomerCareUser) => {
    if (!customerCareUser) return;

    setIsPageLoading(true);
    customerCareUserService
      .deleteCustomerCareUser(customerCareUser.email)
      .then(() => {
        dispatchAlert({
          message: "Customer care team member deleted succesfully.",
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: "Error deleting customer care team member.",
          messageType: "error",
        });
      })
      .finally(() => {
        setIsPageLoading(false);
        customerCareUserService
          .getCustomerCareUsers()
          .then((res) => {
            setCustomerCareUsers(res.data.customerCareUsers);
          })
          .catch(() => {
            dispatchAlert({
              message: "Error retrieving customer care team members.",
              messageType: "error",
            });
          });
      });
  };

  return (
    <>
      <PagePaper isLoading={isPageLoading} title="Customer care team">
        <div className={classes.wrapper}>
          <ProtectedButton
            variant="outlined"
            color="primary"
            disabled={isPageLoading}
            className={classes.button}
            onClick={() => openAddCustomerCareUserPage()}
            startIcon={<ArrowForwardIcon />}
            requiredFeature={Feature.CustomerCareManageMachines}
          >
            Add a customer care team member
          </ProtectedButton>
          <TableSearch
            searchTerm={searchTerm}
            onChange={setSearchTerm}
            captionText={"Search on every field in this table"}
          />
        </div>
        <ViewCustomerCareUserListPresentation
          customerCareUsers={sortedCustomerCareUsers}
          onEditCustomerCareUser={handleEditCustomerCareUser}
          onDeleteCustomerCareUser={handleDeleteCustomerCareUser}
        />
      </PagePaper>
      <ConfirmActionDialog
        isOpen={dialogOpen}
        message={dialogMessage}
        onConfirm={() => handleDeleteCustomerCareUserConfirm()}
        onReject={() => resetAndCloseDialog()}
      />
    </>
  );
}
