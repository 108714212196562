import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Feature } from "../globalHooks/authorizationHooks";
import { ProtectedRoute } from "../utility/ProtectedRoute";
import BugReportProcessPage from "./bug-report-process/BugReportProcessPage";
import InvoicesPage from "./Invoices/InvoicesPage";
import LogoutPage from "./logout/logoutPage";
import LogsPage from "./Logs/LogsPage";
import MachineDiagnosticsLifesignPage from "./machine-diagnostics/machine-diagnostics-lifesign/MachineDiagnosticsLifesignPage";
import MachineDiagnosticsQuestsPage from "./machine-diagnostics/machine-diagnostics-quests/MachineDiagnosticsQuestsPage";
import MachineDiagnosticsPage from "./machine-diagnostics/MachineDiagnosticsPage";
import AddCustomerCareUserPage from "./management/AddCustomerCareUserPage";
import CustomerCareUserListPage from "./management/CustomerCareUserListPage";
import EditCustomerCareUserPage from "./management/EditCustomerCareUserPage";
import EditMachinePage from "./management/EditMachinePage";
import MachineListPage from "./management/MachineListPage";
import ManageSubscriptionsPage from "./management/ManageSubscriptionsPage";
import SetGlobalAnnouncementPage from "./management/SetGlobalAnnouncementPage";
import UserListPage from "./management/UserListPage";
import UnauthorizedPage from "./unauthorized/unauthorizedPage";
import UsdaEggInformationHistory from "./usda-egg-information-history/UsdaEggInformationHistoryPage";

const RootPage = () => (
  <Switch>
    <ProtectedRoute
      path="/users"
      component={UserListPage}
      feature={Feature.CustomerCareReadUsers}
    />
    <ProtectedRoute
      path="/invoices"
      component={InvoicesPage}
      feature={Feature.ReadInvoices}
    />
    <ProtectedRoute
      path="/logs"
      component={LogsPage}
      feature={Feature.CustomerCareReadUsers}
    />
    <ProtectedRoute
      path="/machines/:machineId/subscriptions"
      component={ManageSubscriptionsPage}
      feature={Feature.CustomerCareReadMachines}
    />
    <ProtectedRoute
      path="/machines/:machineId"
      component={EditMachinePage}
      feature={Feature.CustomerCareManageMachines}
    />
    <ProtectedRoute
      path="/machines"
      component={MachineListPage}
      feature={Feature.CustomerCareReadMachines}
    />
    <ProtectedRoute
      path="/customer-care-team"
      component={CustomerCareUserListPage}
      feature={Feature.CustomerCareManageCustomerCareUser}
    />
    <ProtectedRoute
      path="/add-customer-care-team-member"
      component={AddCustomerCareUserPage}
      feature={Feature.CustomerCareManageCustomerCareUser}
    />

    <ProtectedRoute
      path="/edit-customer-care-team-member/:email"
      component={EditCustomerCareUserPage}
      feature={Feature.CustomerCareManageCustomerCareUser}
    />

    <ProtectedRoute
      path="/global-announcement"
      component={SetGlobalAnnouncementPage}
      feature={Feature.CustomerCareManageAnnouncement}
    />

    <ProtectedRoute
      path="/machine-diagnostics/lifesign"
      component={MachineDiagnosticsLifesignPage}
      feature={Feature.CustomerCareReadMachines}
    />

    <ProtectedRoute
      path="/machine-diagnostics/quests"
      component={MachineDiagnosticsQuestsPage}
      feature={Feature.CustomerCareReadMachines}
    />

    <ProtectedRoute
      path="/machine-diagnostics"
      component={MachineDiagnosticsPage}
      feature={Feature.CustomerCareReadMachines}
    />

    <ProtectedRoute
      path="/usda-egg-information-history"
      component={UsdaEggInformationHistory}
      feature={Feature.CustomerCareReadMachines}
    />

    <Route path="/bug-report-process" component={BugReportProcessPage} />
    <Route path="/logout" component={LogoutPage} />
    <Route path="/unauthorized" component={UnauthorizedPage} />
    <Redirect from="/" to="/users" />
  </Switch>
);

export default RootPage;
