import { ICustomerCareUser } from "../../types/User";
import { AxiosHttpClient } from "../AxiosHttpClient";
import ICustomerCareUserService from "./ICustomerCareUserService";

class CustomerCareUserService implements ICustomerCareUserService {
  private _httpClient: AxiosHttpClient;

  constructor(httpClient: AxiosHttpClient) {
    this._httpClient = httpClient;
  }

  getCustomerCareUsers() {
    return this._httpClient.get("v1/customer-care-user");
  }

  addCustomerCareUser(customerCareUser: ICustomerCareUser): Promise<any> {
    return this._httpClient.post("v1/customer-care-user", {
      ...customerCareUser,
      systemRole: customerCareUser.systemRoles[0]
    });
  }
  
  updateCustomerCareUser(customerCareUser: ICustomerCareUser): Promise<any> {
    return this._httpClient.put("v1/customer-care-user/by-email", {
      ...customerCareUser,
      systemRole: customerCareUser.systemRoles[0]
    });
  }

  deleteCustomerCareUser(email: string): Promise<any> {
    return this._httpClient.put("v1/customer-care-user/delete-by-email", {
      email,
    });
  }
}

export default CustomerCareUserService;
