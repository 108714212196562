import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { UserQueryNames } from "../../api/Queries";
import { deleteUser, resetUserMfa } from "../../api/User/UserService";
import { useDispatchAlert } from "../ui/Alert";

export function useResetUserMfa() {
  const dispatchAlert = useDispatchAlert();

  const [userMfaToReset, setUserMfaToReset] = useState("");
  const [userResetMfaDialogOpen, setUserResetMfaDialogOpen] = useState(false);
  const [userResetMfaDialogMessage, setUserResetMfaDialogMessage] =
    useState("");

  const handleUserResetMfa = (email: string) => {
    setUserResetMfaDialogMessage(
      `Are you sure you want to reset the MFA for ${email}?`
    );
    setUserMfaToReset(email);
    setUserResetMfaDialogOpen(true);
  };

  const resetAndCloseDialog = () => {
    setUserResetMfaDialogOpen(false);
    setUserResetMfaDialogMessage("");
    setUserMfaToReset("");
  };

  const resetMfaMutation = useMutation((email: string) => resetUserMfa(email), {
    onSuccess: () => {
      dispatchAlert({
        message:
          "MFA is reset successfully and a confirmation e-mail is sent to the user.",
        messageType: "success",
      });
    },
    onError: () => {
      dispatchAlert({
        message: "An error occurred while resetting user MFA",
        messageType: "error",
      });
    },
  });

  const resetMfa = (email: string) => {
    if (email === "") return;

    resetMfaMutation.mutate(email);
    resetAndCloseDialog();
  };

  return {
    resetMfaLoading: resetMfaMutation.isLoading,
    userResetMfaDialogOpen,
    userResetMfaDialogMessage,
    userMfaToReset,
    handleUserResetMfa,
    resetAndCloseDialog,
    resetMfa,
  };
}

export function useDeleteUser() {
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
  const [emailOfUserToDelete, setEmailOfUserToDelete] = useState("");
  const [ownedAndLastAdminOfMachines, setOwnedAndLastAdminOfMachines] =
    useState({ lastAdminOfMachines: [], ownerOfMachines: [] });
  const dispatchAlert = useDispatchAlert();
  const queryClient = useQueryClient();

  const handleOnDeleteUser = async (email: string) => {
    setDeleteUserDialogOpen(true);
    setEmailOfUserToDelete(email);
    await queryClient.invalidateQueries([
      UserQueryNames.GetOwnedAndAdminMachinesForUser,
    ]);
  };

  const resetAndCloseDeleteUserDialog = () => {
    setEmailOfUserToDelete("");
    setDeleteUserDialogOpen(false);
    setOwnedAndLastAdminOfMachines({
      lastAdminOfMachines: [],
      ownerOfMachines: [],
    });
  };

  const deleteUserMutation = useMutation((email: string) => deleteUser(email), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([UserQueryNames.GetAllUsers]);
      dispatchAlert({
        message: "User deleted succesfully.",
        messageType: "success",
      });
    },
    onError: () => {
      dispatchAlert({
        message: "Error deleting user.",
        messageType: "error",
      });
    },
    onSettled: () => {
      resetAndCloseDeleteUserDialog();
    },
  });

  return {
    lastAdminOfMachines: ownedAndLastAdminOfMachines.lastAdminOfMachines,
    ownerOfMachines: ownedAndLastAdminOfMachines.ownerOfMachines,
    deleteUserDialogOpen,
    emailOfUserToDelete,
    deleteUserMutation,
    handleOnDeleteUser,
    resetAndCloseDeleteUserDialog,
  };
}
