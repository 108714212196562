import GetAppIcon from "@mui/icons-material/GetApp";
import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  Box,
  ButtonGroup,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Feature } from "../../globalHooks/authorizationHooks";
import { Machine } from "../../types/Machine";
import ProtectedButton from "../ui/ProtectedButton";

export interface ExportUsdaEggInformationHistoryPresentationProps {
  machines: Machine[];
  selectedMachine: Machine | undefined;
  onMachineUpdated: (machine: Machine) => void;
  onDownloadUsdaEggInformationHistory: (
    machine: Machine | undefined,
    date: Date
  ) => Promise<void>;
  selectedDate: Date;
  onDateUpdated: (date: Date) => void;
  onCrackUpdated: (crack: number) => void;
  onDirtUpdated: (dirt: number) => void;
  errorMessage: string;
  isPageLoading: boolean;
}

const useStyles = makeStyles(() => ({
  datePicker: {
    marginTop: "1rem",
    marginRight: "1rem",
    marginBottom: "1rem",
  },
  exportButton: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}));

function ExportUsdaEggInformationHistoryPresentation({
  machines,
  selectedMachine,
  onMachineUpdated,
  selectedDate,
  onDateUpdated,
  onCrackUpdated,
  onDirtUpdated,
  errorMessage,
  onDownloadUsdaEggInformationHistory,
  isPageLoading,
}: ExportUsdaEggInformationHistoryPresentationProps) {
  const classes = useStyles();

  const min = 1;
  const dirtMax = 7;
  const crackMax = 31;

  return (
    <div>
      <Typography
        variant="subtitle1"
        sx={{
          marginTop: "0.5rem",
        }}
      >
        By selecting the machine and clicking the button, the egg information
        history is exported for the USDA. This can take several minutes.
      </Typography>

      <div
        style={{
          display: "flex",
        }}
      >
        <DatePicker
          inputFormat={"dd-MM-yyyy"}
          disabled={isPageLoading}
          value={selectedDate}
          onChange={(date) => onDateUpdated(new Date(date!.getTime()))}
          maxDate={new Date()}
          className={classes.datePicker}
          renderInput={(props) => <TextField label="From" {...props} />}
        />

        <Autocomplete
          sx={{
            marginTop: "1rem",
            marginRight: "1rem",
            marginBottom: "1rem",
            width: "8rem",
          }}
          disabled={isPageLoading}
          disableClearable
          disableCloseOnSelect={false}
          options={machines}
          getOptionLabel={(option: Machine) => `${option.machineId ?? ""}`}
          onChange={(event: any, newValue: Machine) => {
            onMachineUpdated(newValue);
          }}
          isOptionEqualToValue={(option: Machine, value: Machine) => {
            return option.machineId === value.machineId;
          }}
          renderInput={(params: any) => <TextField {...params} />}
        />

        <TextField
          sx={{
            marginTop: "1rem",
            marginRight: "1rem",
            marginBottom: "1rem",
            width: "12rem",
          }}
          disabled={isPageLoading}
          label={"Minimum crack level"}
          defaultValue={min}
          InputProps={{
            type: "number",
            endAdornment: (
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="body1">
                      The minimum level at which an egg is considered cracked.
                    </Typography>
                    <Typography
                      variant="body2"
                      display="inline"
                      component="div"
                    >
                      <Box>
                        Each egg has a crack value, this value represents how
                        cracked the egg is.
                      </Box>
                      Crack levels range from {min} to {crackMax}. <Box /> The
                      lower the crack level, the lower the treshold for
                      determining which eggs are considered cracked.
                    </Typography>
                  </React.Fragment>
                }
                arrow
                disableFocusListener
                disableTouchListener
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value > crackMax) e.target.value = crackMax.toString();
            if (value < min) e.target.value = min.toString();
            onCrackUpdated(parseInt(e.target.value));
          }}
        />

        <TextField
          sx={{
            marginTop: "1rem",
            marginRight: "1rem",
            marginBottom: "1rem",
            width: "12rem",
          }}
          disabled={isPageLoading}
          label={"Minimum dirt level"}
          defaultValue={min}
          InputProps={{
            type: "number",
            endAdornment: (
              <Tooltip
                arrow
                disableFocusListener
                disableTouchListener
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="body1">
                      The minimum level at which an egg is considered dirty.
                    </Typography>
                    <Typography
                      variant="body2"
                      display="inline"
                      component="div"
                    >
                      <Box>
                        Each egg has a dirt value, this value represents how
                        dirty the egg is.
                      </Box>
                      Dirt levels range from {min} to {dirtMax}. <Box /> The
                      lower the dirt level, the lower the treshold for
                      determining which eggs are considered dirty.
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value > dirtMax) e.target.value = dirtMax.toString();
            if (value < min) e.target.value = min.toString();
            onDirtUpdated(parseInt(e.target.value));
          }}
        />
        <ButtonGroup>
          <ProtectedButton
            size="large"
            onClick={async () =>
              await onDownloadUsdaEggInformationHistory(selectedMachine, selectedDate)
            }
            className={classes.exportButton}
            disabled={isPageLoading}
            requiredFeature={Feature.CustomerCareReadUsers}
          >
            <GetAppIcon
              sx={{
                marginRight: "0.5rem",
              }}
            />
            Export
          </ProtectedButton>
        </ButtonGroup>
      </div>

      {errorMessage !== undefined && errorMessage !== "" && (
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
}

export default ExportUsdaEggInformationHistoryPresentation;
