import { Button, Divider, Grid, MenuItem, Select, Switch, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikErrors, FormikState, FormikTouched } from "formik";
import React, { useEffect } from "react";
import {
  AnnouncementSeverity,
  GlobalAnnouncementModel,
} from "../../types/GlobalAnnouncementModel";
import InputWithLeftLabel from "../Forms/FormSelect";
import { MaxCharactersInMessage, MaxCharactersInTitle } from "./common";

const useStyles = makeStyles((theme) => ({
  buttonSection: {
    marginTop: theme.spacing(3),
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
}));

interface Props {
  values: GlobalAnnouncementModel;
  errors: FormikErrors<GlobalAnnouncementModel>;
  handleChange: (e: any) => void;
  handleSubmit: (e?: any) => void;
  handleBlur: (e: any) => void;
  isValid: boolean;
  dirty: boolean;
  touched: FormikTouched<GlobalAnnouncementModel>;
  resetForm: (
    nextState?: Partial<FormikState<GlobalAnnouncementModel>> | undefined
  ) => void;
  isPageLoading: boolean;
  setPreviewAnnouncement: React.Dispatch<
    React.SetStateAction<GlobalAnnouncementModel | null>
  >;
}

export default function GlobalAnnouncementForm({
  values,
  errors,
  handleChange,
  handleSubmit,
  handleBlur,
  isValid,
  dirty,
  touched,
  resetForm,
  isPageLoading,
  setPreviewAnnouncement,
}: Props) {
  const classes = useStyles();

  useEffect(() => {
    if (isValid === true) {
      const preview = { ...values };
      preview.isActive = true;
      setPreviewAnnouncement(preview);
    } else {
      setPreviewAnnouncement(null);
    }
  }, [isValid, values]);

  return (
    <Form>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <FormFields
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            isPageLoading={isPageLoading}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.buttonSection}>
          <Divider />
          <div className={classes.buttonGroup}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSubmit();
              }}
              disabled={!dirty || !isValid || isPageLoading}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                resetForm();
              }}
              disabled={!dirty || isPageLoading}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Grid>
    </Form>
  );
}

function FormFields({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  isPageLoading,
}: any) {
  return <>
    <InputWithLeftLabel
      label={"Active *"}
      inputJsx={
        <Switch
          id="isActive"
          disabled={isPageLoading}
          checked={values.isActive ?? false}
          onChange={handleChange}
          onBlur={handleBlur}
          color="primary"
        />
      }
    />

    <InputWithLeftLabel
      label={"Title *"}
      inputJsx={
        <TextField
          id="title"
          disabled={isPageLoading}
          fullWidth
          value={values.title ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.title && Boolean(errors.title)}
          helperText={
            (touched.title && errors.title) ||
            getMaxCharactersText(values.title, MaxCharactersInTitle)
          }
          variant="outlined"
        />
      }
    />

    <InputWithLeftLabel
      label={"Message *"}
      inputJsx={
        <TextField
          id="message"
          disabled={isPageLoading}
          fullWidth
          multiline
          rows={5}
          value={values.message ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.message && Boolean(errors.message)}
          helperText={
            (touched.message && errors.message) ||
            getMaxCharactersText(values.message, MaxCharactersInMessage)
          }
          variant="outlined"
        />
      }
    />

    <InputWithLeftLabel
      label={"Severity *"}
      inputJsx={
        <Select
          variant="standard"
          id="severity"
          disabled={isPageLoading}
          value={values.severity ?? 0}
          fullWidth
          onChange={(changed) => {
            changed.target.name = "severity";
            handleChange(changed);
          }}
          onBlur={(changed) => {
            changed.target.name = "severity";
            handleBlur(changed);
          }}>
          {Object.keys(AnnouncementSeverity)
            .filter((v) => isNaN(Number(v)))
            .map((key, index) => (
              <MenuItem key={key} value={index}>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </MenuItem>
            ))}
        </Select>
      }
    />
  </>;
}

function getMaxCharactersText(
  inputString: string | undefined,
  maxCharacters: number
): string {
  let inputStringLength = inputString?.length ?? 0;
  return `${inputStringLength}/${maxCharacters}`;
}
