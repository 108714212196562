import { useAlertsActions, useAlertsState } from "../../../store/GlobalContext";
import { AlertMessage } from "./Alert";

export function useDispatchAlert(): (m: AlertMessage) => void {
  const { updateAlertsState } = useAlertsActions();
  const state = useAlertsState();

  return (message: AlertMessage) => {
    updateAlertsState({
      ...state,
      alertMessages: [...state.alertMessages, message],
    });
  };
}

export function useDispatchClearMessages() {
  const { updateAlertsState } = useAlertsActions();
  return () => {
    updateAlertsState({
      alertMessages: [],
    });
  };
}
