import { IGlobalState } from "./GlobalContext";

export interface Action {
  type: string;
  payload: any;
}

/**
 * The Reducer takes the previous state an and action, and returns the next state.
 * This function will contain all actions to mutate global state in this application.
 *
 * @param state The current (previous) state
 * @param action The action to perform on the state
 *
 * @returns The state after performing the action
 */
export function Reducer(state: IGlobalState, action: Action): IGlobalState {
  switch (action.type) {
    case "UPDATE_ALERTS_STATE":
      return { ...state, alerts: { ...action.payload } };
    case "UPDATE_MY_USER":
      return {
        ...state,
        myUser: action.payload,
      };
    case "UPDATE_MY_USER_IS_LOADING":
      return {
        ...state,
        myUserIsLoading: action.payload,
      };
    default:
      return state;
  }
}
