import React from "react";
import InvoicesContainer from "../../components/Invoices/InvoicesContainer";
import BasePage from "../../components/ui/BasePage";

export default function InvoicesPage() {
  return (
    <BasePage>
      <InvoicesContainer />
    </BasePage>
  );
}
