import React from "react";
import EditMachineContainer from "../../components/EditMachine/EditMachineContainer";
import BasePage from "../../components/ui/BasePage";

export default function EditMachinePage() {
  return (
    <BasePage>
      <EditMachineContainer />
    </BasePage>
  );
}
