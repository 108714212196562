import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useState } from "react";
import { HashRouter } from "react-router-dom";
import AppStyles from "./AppStyles";
import Alert from "./components/ui/Alert";
import CustomerCareToolbar from "./components/ui/CustomerCareToolbar";
import InformationBar from "./components/ui/InformationBar";
import NavigationDrawer from "./components/ui/NavigationDrawer";
import {
  useHttpClientPipelineInjectorEffect,
  useLoginWithRedirectEffect,
} from "./globalHooks/authHooks";
import { useLoadMyUserIntoGlobalState } from "./globalHooks/authorizationHooks";
import RootPage from "./pages/RootPage";
import { generalNav, managementNav } from "./utility/Routes";

export interface LastDataReceived {
  lastDataReceivedDate: Date;
  lastDataReceivedFound: boolean;
}

const navigationItems = [
  {
    name: "Management",
    entries: managementNav,
  },
  {
    name: "General",
    entries: generalNav,
  },
];

function App() {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const { isAuthenticated } = useAuth0();
  useHttpClientPipelineInjectorEffect();
  useLoadMyUserIntoGlobalState();

  const classes = AppStyles();

  useLoginWithRedirectEffect();

  const handleOnMenuClick = useCallback(
    () => setShowDrawer(!showDrawer),
    [showDrawer]
  );

  const handleOnDrawerLinkClick = useCallback(
    () => setShowDrawer(!showDrawer),
    [showDrawer]
  );

  return (
    <div className="App">
      <HashRouter>
        <CustomerCareToolbar onMenuClick={handleOnMenuClick} />
        <NavigationDrawer
          isOpen={showDrawer}
          navigationItems={navigationItems}
          onDrawerLinkClick={handleOnDrawerLinkClick}
        />
        <div className={classes.content}>{isAuthenticated && <RootPage />}</div>
        <Alert />
        <InformationBar version="2.0" />
        <Alert />
      </HashRouter>
    </div>
  );
}

export default App;
