import { format } from "date-fns";
import { axiosHttpClient } from "../../store/GlobalContext";

export const downloadUsdaEggInformationHistory = async (
  machineId: MachineId,
  date: Date,
  minDirtLevel: number,
  minCrackLevel: number
): Promise<void> => {
  const formattedDate = format(date, "yyyy-MM-dd");

  const result = await axiosHttpClient.getBlob(
    `v1/usdaegginformationhistory/export?machineId=${machineId}&date=${formattedDate}&minimumDirtLevel=${minDirtLevel}&minimumCrackLevel=${minCrackLevel}`,
    {
      timeout: 1000 * 60 * 5, // 1 second * 60 = 1 minute * 5 = 5 minutes
    }
  );

  if (!(result.data instanceof Blob)) return;

  // Response header form the API should be as follows:
  // content-disposition: attachment; filename=[<machineId> - <date> - min crack level <minCrackLevel> - min dirt level <minDirtLevel>] USDA Egg Information History.xlsx
  // Example:
  // content-disposition: attachment; filename=[880423 - 2023-05-26 - min crack level 8 - min dirt level 4] USDA Egg Information History.xlsx
  // We trim it just for safety
  let filename = result.headers["content-disposition"]
    .split(";")[1]
    .split("filename=")[1]
    .trim();

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(result.data);
  link.download = filename;
  link.click();
};
