import React from "react";
import SetGlobalAnnouncementContainer from "../../components/GlobalAnnouncement/SetGlobalAnnouncementContainer";
import BasePage from "../../components/ui/BasePage";

export default function SetGlobalAnnouncementPage() {
  return (
    <BasePage>
      <SetGlobalAnnouncementContainer />
    </BasePage>
  );
}
