import CardMembershipIcon from "@mui/icons-material/CardMembership";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/Warning";
import { ClassNameMap, Tooltip } from "@mui/material";
import React from "react";
import {
  Feature,
  useAuthorization,
} from "../../globalHooks/authorizationHooks";
import { toISO8601DateTimeStringLocal } from "../../utility/DateUtility";
import { IsMachineInstalled } from "../../utility/MachineUtility";
import ProtectedIconButton from "../ui/ProtectedIconButton";

type NumberFunction = (num: number) => void;

export const CreateColumns = (
  onManageSubscription: NumberFunction,
  onViewMachine: NumberFunction,
  onEditMachine: NumberFunction,
  onDeleteMachine: NumberFunction,
  classes: ClassNameMap<"tooltip" | "machineIdContainer" | "warningIcon">
) => {
  const authorization = useAuthorization();
  return [
    {
      field: "machineId",
      headerName: "Machine Id",
      flex: 1,
      renderCell: (params) => {
        const isMachineLocked = authorization.lockedMachines.includes(
          Number(params.row.machineId)
        );

        return (
          <div className={classes.machineIdContainer}>
            {params.row.hasRecentlyExpiredSubscriptions && (
              <Tooltip
                title={"A subscription on this machine has recently expired"}
                className={classes.tooltip}
              >
                <WarningIcon className={classes.warningIcon} />
              </Tooltip>
            )}
            {isMachineLocked && (
              <Tooltip
                title={
                  "This machine is in read-only mode because it is locked by the admin of the machine."
                }
                className={classes.tooltip}
              >
                <LockIcon />
              </Tooltip>
            )}
            {params.row.deleteMachineInProgress && (
              <Tooltip
                title={
                  "This machine is marked for deletion and will be removed from this list shortly."
                }
                className={classes.tooltip}
              >
                <DeleteIcon />
              </Tooltip>
            )}
            {params.row.machineId}
          </div>
        );
      },
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
    },
    {
      field: "ownerFullName",
      headerName: "Owner",
      flex: 1,
      valueGetter: (params) =>
        params.row.ownerFullName.length === 0
          ? "No owner"
          : params.row.ownerFullName,
    },
    {
      field: "ownerEmail",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "ownerPhone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "asmEmail",
      headerName: "ASM",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "createdAtUTC",
      headerName: "Creation Date",
      flex: 1,
      valueGetter: (params) =>
        toISO8601DateTimeStringLocal(params.row.createdAtUTC),
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        const isMachineLocked = authorization.lockedMachines.includes(
          Number(params.row.machineId)
        );

        return (
          <>
            <ProtectedIconButton
              onClick={() => onManageSubscription(params.row.machineId)}
              requiredFeature={Feature.CustomerCareManageMachines}
              color={"primary"}
              disabled={IsMachineInstalled(params.row.machineConnectionStatus)}
              tooltip={"Machine Subscriptions"}
            >
              <CardMembershipIcon />
            </ProtectedIconButton>
            {isMachineLocked ? (
              <ProtectedIconButton
                onClick={() => onViewMachine(params.row.machineId)}
                requiredFeature={Feature.CustomerCareManageMachines}
                color={"primary"}
                disabled={IsMachineInstalled(
                  params.row.machineConnectionStatus
                )}
                tooltip={"View Machine"}
              >
                <VisibilityIcon />
              </ProtectedIconButton>
            ) : (
              <ProtectedIconButton
                onClick={() => onEditMachine(params.row.machineId)}
                requiredFeature={Feature.CustomerCareManageMachines}
                color={"primary"}
                tooltip={"Edit Machine"}
              >
                <EditIcon />
              </ProtectedIconButton>
            )}
            <ProtectedIconButton
              onClick={() => onDeleteMachine(params.row.machineId)}
              requiredFeature={Feature.CustomerCareManageMachines}
              color={"secondary"}
              tooltip={"Delete Machine"}
              disabled={params.row.deleteMachineInProgress}
            >
              <DeleteIcon
                color={
                  params.row.deleteMachineInProgress ? "disabled" : "secondary"
                }
              />
            </ProtectedIconButton>
          </>
        );
      },
      disableColumnMenu: true,
      sortable: false,
      minWidth: 140,
    },
  ];
};
