import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React from "react";
import DraggablePaperComponent from "./DraggablePaperComponent";

interface Props {
  children?: React.ReactNode;
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
  steps: Array<string>;
  lastActionButtonDisabled: boolean;
  lastActionButtonText: string;
}

export function StepperDialog({
  children,
  isOpen,
  onConfirm,
  onReject,
  steps,
  lastActionButtonDisabled,
  lastActionButtonText,
}: Props) {
  const childrenArray = React.Children.toArray(children);

  const [activeStep, setActiveStep] = React.useState(0);

  const resetState = () => {
    setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReject = () => {
    resetState();
    onReject();
  };

  const handleConfirm = () => {
    resetState();
    onConfirm();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleReject}
      PaperComponent={DraggablePaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Warning
      </DialogTitle>
      <DialogContent>
        {steps.length > 1 && (
          <Stepper activeStep={activeStep}>
            {steps.map((value, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{value}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
        {childrenArray[activeStep]}
      </DialogContent>
      <DialogActions>
        {activeStep === 0 ? (
          <Button autoFocus onClick={() => handleReject()} color="primary">
            Cancel
          </Button>
        ) : (
          <Button autoFocus onClick={handleBack} color="primary">
            Back
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            autoFocus
            disabled={lastActionButtonDisabled}
            onClick={() => handleConfirm()}
            color="secondary"
          >
            {lastActionButtonText}
          </Button>
        ) : (
          <Button autoFocus onClick={handleNext} color="primary">
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
