import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React from "react";

export interface AddMachineDialogPresentationProps {
  open: boolean;
  machineId: number | null;
  displayError: boolean;
  errorMessage: string;
  onChange: (event: any) => void;
  onCancel: () => void;
  onAddMachine: (machineId: number | null) => void;
}

export default function AddMachineDialogPresentation({
  open,
  machineId,
  displayError,
  errorMessage,
  onChange,
  onCancel,
  onAddMachine,
}: AddMachineDialogPresentationProps) {
  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Add a machine</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new machine, please enter the Machine Id{" "}
            <b> as a number</b>. Machine type pre/postfixes such as "PX" or "F"
            are to be omitted. Example: Machine Id "F100000" should be entered
            as "100000"
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="machineId"
            label="Machine Id"
            placeholder="350009"
            fullWidth
            onChange={onChange}
            value={machineId ?? ""}
            variant={"outlined"}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            error={displayError}
            helperText={errorMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => onAddMachine(machineId)}
            color="primary"
            disabled={!machineId}
          >
            Add machine
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
