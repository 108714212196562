import React, { useCallback, useMemo, useState } from "react";
import { MachineWithOwner } from "../../viewMachineList/ViewMachineListContainer";
import AddUserDialogPresentation from "./AddUserDialogPresentation";

export interface Props {
  open: boolean;
  machines: Array<MachineWithOwner>;
  onClose: () => void;
  onClick: (machineId: MachineWithOwner) => void;
}

export default function AddUserDialog({
  open,
  machines,
  onClose,
  onClick,
}: Props) {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const searchedMachines = useMemo(() => {
    if (searchTerm.length === 0) {
      return machines;
    }

    return machines.filter((machine: MachineWithOwner) => {
      let values = Object.values(machine.machineView);

      return values.join(" ").toLowerCase().includes(searchTerm);
    });
  }, [searchTerm, machines]);

  const handleChange = useCallback((value: string) => {
    setSearchTerm(value);
  }, []);

  return (
    <AddUserDialogPresentation
      open={open}
      machines={searchedMachines}
      searchTerm={searchTerm}
      onChange={handleChange}
      onClose={onClose}
      onClick={onClick}
    />
  );
}
