import Alert from "@mui/lab/Alert";
import React from "react";

export function UserOwnerOfMachineStep({
  ownerOfMachines,
}: {
  ownerOfMachines: Array<number>;
}) {
  return (
    <>
      <Alert severity="warning">
        This user is an owner of the following machine(s):
        <ul>
          {ownerOfMachines.map((machineId, index) => {
            return <li key={index}>{machineId}</li>;
          })}
        </ul>
        Deleting this user will leave these machine(s) without an owner. After
        deleting this user, another owner should be reassigned to these
        machines.
      </Alert>
    </>
  );
}
