import { Paper, Typography, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import iMobaLogoImage from "../../resources/moba_logo.png";

export interface InformationBarProps {
  version: string;
}

const useStyles = makeStyles((theme) => ({
  bar: {
    width: "100vw",
    position: "fixed",
    left: 0,
    bottom: 0,
    padding: theme.spacing(1),
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(2),
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  version: {
    flex: 1,
  },
  lastReceived: {
    marginRight: theme.spacing(2),
  },
}));

function InformationBar({ version }: InformationBarProps) {
  const classes = useStyles();

  const isMobileSize = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Paper className={classes.bar}>
      <div className={classes.flexBox}>
        <img
          src={iMobaLogoImage}
          alt="imobacustomercare"
          className={classes.logo}
        />

        <div className={classes.textContainer}>
          <Typography
            className={classes.version}
            variant={isMobileSize ? "caption" : "body1"}
          >
            Customer Care Version {version}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

export default InformationBar;
