import { SystemRole } from "../globalHooks/authorizationHooks";

export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  phone: string;
  country: string;
  lastLoginDateUtc: Date;
  assignedMachines: number[];
  systemRoles: SystemRole[]
}

export interface ICustomerCareUser extends IUser {
  isAssignedAsm: boolean;
}

export const GetEmptyCustomerCareUser = (): ICustomerCareUser => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phone: '',
    country: '',
    lastLoginDateUtc: new Date(),
    assignedMachines: [],
    systemRoles: [],
    isAssignedAsm: false
  };
};
