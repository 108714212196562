import React from "react";

import BasePage from "../../components/ui/BasePage";
import AddCustomerCareUserContainer from "../../components/ViewCustomerCareUserList/AddCustomerCareUser/AddCustomerCareUserContainer";

export default function AddcustomerCareUserPage() {
  return (
    <BasePage>
      <AddCustomerCareUserContainer />
    </BasePage>
  );
}
