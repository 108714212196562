import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { colors } from "../../colors";
import { IUser } from "../../types/User";
import { CreateColumns } from "./ViewUserListUtil";

interface Props {
  users: IUser[];
  onResetUserMfa: (email: string) => void;
  onDeleteUser: (email: string) => Promise<void>;
}

function ViewUserListPresentation({
  users,
  onResetUserMfa,
  onDeleteUser,
}: Props) {
  const columns = useMemo(() => {
    return CreateColumns(onResetUserMfa, onDeleteUser);
  }, [onDeleteUser, onResetUserMfa]);

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={users}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        getRowId={(row) => row.email}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.mobaBlue,
            color: "white",
          },
          "& .MuiDataGrid-sortIcon, & .MuiDataGrid-filterIcon, & .MuiDataGrid-menuIconButton":
            {
              opacity: 1,
              color: "white",
            },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(odd)": {
              backgroundColor: colors.rowBackgroundColor,
            },
          },
        }}
        isRowSelectable={() => false}
      />
    </div>
  );
}

export default ViewUserListPresentation;
