import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../../colors";

export interface NavigationMenuProps {
  navigationItems: any[];
  onDrawerLinkClick: () => void;
}

/**
 * Gets the current page URL.
 * This is the page after the hash in the url, e.g.
 * www.app.imoba.com/#/dashboard => /dashboard
 */
function retrievePageUrl(): string {
  const locationHash = window.location.hash;
  return locationHash.substring(1, locationHash.length);
}

const useStyles = makeStyles((theme) => ({
  sectionName: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    fontWeight: "normal",
    color: colors.grey40,
    backgroundColor: colors.white,
  },
  navLink: {
    textDecoration: "none",
    color: colors.black,
  },
  navText: {
    minWidth: theme.spacing(35),
  },
}));

function NavigationMenu({
  navigationItems,
  onDrawerLinkClick,
}: NavigationMenuProps) {
  const classes = useStyles();
  return (
    <>
      {navigationItems.map((item: any, i: number) => (
        <div key={i}>
          <List>
            {item.entries.map((entry: any, i: number) => (
              <Link
                key={i}
                to={entry.link}
                className={classes.navLink}
                onClick={onDrawerLinkClick}
              >
                <ListItem
                  button
                  className={classes.navText}
                  selected={entry.link === retrievePageUrl()}
                >
                  <ListItemIcon>{entry.icon}</ListItemIcon>
                  <ListItemText primary={entry.name} />
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
        </div>
      ))}
    </>
  );
}

export default NavigationMenu;
