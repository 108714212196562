import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert } from "@mui/lab";
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { addYears, format } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import PagePaper from "../../ui/PagePaper";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  iconButton: {
    marginLeft: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  loading: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  isPageLoading: boolean;
  machineId: MachineId;
  displayCredentials: boolean;
  clientId: string;
  clientSecret: string;
  onClick: () => void;
}

export default function CredentialsPresentation({
  isPageLoading,
  machineId,
  displayCredentials,
  clientId,
  clientSecret,
  onClick,
}: Props) {
  const classes = useStyles();
  return (
    <PagePaper isLoading={isPageLoading} title={"Machine API Credentials"}>
      <Typography variant="subtitle1" className={classes.subtitle}>
        The credentials below grants the machine '{machineId}' access to the
        Machine API.
      </Typography>

      {displayCredentials ? (
        <CredentialsSection
          clientId={clientId}
          clientSecret={clientSecret}
          isPageLoading={isPageLoading}
        />
      ) : (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={onClick}
          disabled={isPageLoading}
        >
          Display Machine API Credentials
        </Button>
      )}
    </PagePaper>
  );
}

function CredentialsSection({
  clientId,
  clientSecret,
  isPageLoading,
}: {
  clientId: string;
  clientSecret: string;
  isPageLoading: boolean;
}) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.marginBottom}>
        <Grid item xs={11} md={5}>
          <TextField
            label="Client Id"
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            disabled={isPageLoading}
            value={clientId}
          />
        </Grid>
        <Grid item xs={1}>
          <CopyToClipboardButton
            textToCopy={clientId}
            isPageLoading={isPageLoading}
          />
        </Grid>
        <Grid item xs={11} md={5}>
          <TextField
            label="Client Secret"
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            disabled={isPageLoading}
            value={clientSecret}
          />
        </Grid>
        <Grid item xs={1}>
          <CopyToClipboardButton
            textToCopy={clientSecret}
            isPageLoading={isPageLoading}
          />
        </Grid>
      </Grid>
      <Alert severity="info" className={classes.marginBottom}>
        This ClientId and Client Secret combination are valid for{" "}
        <b>two years</b>. <br />
        Please set a reminder before{" "}
        {format(addYears(new Date(), 2), "dd-MM-yyyy")} to prevent
        authorization/authentication issues with the Egg Grading Machine and the
        Machine API.
      </Alert>
      <Alert severity="warning">
        The Client Secret should be handled carefully. Please refrain from
        sharing these credentials with others in order to prevent security
        leaks. <br />
        After leaving this page, the Client Id and Client Secret will{" "}
        <b>never</b> be visible again. <br />
        If the Client Id and Secret are lost, a new pair should be created here
        and the machine should be reconfigured.
      </Alert>
    </>
  );
}

function CopyToClipboardButton({
  textToCopy,
  isPageLoading,
}: {
  textToCopy: string;
  isPageLoading: boolean;
}) {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const tooltipTitle = useMemo(() => {
    return copied ? "Copied" : "Copy to clipboard";
  }, [copied]);

  const handleClick = useCallback(async () => {
    await navigator.clipboard.writeText(textToCopy);
    setCopied(true);
  }, [textToCopy]);

  const handleOnMouseEnter = useCallback(() => setCopied(false), []);

  return isPageLoading ? (
    <CircularProgress className={classes.loading} />
  ) : (
    <Tooltip title={tooltipTitle} onMouseEnter={handleOnMouseEnter}>
      <IconButton
        aria-label="copy"
        className={classes.iconButton}
        onClick={handleClick}
        size="large"
      >
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
}
