import { AxiosResponse } from "axios";
import { axiosHttpClient } from "../../store/GlobalContext";
import { MachineDiagnostic } from "../../types/MachineDiagnostics/MachineDiagnosticEnum";
import { MachineDiagnosticModel } from "../../types/MachineDiagnostics/MachineDiagnosticModel";
import { MachineDiagnosticSummaryModel } from "../../types/MachineDiagnostics/MachineDiagnosticsSummaryModel";

export const getMachineDiagnosticsSummary = (): Promise<
  AxiosResponse<MachineDiagnosticSummaryModel>
> => {
  return axiosHttpClient.get("v1/machine-diagnostics/summary");
};

export const getMachineDiagnostic = (
  machineDiagnostic: MachineDiagnostic
): Promise<AxiosResponse<MachineDiagnosticModel>> => {
  return axiosHttpClient.get(
    `v1/machine-diagnostics?machineDiagnostic=${machineDiagnostic}`
  );
};

export const setMachineDiagnosticIgnored = (
  machineId: MachineId,
  machineDiagnostic: MachineDiagnostic,
  newIgnoreValue: boolean
) => {
  return axiosHttpClient.post(`v1/machine-diagnostics/ignore`, {
    machineId: machineId,
    machineDiagnostic: machineDiagnostic,
    newIgnoreValue: newIgnoreValue,
  });
};
