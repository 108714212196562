import makeStyles from '@mui/styles/makeStyles';


export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(7),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(8),
    },
    maxWidth: "100vw",
    paddingBottom: theme.spacing(10),
  }
})
)
