import { Button, Divider, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from "formik";
import React from "react";

import { ICustomerCareUser } from "../../../types/User";
import { IsValidEmailAddress } from "../../../utility/FormUtility";
import { CustomerCareUserForm } from "./CustomerCareUserForm";


const useStyles = makeStyles((theme) => ({
  buttonSection: {
    marginTop: theme.spacing(3),
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
}));

export interface AddCustomerCareUserFormProps {
  isPageLoading: boolean;
  onSubmit: (customerCareUser: ICustomerCareUser) => void;
}

export default function AddCustomerCareUserForm({
  isPageLoading,
  onSubmit,
}: AddCustomerCareUserFormProps) {
  const classes = useStyles();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
            email: "",
            firstName: "",
            lastName: "",
            company: "",
            phone: "",
            country: "",
            isAssignedAsm: false,
            assignedMachines: [],
            systemRoles: [],
            lastLoginDateUtc: new Date()
        }}
        onSubmit={(values, _) => {
          onSubmit(values);
        }}
        validate={(values: ICustomerCareUser) => {
          const errors: any = {};

          if (!values.email) {
              errors.email = "Required";
          }
          if (!IsValidEmailAddress(values.email)) {
              errors.email = "Invalid email";
            }
          if (!values.firstName) {
            errors.firstName = "Required";
          }
          if (!values.lastName) {
            errors.lastName = "Required";
          }
          if (!values.systemRoles || values.systemRoles.length === 0) {
            errors.systemRoles = "Required";
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
          resetForm,
          setFieldValue,
          initialValues,
        }) => (
          <Form>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <CustomerCareUserForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  isPageLoading={isPageLoading}
                  initialValues={initialValues}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.buttonSection}>
                  <Divider />
                  <div className={classes.buttonGroup}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={!dirty || !isValid || isPageLoading}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        resetForm();
                      }}
                      disabled={!dirty || isPageLoading}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
  
  