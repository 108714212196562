import React from "react";
import ExportLogsContainer from "../../components/Logs/ExportLogsContainer";
import BasePage from "../../components/ui/BasePage";

function LogsPage() {
  return (
    <BasePage>
      <ExportLogsContainer />
    </BasePage>
  );
}

export default LogsPage;
