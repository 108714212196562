import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getMachines } from "../../api/Machine/MachineService";
import { downloadUsdaEggInformationHistory } from "../../api/UsdaEggInformationHistory/UsdaEggInformationHistoryService";
import { Machine } from "../../types/Machine";
import { MachineConnectionStatus } from "../../utility/MachineUtility";
import { useDispatchAlert } from "../ui/Alert";
import PagePaper from "../ui/PagePaper";
import { MachineWithOwner } from "../viewMachineList/ViewMachineListContainer";
import ExportUsdaEggInformationHistoryPresentation from "./ExportUsdaEggInformationHistoryPresentation";

interface DownloadUsdaEggInformationHistoryCommand {
  machineId: MachineId;
  date: Date;
  minDirtLevel: number;
  minCrackLevel: number;
}

export default function ExportUsdaEggInformationHistoryContainer() {
  const dispatchAlert = useDispatchAlert();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMachine, setSelectedMachine] = useState<Machine>();
  const [machines, setMachines] = useState<Machine[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedDirt, setSelectedDirt] = useState<number>(1);
  const [selectedCrack, setSelectedCrack] = useState<number>(1);

  const getAllMachines = useQuery("get-machines", () => getMachines(), {
    onSuccess: (response: MachineWithOwner[]) => {
      setMachines(
        response
          .map((mwo) => mwo.machineView)
          .filter(
            (m) =>
              m.machineConnectionStatus === MachineConnectionStatus.Approved ||
              m.machineConnectionStatus === MachineConnectionStatus.Connected
          )
      );
    },
    onError: () => {
      dispatchAlert({
        message: "Something went wrong while retrieving machines.",
        messageType: "error",
      });
    },
  });

  const downloadUsdaEggInformationHistoryMutation = useMutation(
    (input: DownloadUsdaEggInformationHistoryCommand) =>
      downloadUsdaEggInformationHistory(
        input.machineId,
        input.date,
        input.minDirtLevel,
        input.minCrackLevel
      ),
    {
      onSuccess: () => {
        dispatchAlert({
          message: "USDA egg information history exported successfully.",
          messageType: "success",
        });
      },
      onError: () => {
        dispatchAlert({
          message:
            "Something went wrong while exporting USDA egg information history.",
          messageType: "error",
        });
      },
    }
  );

  const onDownloadUsdaEggInformationHistory = async (
    machine: Machine | undefined,
    date: Date
  ) => {
    let midnightNow = new Date().setHours(0, 0, 0, 0);
    let midnightSelectedDate = date.setHours(0, 0, 0, 0);
    let error = false;
    if (midnightNow - midnightSelectedDate < 0) {
      error = true;
      setErrorMessage("Date cannot be in the future.");
    }
    if (machine == undefined) {
      error = true;
      setErrorMessage("Select a machine.");
    }
    if (error) {
      return;
    }

    setErrorMessage("");
    await downloadUsdaEggInformationHistoryMutation.mutateAsync({
      machineId: machine!.machineId,
      date: date,
      minDirtLevel: selectedDirt,
      minCrackLevel: selectedCrack,
    });
  };

  let isPageLoading =
    downloadUsdaEggInformationHistoryMutation.isLoading ||
    getAllMachines.isLoading;

  return (
    <PagePaper title={"Export logs"} isLoading={isPageLoading}>
      <ExportUsdaEggInformationHistoryPresentation
        machines={machines}
        selectedMachine={selectedMachine}
        onMachineUpdated={setSelectedMachine}
        selectedDate={selectedDate}
        onDateUpdated={setSelectedDate}
        onCrackUpdated={setSelectedCrack}
        onDirtUpdated={setSelectedDirt}
        onDownloadUsdaEggInformationHistory={
          onDownloadUsdaEggInformationHistory
        }
        errorMessage={errorMessage}
        isPageLoading={isPageLoading}
      />
    </PagePaper>
  );
}
