import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import {
  MachineSubscription,
  SubscriptionType,
} from "../../../utility/SubscriptionsUtility";

interface Props {
  open: boolean;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  onClose: () => void;
  onChange: (newEndDate: Date | null) => void;
  onSubmit: () => void;
  subscription: Nullable<MachineSubscription>;
}

export default function ChangeSubscriptionDialogPresentation({
  open,
  onClose,
  onChange,
  onSubmit,
  startDate,
  endDate,
  subscription,
}: Props) {
  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>
          {subscription?.isActive
            ? "Change end date"
            : "Activate subscription (no invoice)"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {subscription?.isActive
              ? `Change the end date for the ${
                  SubscriptionType[subscription?.subscriptionType]
                } subscription. No invoice or credit note will be generated.  If you want to extend the subscription with an invoice please use the auto-renew function of the subscriptions page in the Customer App.`
              : "This subscription will be activated without creating an invoice. If you want to activate the subscription with an invoice please go to the subscriptions page in the Customer App."}
          </DialogContentText>
          <DatePicker
            value={endDate}
            minDate={startDate ?? undefined}
            disablePast
            onChange={(value: Date | null) => onChange(value)}
            inputFormat={"dd-MM-yyyy"}
            renderInput={(props) => (
              <TextField
                label="End date of the subscription"
                fullWidth
                {...props}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onSubmit()} color="primary" disabled={false}>
            {subscription?.isActive
              ? "Change end date"
              : "Activate subscription"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
