import React from "react";
import { Drawer } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import NavigationMenu from "./NavigationDrawerUtil/NavigationMenu";

export interface NavigationDrawerProps {
  isOpen: boolean;
  navigationItems: any[];
  onDrawerLinkClick: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  drawer: {
    flexShrink: 0,
  },
  spaceMargin: {
    marginTop: "64px"
  }
}));

function NavigationDrawer({isOpen, navigationItems, onDrawerLinkClick}: NavigationDrawerProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        open={isOpen}
        onClose={onDrawerLinkClick}
        className={classes.drawer}
      >
        <div className={classes.spaceMargin}></div>
        <NavigationMenu
          navigationItems={navigationItems}
          onDrawerLinkClick={onDrawerLinkClick}
        />
      </Drawer>
    </div>
  );
}

export default NavigationDrawer;
