import { Button, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../globalHooks/authorizationHooks";
import { toISO8601DateStringLocal } from "../../utility/DateUtility";
import PagePaper from "../ui/PagePaper";
import CredentialsContainer from "./Credentials/CredentialsContainer";
import EditMachineForm from "./EditMachineForm";
import LockMachineContainer from "./LockMachine/LockMachineContainer";
import useEditMachineData from "./useEditMachineData";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

export default function EditMachineContainer() {
  const { machineId } = useParams<{ machineId: string }>();
  const classes = useStyles();
  const authorization = useAuthorization();

  const isMachineLocked = authorization.lockedMachines.includes(
    Number(machineId)
  );

  const {
    machineNotFound,
    machine,
    countries,
    users,
    asmUsers,
    machineTypes,
    isPageLoading,
    setChildLoading,
    handleEditMachine,
    handleDeleteComment,
  } = useEditMachineData(machineId);

  const handleOnLoad = useCallback(
    (isLoading: boolean) => {
      setChildLoading(isLoading);
    },
    [setChildLoading]
  );

  return (
    <>
      <div className={classes.margin}>
        <PagePaper
          isLoading={isPageLoading}
          title={`Machine ${machineId}`}
          extraInfo={`Owner approval date: ${
            machine?.approvalDate
              ? toISO8601DateStringLocal(machine.approvalDate)
              : "not approved"
          } `}
        >
          <GoToMachineListPageButton />
          <Divider className={classes.divider} />
          {machineNotFound === true ? (
            <Typography variant="body1">
              Machine {machineId} not found.
            </Typography>
          ) : (
            machine &&
            countries &&
            machineTypes && (
              <EditMachineForm
                machine={machine}
                onSubmit={handleEditMachine}
                onCommentDeleted={handleDeleteComment}
                machineTypes={machineTypes}
                countries={countries}
                users={users ?? []}
                asms={asmUsers ?? []}
                isPageLoading={isPageLoading}
                isMachineLocked={isMachineLocked}
              />
            )
          )}
        </PagePaper>
      </div>
      <div className={classes.margin}>
        <CredentialsContainer
          isPageLoading={isPageLoading}
          machineId={machineId}
          onLoad={handleOnLoad}
        />
      </div>
      <LockMachineContainer
        isPageLoading={isPageLoading}
        machineId={machineId}
        onLoad={handleOnLoad}
        isMachineLocked={isMachineLocked}
      />
    </>
  );
}

export function GoToMachineListPageButton() {
  const classes = useStyles();
  const browserHistory = useHistory();

  const handleClick = useCallback(() => {
    browserHistory.push("/machines/");
  }, [browserHistory]);

  return (
    <Button
      onClick={handleClick}
      color="primary"
      variant="outlined"
      className={classes.button}
    >
      Go to Machine List
    </Button>
  );
}
