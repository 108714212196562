import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useEffect, useState } from "react";
import { TableColumnLabel } from "../../types/TableColumnLabel";
import {
    StyledTableCell, StyledTableSortLabel
} from "../ui/TableComponents";

type OrderType = "asc" | "desc";

interface SortableColumnsProps<TModel> {
    defaultOrder: OrderType,
    defaultSortedColumn: string,
    collection: TModel[],
    setCollection: (newCollection: TModel[]) => void,
    columnLabels: TableColumnLabel[],
    comparator: (a: TModel, b: TModel, orderBy: string) => 0 | 1 | -1
};

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export default function SortableColumns<TModel>({
    defaultOrder,
    defaultSortedColumn,
    collection,
    setCollection,
    columnLabels,
    comparator = descendingComparator
}: SortableColumnsProps<TModel>) {
    const classes = useStyles();
    const [order, setOrder] = useState<OrderType>(defaultOrder);
    const [orderByColumn, setOrderByColumn] = useState<string>(defaultSortedColumn);

    const sortHandler = useCallback(
        (property: string, currentOrder: OrderType, currentSortColumn: string) => () => {
            const isCurrentAsc = currentSortColumn === property && currentOrder === "asc";
            const newOrder = isCurrentAsc ? "desc" : "asc";
            setOrder(newOrder);
            setOrderByColumn(property);
        }, []);

    useEffect(() => {
        const sortFunction = getComparator(order, orderByColumn, comparator);

        setCollection([...collection].sort(sortFunction));
    }, [order, orderByColumn, sortHandler, collection]);

    const orderText = order === "desc" ? "sorted descending" : "sorted ascending";

    return (
        <>
            {columnLabels.map((label: TableColumnLabel, i: number) => (
                <StyledTableCell key={i}>
                    <StyledTableSortLabel
                        active={orderByColumn === label.key}
                        direction={orderByColumn === label.key ? order : "asc"}
                        onClick={sortHandler(label.key, order, orderByColumn)}
                    >
                        {label.text}
                        {orderByColumn === label.key ? (
                            <span className={classes.visuallyHidden}>
                                {orderText}
                            </span>
                        ) : null}
                    </StyledTableSortLabel>
                </StyledTableCell>
            ))}
        </>
    );
}

function getComparator<TModel>(
    order: OrderType,
    orderBy: string,
    comparator: (a: TModel, b: TModel, orderBy: string) => 0 | 1 | -1
): (
        a: TModel,
        b: TModel
    ) => number {
    return order === "desc"
        ? (a, b) => comparator(a, b, orderBy)
        : (a, b) => -comparator(a, b, orderBy);
}

export function descendingComparator<TModel>(a: TModel, b: TModel, orderBy: string) {
    if (b[orderBy as keyof TModel] < a[orderBy as keyof TModel]) {
        return -1;
    }
    if (b[orderBy as keyof TModel] > a[orderBy as keyof TModel]) {
        return 1;
    }
    return 0;
}
