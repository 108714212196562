import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from "@mui/lab";
import React, { useMemo, useState } from "react";
import DraggablePaperComponent from "./DraggablePaperComponent";

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
  message: string;
  stringToRetype: string;
  displayExtraCheckboxConfirmation: boolean;
  extraInformationMessage: any;
  checkBoxLabel: string;
  informationAlertMessage?: string;
}

const useStyles = makeStyles((theme) => ({
  confirmDeleteButton: {
    color: "red",
  },
  retypeField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  stringToRetype: {
    fontWeight: "bold",
  },
  extraConfirmationSection: {
    marginTop: theme.spacing(2),
  },
  checkBox: {
    marginTop: theme.spacing(1),
  },
  informationAlert: {
    marginBottom: theme.spacing(1),
  },
}));

export function ConfirmActionRetypeDialog({
  isOpen,
  onConfirm,
  onReject,
  message,
  stringToRetype,
  displayExtraCheckboxConfirmation,
  extraInformationMessage,
  checkBoxLabel,
  informationAlertMessage,
}: Props) {
  const [inputCorrectlyRetyped, setInputCorrectlyRetyped] = useState(false);
  const [extraConfirmationValue, setExtraConfirmationValue] = useState(false);
  const classes = useStyles();

  const handleInputChanged = (newValue: string) => {
    if (newValue === stringToRetype) {
      setInputCorrectlyRetyped(true);
    } else {
      setInputCorrectlyRetyped(false);
    }
  };

  const onClose = () => {
    setInputCorrectlyRetyped(false);
    onReject();
  };

  const onAccept = () => {
    setInputCorrectlyRetyped(false);
    onConfirm();
  };

  const handleExtraConfirmationClick = () => {
    setExtraConfirmationValue(!extraConfirmationValue);
  };

  const saveButtonDisabled = useMemo(() => {
    if (displayExtraCheckboxConfirmation) {
      return !(extraConfirmationValue && inputCorrectlyRetyped);
    }
    return !inputCorrectlyRetyped;
  }, [
    inputCorrectlyRetyped,
    extraConfirmationValue,
    displayExtraCheckboxConfirmation,
  ]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperComponent={DraggablePaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ cursor: "move", color: "red" }}
        id="draggable-dialog-title"
      >
        Warning: This is a destructive action
      </DialogTitle>
      <DialogContent>
        <div>
          {informationAlertMessage !== "" && (
            <Alert severity="info" className={classes.informationAlert}>
              {informationAlertMessage}
            </Alert>
          )}
          <Typography>{message}</Typography>
          <Typography className={classes.retypeField}>
            Please type{" "}
            <span className={classes.stringToRetype}>'{stringToRetype}'</span>{" "}
            to confirm
          </Typography>
          <TextField
            autoFocus
            fullWidth
            variant="outlined"
            onChange={(e) => handleInputChanged(e.target.value)}
          />
        </div>
        {displayExtraCheckboxConfirmation && (
          <div className={classes.extraConfirmationSection}>
            {extraInformationMessage}
            <FormControlLabel
              className={classes.checkBox}
              control={
                <Checkbox
                  checked={extraConfirmationValue}
                  onChange={handleExtraConfirmationClick}
                  name="extra-confirmation"
                  color="primary"
                />
              }
              label={checkBoxLabel}
            />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          className={classes.confirmDeleteButton}
          onClick={onAccept}
          disabled={saveButtonDisabled}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
