import React, { useState } from "react";
import { useMachineService } from "../../../api/ServiceContext";
import { useDispatchAlert } from "../../ui/Alert";
import { ConfirmActionRetypeDialog } from "../../ui/ConfirmActionRetypeDialog";
import LockMachinePresentation from "./LockMachinePresentation";

export interface LockMachineContainerProps {
  machineId: MachineId;
  isPageLoading: boolean;
  isMachineLocked: boolean;
  onLoad: (isLoading: boolean) => void;
}

export default function LockMachineContainer({
  machineId,
  isPageLoading,
  isMachineLocked,
  onLoad,
}: LockMachineContainerProps) {
  const machineService = useMachineService();
  const dispatchAlert = useDispatchAlert();
  const [openLockMachineDialog, setOpenLockMachineDialog] = useState(false);

  const handleClick = () => {
    setOpenLockMachineDialog(true);
  };

  const handleLockMachineDialogClose = () => {
    setOpenLockMachineDialog(false);
  };

  const handleLockMachineDialogAccept = () => {
    setOpenLockMachineDialog(false);
    onLoad(true);
    machineService
      .lockMachine(Number(machineId))
      .then(() => {
        dispatchAlert({
          message: "Machine locked successfully.",
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: "Something went wrong while locking the machine",
          messageType: "error",
        });
      })
      .finally(() => {
        onLoad(false);
        window.location.reload(); // hard refresh so the user information is retrieved again, including the recently locked machine.
      });
  };

  return (
    <>
      <LockMachinePresentation
        machineId={machineId}
        isPageLoading={isPageLoading}
        isMachineLocked={isMachineLocked}
        onClick={handleClick}
      />
      <ConfirmActionRetypeDialog
        isOpen={openLockMachineDialog}
        onReject={handleLockMachineDialogClose}
        onConfirm={handleLockMachineDialogAccept}
        stringToRetype={machineId.toString()}
        displayExtraCheckboxConfirmation={true}
        extraInformationMessage={true}
        checkBoxLabel={
          "The iMoba machine owner/admin requested to lock this machine and is aware of the impact of this action"
        }
        message={`Are you sure you want to lock machine ${machineId}? When locked, 
          Customer Care will not be able to assist on this machine once this feature has been turned on. Only the iMoba admin of the machine is able to unlock
          the machine from the Customer App.`}
      />
    </>
  );
}
