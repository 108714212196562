import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useState } from "react";
import { colors } from "../../../colors";
import AutoCompleteTextField from "../../AutocompleteTextField/AutoCompleteTextField";
import InputWithLeftLabel from "../../Forms/FormSelect";

const useStyles = makeStyles((theme) => ({
  clickableHelperText: {
    color: colors.mobaBlue,
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 400,
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  isPageLoading: boolean;
  values: any;
  errors: any;
  touched: any;
  users: Array<string>;
  initialValues: any;
  handleChange: (value: any) => void;
  setFieldValue: (field: string, value: string) => Promise<unknown>;
  fieldId: string;
  isDisabled: boolean;
  alwaysDisplayError?: boolean;
}

export default function UserAutocomplete({
  isPageLoading,
  values,
  errors,
  touched,
  users,
  initialValues,
  handleChange,
  setFieldValue,
  fieldId,
  isDisabled,
  alwaysDisplayError,
}: Props) {
  const [displayNewUserInput, setDisplayNewUserInput] = useState(false);

  const handleOnHelperTextClick = useCallback(async () => {
    const displayNewUserInputCopy = !displayNewUserInput;

    if (displayNewUserInputCopy) {
      await setFieldValue("ownerEmail", "");
    } else {
      await setFieldValue("ownerEmail", initialValues.ownerEmail);
    }
    setDisplayNewUserInput(
      fieldId === "ownerEmail" ? displayNewUserInputCopy : false
    );
  }, [displayNewUserInput, fieldId, initialValues.ownerEmail, setFieldValue]);

  let label = "";

  if (fieldId === "ownerEmail") {
    label = displayNewUserInput ? "Owner * (will be invited)" : "Owner *";
  }

  if (fieldId === "asmEmail") {
    label = "ASM *";
  }

  return (
    <InputWithLeftLabel
      label={label}
      inputJsx={
        isPageLoading ? (
          <LoadingTextField valueToDisplay={values[fieldId]} />
        ) : (
          <UserAutocompletePresentation
            onHelperTextClick={handleOnHelperTextClick}
            displayNewUserInput={displayNewUserInput}
            isPageLoading={isPageLoading}
            values={values}
            errors={errors}
            touched={touched}
            users={users}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            initialValues={initialValues}
            fieldId={fieldId}
            isDisabled={isDisabled}
            alwaysDisplayError={alwaysDisplayError}
          />
        )
      }
    />
  );
}

interface OwnerAutocompletePresentationProps extends Props {
  displayNewUserInput: boolean;
  isDisabled: boolean;
  onHelperTextClick: () => void;
  alwaysDisplayError?: boolean;
}

function UserAutocompletePresentation({
  isPageLoading,
  values,
  errors,
  touched,
  users,
  displayNewUserInput,
  handleChange,
  setFieldValue,
  onHelperTextClick,
  fieldId,
  isDisabled,
  alwaysDisplayError,
}: OwnerAutocompletePresentationProps) {
  const classes = useStyles();

  const handleOnChange = useCallback(
    async (user: string) => {
      await setFieldValue(fieldId, user);
    },
    [fieldId, setFieldValue]
  );

  return displayNewUserInput ? (
    <>
      <TextField
        id={fieldId}
        disabled={isPageLoading || isDisabled}
        fullWidth
        value={values[fieldId] || ""}
        onChange={handleChange}
        error={
          (touched[fieldId] || alwaysDisplayError) && Boolean(errors[fieldId])
        }
        variant="outlined"
        helperText={(touched.place || alwaysDisplayError) && errors.place}
      />
      <Typography
        variant="subtitle2"
        className={classes.clickableHelperText}
        onClick={onHelperTextClick}
      >
        Or browse the existing list of iMoba users
      </Typography>
    </>
  ) : (
    <>
      <AutoCompleteTextField
        disabled={isPageLoading || isDisabled}
        options={users}
        selectedOption={values[fieldId]}
        onChange={handleOnChange}
        error={
          (touched[fieldId] || alwaysDisplayError) && Boolean(errors[fieldId])
        }
        helperText={(touched[fieldId] || alwaysDisplayError) && errors[fieldId]}
      />
      {!isDisabled && fieldId === "ownerEmail" && (
        <Typography
          variant="subtitle2"
          className={classes.clickableHelperText}
          onClick={onHelperTextClick}
        >
          Or invite a new owner to iMoba
        </Typography>
      )}
    </>
  );
}

function LoadingTextField({ valueToDisplay }: { valueToDisplay: string }) {
  return (
    <TextField
      fullWidth
      disabled={true}
      variant="outlined"
      value={valueToDisplay || ""}
    />
  );
}
