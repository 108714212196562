import differenceInDays from "date-fns/differenceInDays";
import subMonths from "date-fns/subMonths";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { downloadEventLogs } from "../../api/EventLogs/EventLogsService";
import { useDispatchAlert } from "../ui/Alert";
import PagePaper from "../ui/PagePaper";
import ExportLogsPresentation from "./ExportLogsPresentation";

interface DownloadLogsCommand {
  from: Date;
  until: Date;
}

export default function ExportLogsContainer() {
  const dispatchAlert = useDispatchAlert();

  const today = new Date();
  const [selectedFromDate, setSelectedFromDate] = useState(subMonths(today, 1));
  const [selectedUntilDate, setSelectedUntilDate] = useState(new Date());
  const [displayValidationErrorMessage, setDisplayValidationErrorMessage] =
    useState(false);

  const downloadLogs = useMutation(
    (input: DownloadLogsCommand) => downloadEventLogs(input.from, input.until),
    {
      onSuccess: () => {
        dispatchAlert({
          message: "Logs exported successfully.",
          messageType: "success",
        });
      },
      onError: () => {
        dispatchAlert({
          message: "Something went wrong while exporting logs.",
          messageType: "error",
        });
      },
    }
  );

  const onDownloadLogs = async (from: Date, until: Date) => {
    if (differenceInDays(until, from) > 365) {
      setDisplayValidationErrorMessage(true);
      return;
    }
    setDisplayValidationErrorMessage(false);
    await downloadLogs.mutateAsync({ from: from, until: until });
  };

  return (
    <PagePaper title={"Export logs"} isLoading={downloadLogs.isLoading}>
      <ExportLogsPresentation
        selectedFromDate={selectedFromDate}
        onFromDateUpdated={setSelectedFromDate}
        selectedUntilDate={selectedUntilDate}
        onUntilDateUpdated={setSelectedUntilDate}
        displayValidationErrorMessage={displayValidationErrorMessage}
        onDownloadLogs={onDownloadLogs}
        isPageLoading={downloadLogs.isLoading}
      />
    </PagePaper>
  );
}
