import { adaptV4Theme, createTheme, Theme } from "@mui/material/styles";
import { colors } from "./colors";

declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}
let theme = createTheme(
  adaptV4Theme({
    typography: {
      fontSize: 14,
    },
    palette: {
      primary: {
        main: colors.mobaBlue,
      },
      secondary: {
        main: colors.priorityRed,
      },
    },
    props: {
      MuiPopover: {
        style: {
          zIndex: 2000,
        },
      },
    },
  })
);

export default theme;
