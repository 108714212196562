import React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { Feature, useAuthorization } from "../globalHooks/authorizationHooks";
import SkeletonPage from "../pages/skeleton/SkeletonPage";
import { useMyUserIsLoadingState } from "../store/GlobalContext";

export function ProtectedRoute(props: ProtectedRouteProps) {
  const authorization = useAuthorization();
  const myUserIsLoading = useMyUserIsLoadingState();

  let component = props.component;

  if (
    props.feature &&
    !myUserIsLoading &&
    authorization.features.indexOf(props.feature.toString()) === -1
  ) {
    return <Redirect to="/unauthorized" />;
  }

  if (myUserIsLoading) {
    component = SkeletonPage;
  }

  return (
    <Route path={props.path} component={component}>
      {props.children}
    </Route>
  );
}

interface ProtectedRouteProps {
  feature?: Feature;
  path: string;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  children?: any;
}
