import { Button, Divider } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useCustomerCareUserService } from "../../../api/ServiceContext";
import { ICustomerCareUser } from "../../../types/User";
import { useDispatchAlert } from "../../ui/Alert";
import PagePaper from "../../ui/PagePaper";
import AddCustomerCareUserForm from "../Forms/AddCustomerCareUserForm";


const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));


export default function AddcustomerCareUserContainer() {
  const classes = useStyles();
  const customerCareUserService = useCustomerCareUserService();
  const dispatchAlert = useDispatchAlert();
  const browserHistory = useHistory();

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const handleSubmit = (submittedCustomerCareUser: ICustomerCareUser) => {
    const request = { ...submittedCustomerCareUser };
    setIsPageLoading(true);
    customerCareUserService
      .addCustomerCareUser(request)
      .then(() => {
        dispatchAlert({
          message: "Customer care team member added successfully",
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: "Something went wrong adding a new customer care team member",
          messageType: "error",
        });
      })
      .finally(() => {
        setIsPageLoading(false);
        browserHistory.push("/customer-care-team/");
      });
  };

  return (
    <>
      <PagePaper isLoading={isPageLoading} title={'Add customer care team member'}>
        <GoToCustomerCareUserListPageButton />
        <Divider className={classes.divider} />
           <AddCustomerCareUserForm
            onSubmit={handleSubmit}
            isPageLoading={isPageLoading}
          />
      </PagePaper>
    </>
  );
}

export function GoToCustomerCareUserListPageButton() {
  const classes = useStyles();
  const browserHistory = useHistory();

  const handleClick = () => {
    browserHistory.push("/customer-care-team/");
  };

  return (
    <Button
      onClick={handleClick}
      color="primary"
      variant="outlined"
      className={classes.button}
    >
      Go to customer care team list
    </Button>
  );
}
