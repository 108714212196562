import makeStyles from "@mui/styles/makeStyles";
import exportFromJSON from "export-from-json";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getInvoices } from "../../api/Invoices/InvoicesService";
import { UserQueryNames } from "../../api/Queries";
import { Feature } from "../../globalHooks/authorizationHooks";
import {
  getDaysBetweenDates,
  toISO8601DateString,
} from "../../utility/DateUtility";
import { SubscriptionType } from "../../utility/SubscriptionsUtility";
import { useDispatchAlert } from "../ui/Alert";
import PagePaper from "../ui/PagePaper";
import ProtectedButton from "../ui/ProtectedButton";
import TableSearch from "../ui/TableSearch";
import InvoicesPresentation from "./InvoicesPresentation";

const useStyles = makeStyles((theme) => ({
  button: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
    },
  },
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function InvoicesContainer() {
  const classes = useStyles();
  const dispatchAlert = useDispatchAlert();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const invoicesQuery = useQuery(
    UserQueryNames.getInvoices,
    () => getInvoices(),
    {
      onError: () => {
        dispatchAlert({
          message: "Something went wrong while retrieving the invoices.",
          messageType: "error",
        });
      },
    }
  );

  const searchedInvoices = useMemo(() => {
    if (invoicesQuery.data === undefined) return [];
    if (searchTerm.length === 0) return invoicesQuery.data;

    return invoicesQuery.data
      .filter((i) => {
        return (
          i.machineNumber.toString().includes(searchTerm) ||
          i.debtorNumber.toLocaleLowerCase().includes(searchTerm) ||
          i.companyName.toLocaleLowerCase().includes(searchTerm) ||
          i.amountDue.toString().includes(searchTerm) ||
          SubscriptionType[i.subscription]
            .toLocaleLowerCase()
            .includes(searchTerm) ||
          i.invoiceStatus.toLocaleLowerCase().includes(searchTerm) ||
          toISO8601DateString(i.subscriptionStartDate)
            .toLowerCase()
            .includes(searchTerm) ||
          toISO8601DateString(i.subscriptionEndDate).includes(searchTerm) ||
          getDaysBetweenDates(
            toISO8601DateString(new Date()),
            toISO8601DateString(i.subscriptionEndDate)
          )
            .toString()
            .includes(searchTerm) ||
          toISO8601DateString(i.invoiceCreatedDate)
            .toString()
            .includes(searchTerm)
        );
      })
      .sort((i1, i2) => (i1.machineNumber > i2.machineNumber ? 1 : -1));
  }, [invoicesQuery.data, searchTerm]);

  const handleExportToExcel = () => {
    const data = searchedInvoices;
    const fileName = "invoices";
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <>
      <PagePaper isLoading={invoicesQuery.isFetching} title="Invoices">
        <div className={classes.wrapper}>
          <ProtectedButton
            variant="outlined"
            color="primary"
            disabled={invoicesQuery.isFetching}
            className={classes.button}
            onClick={handleExportToExcel}
            requiredFeature={Feature.CustomerCareManageMachines}
          >
            Export
          </ProtectedButton>
          <TableSearch
            searchTerm={searchTerm}
            onChange={setSearchTerm}
            captionText={"Search on every field in this table"}
          />
          <InvoicesPresentation
            invoices={searchedInvoices.map((x) => {
              return {
                ...x,
                machineNumber: x.machineNumber,
                companyName: x.companyName,
                debtorNumber: x.debtorNumber,
                amountDue: x.amountDue,
                subscription: x.subscription,
                subscriptionStartDate: toISO8601DateString(
                  x.subscriptionStartDate
                ),
                subscriptionEndDate: toISO8601DateString(x.subscriptionEndDate),
                invoiceStatus: x.invoiceStatus,
                invoiceCreatedDate: toISO8601DateString(x.invoiceCreatedDate),
              };
            })}
            isPageLoading={invoicesQuery.isFetching}
          />
        </div>
      </PagePaper>
    </>
  );
}
